import React from 'react';
import { ComponentWrapper } from '../../../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from '../../Shared/styles';
import { Header } from './Header';
import { Spacer } from '../../../../helperComponents/Spacer';
import { StyleSheet, View } from 'react-native';
import { ShadowTextInput } from '../../../../helperComponents/ShadowTextInput';
import { formUtils, useForm } from '../../../../../hooks/useForm';
import { z } from 'zod';
import { STRING_CONSTANTS } from '../../../../../constants/constants';
import { utils } from '../../../../../utils/utils';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';

type ValidForm = {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

const schemas = {
    currentPassword: z.string().min(1, { message: 'Current password is required' }),
    newPassword: formUtils.validatePassword,
    confirmPassword: formUtils.validatePassword,
};

const onValidateAll = (schema: z.ZodSchema) => {
    return schema.superRefine((args, ctx) => {
        if (args.newPassword !== args.confirmPassword) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: STRING_CONSTANTS.PASSWORDS_DO_NOT_MATCH,
                path: ['confirmPassword'],
            });
        }
    });
};

export default function ChangePassword() {

    const { mutate: changePassword, isLoading } = trpc.user.changePassword.useMutation();

    const {
        setCurrentPassword,
        setNewPassword,
        setConfirmPassword,
        errors,
        validateAll,
    } = useForm<Partial<ValidForm>>(
        { schemas, onValidateAll },
        {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        }
    );

    const onSave = () => {
        const result = validateAll<ValidForm>();
        if (result.isValid) {
            changePassword({
                currentPassword: result.currentPassword,
                newPassword: result.newPassword,
                confirmPassword: result.confirmPassword,
            }, {
                onSuccess: () => utils.showToast('success', 'Password changed successfully'),
                onError: (error: any) => utils.showToast('error', error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    };

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.container}
            wrapInScrollView={false}
        >
            <Header title="CHANGE PASSWORD" />
            <View style={styles.content}>
                <ShadowTextInput
                    title="Current Password"
                    placeholder="Enter Current Password"
                    showRequiredAsteriks
                    secureTextEntry
                    onChangeText={setCurrentPassword}
                    isError={errors.currentPassword !== undefined}
                    errorMessage={errors.currentPassword}
                />
                <Spacer height={25} />
                <ShadowTextInput
                    title="New Password"
                    placeholder="Enter New Password"
                    showRequiredAsteriks
                    secureTextEntry
                    onChangeText={setNewPassword}
                    isError={errors.newPassword !== undefined}
                    errorMessage={errors.newPassword}
                />
                <Spacer height={25} />
                <ShadowTextInput
                    title="Confirm Password"
                    placeholder="Confirm New Password"
                    showRequiredAsteriks
                    secureTextEntry
                    onChangeText={setConfirmPassword}
                    isError={errors.confirmPassword !== undefined}
                    errorMessage={errors.confirmPassword}
                />
            </View>
            <View style={styles.footer}>
                <MB_Button
                    title="Save"
                    style={styles.primaryBtn}
                    textStyle={styles.primaryBtnText}
                    disabled={isLoading}
                    loading={isLoading}
                    onPress={onSave}
                />
            </View>
        </ComponentWrapper>
    );
}

const styles = StyleSheet.create({
    content: {
        paddingTop: 50,
        paddingHorizontal: 15,
        flex: 1,
    },
    footer: {
        marginHorizontal: -16,
        height: 97,
        paddingTop: 18,
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        alignSelf: 'stretch',
        paddingHorizontal: 29,
        ...mbShadow({
            offsetWidth: -5,
            offsetHeight: -5,
            opacity: 0.5,
            color: '#AEAEC0',
            radius: 20,
            elevation: 20,
        }),
    },
    primaryBtn: {
        height: 43,
        backgroundColor: '#F35E2E',
        borderRadius: 8,
        alignSelf: 'stretch',
    },
    primaryBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
        fontWeight: '800',
    }]),
});

