import React, { useState } from 'react';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { getNumberOfSteps, getTopInset } from '../../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import Stepper from '../../../helperComponents/Stepper';
import { useSignedInContext } from '../../../../context/SignedInContext';
import ShadowRoundedButton from '../../../../resources/svgComponents/ShadowRoundedButton';
import MinusSignIcon from '../../../../resources/svgComponents/Quarter/MinusSignIcon';
import PlusSignIcon from '../../../../resources/svgComponents/Quarter/PlusSignIcon';
import { QuestionToPlay } from '@sapphicsavvy/business';
import BarSlider from '../../../helperComponents/BarSlider';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { Answer } from '../../../../navigations/RootNavigator';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

type SliderCardProps = {
    title: string
    value: number,
    onChange?: (value: number) => void,
    steps?: number,
    min?: number,
    max?: number,
}

function SliderCard({ title, value, onChange, steps = 1, min = 0, max = 100 }: SliderCardProps) {
    return (
        <ShadowView style={styles.sliderCard}>
            <Text style={styles.sliderCardText}>{capitalize(title)}</Text>
            <View>
                <BarSlider
                    colors={[mbApplyTransparency('#FCAF41', 0.95), mbApplyTransparency('#FCAF41', 0.95)]}
                    value={value}
                    minValue={min}
                    maxValue={max}
                    disableLimit
                />
                <View style={styles.sliderCardBtns}>
                    <ShadowRoundedButton
                        size={44}
                        borderWidth={10}
                        icon={<MinusSignIcon size={15} />}
                        onPress={() => onChange?.(Math.max(min, value - steps))}
                    />
                    <Text style={styles.sliderCardValue}>{value}</Text>
                    <ShadowRoundedButton
                        size={44}
                        borderWidth={10}
                        icon={<PlusSignIcon size={15} />}
                        onPress={() => onChange?.(Math.min(max, value + steps))}
                    />
                </View>
            </View>
        </ShadowView>
    );
}


type IdealBonusStepProp = {
    onNext?: (answer: Partial<Answer>) => void,
    onBack?: () => void,
    question: QuestionToPlay,
    isSubmitting?: boolean
}

export default function IdealBonusStep({ question, onBack, onNext, isSubmitting }: IdealBonusStepProp) {

    const { currentUserData } = useSignedInContext();

    const [typicalIdentityAnswer, setTypicalIdentityAnswer] = useState(4);
    const [idealAnswer, setIdealAnswer] = useState(4);

    const imageUri = mbGetMediumImage(question.imageUrl, { asUri: true });

    const min = question.answerOptions.min || 0;
    const max = question.answerOptions.max || 100;
    const increment = question.answerOptions.increment || 1;

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>Ideals Bonus</Text>
                <Spacer />
                <ShadowView style={styles.coins}>
                    <CoinsIcon size={27} />
                    <Spacer width={6} />
                    <Text style={styles.pointsText}>200</Text>
                </ShadowView>
            </View>
            <View style={styles.topSection}>
                <Stepper style={styles.topSectionStepper} step={5} max={getNumberOfSteps(question)} />
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{question.sapphicQuestion}</Text>
                    <Image style={styles.topSectionImage} source={imageUri} />
                </View>
            </View>
            <ScrollView
                style={styles.bottomSection}
                contentContainerStyle={styles.bottomSectionContent}
            >
                <SliderCard
                    title={`What does the typical ${currentUserData?.identity} think is the ideal answer?`}
                    value={typicalIdentityAnswer}
                    onChange={setTypicalIdentityAnswer}
                    min={min}
                    max={max}
                    steps={increment}
                />
                <Spacer height={15} />
                <SliderCard
                    title="What do you think is the ideal answer?"
                    value={idealAnswer}
                    onChange={setIdealAnswer}
                    min={min}
                    max={max}
                    steps={increment}
                />
            </ScrollView>
            <View style={styles.footer}>
                <MB_Button
                    style={styles.backBtn}
                    leftElement={<Feather name="chevron-left" color={styles.backBtnText.color} size={20} />}
                    title="BACK"
                    textStyle={styles.backBtnText}
                    onPress={onBack}
                />
                <MB_Button
                    style={styles.nextBtn}
                    rightElement={<Feather name="chevron-right" color={styles.nextBtnText.color} size={20} />}
                    title="NEXT"
                    textStyle={styles.nextBtnText}
                    onPress={() => onNext?.({
                        idealBonus: {
                            answer: idealAnswer,
                            guess: typicalIdentityAnswer,
                        },
                    })}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: getTopInset(),
        flex: 1,
        backgroundColor: '#EFEFF3',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    coins: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: 111,
        height: 40,
    },
    pointsText: mbTextStyles([textStyles.largerText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    headerTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontWeight: '400',
        fontSize: 22,
        color: mbApplyTransparency('#FCAF41', 0.95),
    }]),
    topSection: {
        flex: 1,
        backgroundColor: mbApplyTransparency('#FCAF41', 0.95),
    },
    topSectionStepper: {
        margin: 2,
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionImage: {
        width: '100%',
        flex: 1,
        borderRadius: 12,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
        marginBottom: 21,
    }]),
    topSectionTitleBoldText: mbTextStyles([textStyles.largeText, {
        fontSize: 55,
        fontWeight: '700',
        marginVertical: 14,
    }]),
    bottomSection: {
        flex: 1,
    },
    bottomSectionContent: {
        padding: 15,
    },
    sliderCard: {
        padding: 13,
    },
    sliderCardText: mbTextStyles([textStyles.largeText, {
        fontSize: 18,
        fontWeight: '500',
        color: mbApplyTransparency('#000000', 0.95),
        marginBottom: 15,
    }]),
    sliderCardValue: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '700',
        color: mbApplyTransparency('#000000', 0.95),
        marginHorizontal: 20,
    }]),
    sliderCardBtns: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
    },
    footer: {
        flexDirection: 'row',
        height: 60,
        ...mbShadow({
            offsetWidth: 5,
            offsetHeight: 5,
            radius: 10,
            elevation: 10,
            color: '#AEAEC0',
            opacity: 0.5,
        }),
    },
    backBtn: {
        backgroundColor: '#F0F0F3',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    backBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#7E8F9F',
    }]),
    nextBtn: {
        backgroundColor: mbApplyTransparency('#FCAF41', 0.95),
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    nextBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#FFFFFF',
    }]),
});
