export {
    AddQuarterAnswerInputSchema,
    BonusTypeIdealSchema,
    IdentityGroupBonusSchema,
    AgeGroupBonusSchema,
    GetQuarterDataToPlayInputSchema,
    QuarterAnswerDataSchema,
} from './schemas';
export type {
    AddQuarterAnswerInput,
    IdealBonusInput,
    IdentityGroupBonusInput,
    AgeGroupBonusInput,
    GetQuarterDataToPlayInput,
    QuarterAnswerData,
} from './types';
