import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

function GolfBallIcon({ color = '#8D9297' }: { color?: string }) {
  return (
    <Svg
      width={15}
      height={23}
      viewBox="0 0 15 23"
      fill="none"
    >
      <G clipPath="url(#clip0_1532_1729)">
        <Path
          d="M7.5.415a7.46 7.46 0 015.303 2.209A7.544 7.544 0 0115 7.957a7.541 7.541 0 01-3.031 6.059c-.935 1.436-1.58 2.771-1.987 4.005-.358 1.083-.534 2.091-.568 3.02h3.523c.377 0 .682.308.682.686 0 .38-.306.686-.682.686H1.985a.684.684 0 01-.682-.686c0-.38.306-.686.682-.686h3.55c-.033-.929-.21-1.935-.567-3.02-.415-1.255-1.073-2.614-2.033-4.077A7.547 7.547 0 010 7.96c0-2.084.84-3.97 2.197-5.335A7.461 7.461 0 017.5.414zm1.197 8.654c.438 0 .794.358.794.799a.796.796 0 01-.794.798.797.797 0 01-.794-.798.795.795 0 01.794-.799zm2.581.865c.438 0 .794.358.794.798 0 .44-.356.799-.794.799a.796.796 0 01-.794-.799c0-.44.356-.798.794-.798zM9.226 11.2c.44 0 .794.358.794.798 0 .44-.356.799-.794.799a.797.797 0 01-.794-.799c0-.44.356-.798.794-.798zm-2.615-.7c.44 0 .794.358.794.798 0 .44-.356.799-.794.799a.797.797 0 01-.794-.799c0-.44.354-.798.794-.798zm4.138-2.83c.438 0 .794.359.794.8a.796.796 0 01-.794.798.797.797 0 01-.793-.799.794.794 0 01.793-.798zM8.224 21.042c.033-1.047.23-2.18.63-3.394.27-.817.632-1.67 1.098-2.559a7.476 7.476 0 01-4.964-.02c.471.896.838 1.755 1.11 2.579.4 1.214.597 2.347.63 3.394h1.496z"
          fill={color}
        />
        <Path
          d="M8.967 9.082c.438 0 .794.358.794.798a.796.796 0 01-.794.799.797.797 0 01-.794-.799.795.795 0 01.794-.798zm2.58.865c.439 0 .795.358.795.798 0 .44-.356.799-.794.799a.796.796 0 01-.794-.799c0-.44.356-.798.794-.798zm-2.051 1.265c.44 0 .793.358.793.799 0 .44-.356.798-.793.798a.797.797 0 01-.794-.798c0-.44.356-.799.794-.799zm-2.615-.7c.44 0 .794.358.794.799 0 .44-.356.798-.794.798a.797.797 0 01-.794-.798c0-.44.354-.799.794-.799zm4.138-2.828c.438 0 .794.358.794.798a.796.796 0 01-.794.799.797.797 0 01-.794-.799.794.794 0 01.794-.798z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1532_1729">
          <Path fill="#fff" transform="translate(0 .415)" d="M0 0H15V22H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default GolfBallIcon;
