import React, { useCallback, useState } from 'react';
import { Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import GradientBackground from '../../../resources/svgComponents/GradientBackground';
import { imageUtils } from '../../../utils/imageUtils';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { ShadowView } from '../../helperComponents/ShadowView';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../constants/textStyles';
import { ShadowTextInput } from '../../helperComponents/ShadowTextInput';
import { Spacer } from '../../helperComponents/Spacer';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import ShadowCheckbox from '../../helperComponents/ShadowCheckbox';
import { GradientButton } from '../../helperComponents/gradients/GradientButton';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../navigations/RootNavigator';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { trpc } from '../../../apiCalls/trpcClient';
import { utils } from '../../../utils/utils';
import { formUtils, useForm } from '../../../hooks/useForm';
import { z } from 'zod';
import XIcon from '../../../resources/svgComponents/XIcon';
import { hitSlop } from '../../../utils/shared';

type ValidForm = {
    phoneNumber: string,
    password: string,
    confirmPassword: string
    isAgreedOnPolicyPrivacy: boolean,
    isAgreedOnReceivingMessages: boolean,
}

const schemas = {
    phoneNumber: z.string().min(1, { message: 'Phone number is required' }).regex(formUtils.phoneRegex, 'Invalid phone number'),
    password: formUtils.validatePassword,
    confirmPassword: formUtils.validatePassword,
    isAgreedOnPolicyPrivacy: z.boolean().refine(val => val === true, { message: 'you must agree on terms and conditions' }),
    isAgreedOnReceivingMessages: z.boolean().refine(val => val === true, { message: 'you must agree in order to receive messages' }),
};

const onValidateAll = (schema: z.ZodSchema) => {
    return schema.superRefine((args, ctx) => {
        if (args.password !== args.confirmPassword) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: STRING_CONSTANTS.PASSWORDS_DO_NOT_MATCH,
                path: ['confirmPassword'],
            });
        }
    });
};

const SignUp = () => {

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const { mutate, isLoading: isCreatingAccount } = trpc.user.createAccount.useMutation();
    const [isNoteVisible, setIsNoteVisible] = useState(true);

    const {
        setPhoneNumber,
        setPassword,
        setConfirmPassword,
        isAgreedOnPolicyPrivacy,
        isAgreedOnReceivingMessages,
        setIsAgreedOnPolicyPrivacy,
        setIsAgreedOnReceivingMessages,
        errors,
        validateAll,
    } = useForm<Partial<ValidForm>>(
        { schemas, onValidateAll },
        {
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            isAgreedOnPolicyPrivacy: false,
            isAgreedOnReceivingMessages: false,
        }
    );

    const onSignUp = useCallback(() => {
        const result = validateAll<ValidForm>();
        if (result.isValid) {
            mutate({
                phoneNumber: result.phoneNumber,
                password: result.password,
                confirmPassword: result.confirmPassword,
            }, {
                onSuccess: (data) => {
                    navigation.navigate('AccountSetup', { phoneNumber: result.phoneNumber, token: data.token });
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    }, [mutate, navigation, validateAll]);


    const goToLogin = () => navigation.navigate('Login');

    return (
        <ComponentWrapper containerStyle={styles.screen}>
            <View style={styles.container}>
                <View style={styles.hiddenHeader}>
                    <GradientBackground
                        height={Dimensions.get('window').height}
                        width={Dimensions.get('window').width}
                    />
                </View>
                <View style={styles.header}>
                    <View style={styles.appIconContainer}>
                        <Image source={imageUtils.images.appIcon} style={styles.appIcon} />
                    </View>
                </View>
                <ShadowView style={styles.content}>
                    <Text style={styles.pageTitle}>Sign Up</Text>
                    <ShadowTextInput
                        title="Phone Number"
                        placeholder="+1 000 000 0000"
                        showRequiredAsteriks
                        keyboardType="phone-pad"
                        onChangeText={setPhoneNumber}
                        isError={errors.phoneNumber !== undefined}
                        errorMessage={errors.phoneNumber}
                        returnKeyType="done"
                    />
                    <Spacer height={20} />
                    {isNoteVisible &&
                        <ShadowView style={styles.note}>
                            <TouchableOpacity
                                hitSlop={hitSlop}
                                onPress={() => setIsNoteVisible(false)}
                                style={styles.noteClose}
                            >
                                <XIcon size={15} color="#6D6E71" />
                            </TouchableOpacity>
                            <Text style={styles.noteTitle}>Why is signing up with a phone number mandatory?</Text>
                            <Spacer height={12} />
                            <Text style={styles.noteInfo}>The Savviest Sapphics are about to win cash! To prevent cheating, each user can only have one account and it must be tied to a verified mobile number.</Text>
                        </ShadowView>
                    }
                    <Spacer height={20} />
                    <ShadowTextInput
                        title="Create Password"
                        placeholder="Create Password"
                        showRequiredAsteriks
                        secureTextEntry
                        onChangeText={setPassword}
                        isError={errors.password !== undefined}
                        errorMessage={errors.password}
                    />
                    <Spacer height={25} />
                    <ShadowTextInput
                        title="Re-Enter Password"
                        placeholder="Re-Enter Password"
                        showRequiredAsteriks
                        secureTextEntry
                        onChangeText={setConfirmPassword}
                        isError={errors.confirmPassword !== undefined}
                        errorMessage={errors.confirmPassword}
                    />
                    <Spacer height={25} />
                    <ShadowCheckbox
                        value={isAgreedOnPolicyPrivacy ?? false}
                        onChange={() => isAgreedOnPolicyPrivacy ? setIsAgreedOnPolicyPrivacy(false) : setIsAgreedOnPolicyPrivacy(true)}
                        isError={errors.isAgreedOnPolicyPrivacy !== undefined}
                        errorMessage={errors.isAgreedOnPolicyPrivacy}
                        text={<Text style={styles.privacyPolicyText}>Agree to <Text style={styles.important}>Privacy Policy & Terms & Conditions</Text></Text>}
                    />
                    <Spacer height={20} />
                    <ShadowCheckbox
                        value={isAgreedOnReceivingMessages ?? false}
                        onChange={() => isAgreedOnReceivingMessages ? setIsAgreedOnReceivingMessages(false) : setIsAgreedOnReceivingMessages(true)}
                        isError={errors.isAgreedOnReceivingMessages !== undefined}
                        errorMessage={errors.isAgreedOnReceivingMessages}
                        text={<Text style={styles.privacyPolicyText}>I agree to receive marketing messaging from Sapphic Savvy at the phone number provided above. Data rates may apply, reply STOP to opt out.</Text>}
                    />
                    <Spacer height={30} />
                    <GradientButton
                        title="SIGN UP"
                        style={styles.signUpBtn}
                        loading={isCreatingAccount}
                        onPress={onSignUp}
                        disabled={isCreatingAccount}
                    />
                    <Spacer height={20} />
                    <Text style={styles.signInText}>Already have an account? <Text style={styles.important} onPress={goToLogin}>Sign in</Text></Text>
                </ShadowView>
            </View>
        </ComponentWrapper>
    );
};

export { SignUp };

const styles = StyleSheet.create({
    screen: {
        paddingHorizontal: 0,
        paddingBottom: 0,
    },
    container: {
        flex: 1,
    },
    hiddenHeader: {
        position: 'absolute',
        marginTop: -Dimensions.get('window').height * 0.65,
    },
    header: {
        height: Dimensions.get('window').height * 0.35,
    },
    content: {
        flex: 1,
        margin: 10,
        paddingHorizontal: 30,
        borderBottomRightRadius: Platform.OS === 'android' ? undefined : 50,
        borderBottomLeftRadius: Platform.OS === 'android' ? undefined : 50,
        paddingBottom: 15,
    },
    pageTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 35,
        color: '#000000',
        textAlign: 'center',
        marginVertical: 25,
    }]),
    appIconContainer: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        paddingTop: initialWindowMetrics?.insets.top,
    },
    appIcon: {
        width: 197,
        height: 197,
    },
    note: {
        paddingVertical: 23,
        paddingHorizontal: 26,
    },
    noteClose: {
        position: 'absolute',
        right: 20,
        top: 20,
    },
    noteTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        fontSize: 14,
        fontWeight: '600',
        color: '#F35E2E',
        textAlign: 'left',
        width: 256,
    }]),
    noteInfo: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        fontSize: 14,
        fontWeight: '500',
        color: mbApplyTransparency('#000000', 0.6),
        textAlign: 'left',
    }]),

    privacyPolicyText: mbTextStyles([textStyles.smallerText, {
        color: '#000C14',
        textAlign: 'left',
        marginLeft: 7,
    }]),
    important: mbTextStyles([textStyles.smallText, {
        color: '#F14728',
        textAlign: 'center',
        fontWeight: '700',
        marginTop: 'auto',
        marginBottom: 30,
    }]),
    signUpBtn: {
        width: 173,
        alignSelf: 'center',
    },
    signInText: mbTextStyles([textStyles.smallText, {
        color: '#000000',
        textAlign: 'center',
        fontWeight: '400',
    }]),
});
