import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { getColors } from '../../../../../constants/colors';

const COLORS = getColors();

const LoadingContent = () => {
    return (
        <View style={styles.container}>
            <View>
                <UIActivityIndicator color={COLORS.orange}/>
            </View>

            <Text style={styles.text}>Loading...</Text>
        </View>
    );
};

export {LoadingContent};

const styles = StyleSheet.create({
    container:{
        flex: 1,
        justifyContent: 'center',
    },
    text: {
        textAlign: 'center',
        marginTop: 8,
    },
});
