import React, { useCallback, useMemo, useState } from 'react';
import { UserSelectTags } from '@sapphicsavvy/business';
import { FlatList, ListRenderItemInfo, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { ShadowView } from './ShadowView';
import { ShadowTextInput } from './ShadowTextInput';
import { GradientButton } from './gradients/GradientButton';
import { Spacer } from './Spacer';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';

type KeywordViewProps = {
    value: string
    onPressRemove?: () => void
    style?: StyleProp<ViewStyle>
}

function KeywordView({ value, onPressRemove, style }: KeywordViewProps) {
    return (
        <ShadowView style={[styles.keyword, style]}>
            <Text style={styles.keywordText}>{value}</Text>
            {onPressRemove &&
                <React.Fragment>
                    <Spacer width={5} />
                    <Feather
                        name="x"
                        size={20}
                        color={mbApplyTransparency('#6D6E71', 0.55)}
                        onPress={onPressRemove}
                    />
                </React.Fragment>
            }
        </ShadowView>
    );
}

type KeywordsProps = {
    title?: string,
    maxKeywords?: number,
    keywords?: UserSelectTags[],
    onKeywordsChange?: (keywords: UserSelectTags[]) => void,
}

function Keywords({ title, maxKeywords, keywords, onKeywordsChange }: KeywordsProps) {

    const [keyword, setKeyword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const onRemoveKeyword = useCallback((item: UserSelectTags) => {
        onKeywordsChange?.([...(keywords ?? []).filter(tag => tag !== item)]);
    }, [keywords, onKeywordsChange]);

    const renderKeyword = useCallback(
        (item: UserSelectTags) =>
            <KeywordView
                value={item}
                onPressRemove={() => onRemoveKeyword(item)}
            />,
        [onRemoveKeyword]
    );

    const renderSuggestion = useCallback(({ item }: ListRenderItemInfo<UserSelectTags>) => (
        <Text style={styles.suggestionText} onPress={() => setKeyword(item)} >{item}</Text>
    ), []);

    const itemSeparator = useCallback(() => <Spacer width={8} />, []);
    const suggessions = useMemo(() => Object.values(UserSelectTags).filter(tag =>
        !keywords?.includes(tag) && tag.toLowerCase().includes(keyword.toLowerCase())
    ), [keyword, keywords]);

    const onKeywordChange = useCallback((text: string) => {
        setKeyword(text);
        setErrorMessage('');
    }, []);

    const addKeyword = useCallback(() => {
        const validKeyword = Object.values(UserSelectTags).find(tag => tag.toLowerCase() === keyword.toLowerCase());
        if (!validKeyword) {
            setErrorMessage('Please enter a valid keyword');
        } else if (keywords?.includes(validKeyword)) {
            setErrorMessage('Keyword already added');
        } else if (maxKeywords && keywords && keywords.length >= maxKeywords) {
            setErrorMessage(`You can only enter up to ${maxKeywords} tags`);
        } else {
            onKeywordsChange?.([...(keywords ?? []), validKeyword]);
            setKeyword('');
            setErrorMessage('');
        }
    }, [keyword, keywords, maxKeywords, onKeywordsChange]);

    return (
        <View>
            <Text style={styles.title}>{title ?? 'Add keywords'}</Text>
            <FlatList
                horizontal
                data={suggessions}
                style={styles.suggestions}
                contentContainerStyle={styles.suggestionsContentContainer}
                ListHeaderComponent={<Text style={styles.suggestionHeaderText}>Suggessions:</Text>}
                ListHeaderComponentStyle={styles.suggestionsHeader}
                ListEmptyComponent={<Text style={styles.suggestionText}>No keyword found</Text>}
                renderItem={renderSuggestion}
                keyExtractor={(item) => item}
                ItemSeparatorComponent={itemSeparator}
                showsHorizontalScrollIndicator={false}
                keyboardShouldPersistTaps="handled"
            />
            <View style={styles.row}>
                <ShadowTextInput
                    placeholder="Write a keyword and tap on add"
                    containerStyle={styles.textInput}
                    textInputStyle={styles.textInputText}
                    value={keyword}
                    onChangeText={onKeywordChange}
                />
                <Text style={styles.errorText}>{errorMessage}</Text>
                <Spacer width={5} />
                <GradientButton
                    title="ADD"
                    style={styles.addBtn}
                    onPress={addKeyword}
                />
            </View>
            {(keywords && keywords.length > 0) ?
                <View style={styles.keywords}>
                    {keywords.map((item, index) => (
                        <View key={`${item}-${index}`} style={styles.keywordsContentContainer}>
                            {renderKeyword(item)}
                        </View>
                    ))}
                </View>
                :
                <Spacer height={17} />
            }
        </View>
    );
}

export { Keywords, KeywordView };

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'stretch',
    },
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        textAlign: 'left',
        fontSize: 16,
        color: mbApplyTransparency('#000000', 0.5),
    }]),
    textInput: {
        flex: 1,
        height: 50,
    },
    textInputText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.45),
        textAlign: 'left',
    }]),
    errorText: mbTextStyles([textStyles.smallText, {
        position: 'absolute',
        zIndex: 1,
        color: mbApplyTransparency('#FF0000', 0.80),
        textAlign: 'center',
        fontWeight: '400',
        fontSize: 11,
        left: 0,
        bottom: -16,
    }]),
    addBtn: {
        height: 50,
        width: 78,
    },
    suggestionsHeader: {
        justifyContent: 'center',
    },
    suggestionHeaderText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.5),
        marginRight: 8,
        textAlign: 'left',
    }]),
    suggestionText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.8),
    }]),
    suggestions: {
        marginHorizontal: -16,
    },
    keywords: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingBottom: 17,
    },
    suggestionsContentContainer: {
        paddingHorizontal: 16,
        paddingTop: 6,
        paddingBottom: 10,
    },
    keywordsContentContainer: {
        paddingRight: 17,
        paddingTop: 21,
    },
    keyword: {
        paddingHorizontal: 12,
        paddingVertical: 4,
        flexDirection: 'row',
        alignItems: 'center',
        height: 32,
    },
    keywordText: mbTextStyles([textStyles.normalText, {
        color: '#F35E2E',
    }]),
});
