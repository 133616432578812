import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function CheckIcon({ size}: { size?: number }) {
    return (
        <Svg
            width={size ?? 57}
            height={size ? size * (56 / 57) : 56}
            viewBox="0 0 57 56"
            fill="none"
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.5 0c15.464 0 28 12.536 28 28s-12.536 28-28 28S.5 43.463.5 28s12.536-28 28-28zM16.043 30.843l-.007-.006a1.763 1.763 0 01-.11-2.497 1.763 1.763 0 012.488-.117l5.904 5.354 14.168-14.838a1.764 1.764 0 011.24-.549 1.764 1.764 0 011.811 1.725c.012.453-.15.91-.486 1.264L25.691 37.264a1.762 1.762 0 01-1.217.546 1.758 1.758 0 01-1.25-.456l-7.177-6.507-.005-.004z"
                fill="#F35E2E"
            />
        </Svg>
    );
}

export default CheckIcon;
