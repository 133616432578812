import React, { useCallback, useRef, useState } from 'react';
import { Animated, FlatList, GestureResponderEvent, LayoutChangeEvent, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { ShadowView } from './ShadowView';
import { MB_ModalDropdown } from '@mightybyte/rnw.components.dropdown';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';

const ModalInfoState = {
    isVisible: false,
    callerX: 0,
    callerY: 0,
    callerWidth: 0,
    callerHeight: 0,
};

type DropdownPickerProps<T extends string> = {
    title?: string,
    titleStyle?: StyleProp<ViewStyle>,
    placeholder?: string,
    items: T[]
    value?: T,
    dropdownTextStyle?: StyleProp<TextStyle>
    onSelectItem?: (item: T) => void,
    style?: StyleProp<ViewStyle>,
    showRequiredAsteriks?: boolean,
    isError?: boolean,
    errorMessage?: string,
}

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

const DropdownPicker = <T extends string>({
    title,
    titleStyle,
    placeholder = 'Please select an option',
    items,
    value,
    dropdownTextStyle,
    onSelectItem,
    style,
    showRequiredAsteriks,
    isError,
    errorMessage,
}: DropdownPickerProps<T>) => {

    const [modalInfo, setModalInfo] = useState(ModalInfoState);
    const chevronAnimation = useRef(new Animated.Value(0)).current;

    const hideModal = useCallback(() => {
        setModalInfo(prev => ({ ...prev, isVisible: false }));
        Animated.timing(chevronAnimation, {
            toValue: 0,
            duration: 200,
            useNativeDriver: false,
        }).start();
    }, [chevronAnimation]);

    const showModal = useCallback((event: GestureResponderEvent) => {
        event.persist();
        setModalInfo(prev => ({
            ...prev,
            isVisible: true,
            callerY: (event.nativeEvent.pageY - event.nativeEvent.locationY) + 8,
        }));
        Animated.timing(chevronAnimation, {
            toValue: 1,
            duration: 200,
            useNativeDriver: false,
        }).start();
    }, [chevronAnimation]);

    const rotation = chevronAnimation.interpolate({ inputRange: [0, 1], outputRange: ['0deg', '180deg'] });

    const onReady = useCallback((event: LayoutChangeEvent) => {
        setModalInfo(prev => ({
            ...prev,
            callerX: event.nativeEvent.layout.x,
            callerY: event.nativeEvent.layout.y + 8,
            callerWidth: event.nativeEvent.layout.width,
            callerHeight: event.nativeEvent.layout.height,
        }));
    }, []);

    const onItemPress = useCallback((item: T) => {
        onSelectItem?.(item);
        hideModal();
    }, [hideModal, onSelectItem]);

    const renderItem = useCallback(({ item }: { item: T }) => (
        <TouchableOpacity onPress={() => onItemPress(item)}>
            <Text style={styles.itemText}>{capitalize(item)}</Text>
        </TouchableOpacity>
    ), [onItemPress]);

    const keyExtractor = useCallback((item: string, index: number) => `${item}-${index}`, []);

    const itemSeparator = useCallback(() => <View style={styles.separator} />, []);

    return (
        <React.Fragment>
            <Text style={[styles.title, titleStyle]}>{title}{showRequiredAsteriks && <Text style={[styles.inputTitleAsteriks]}> *</Text>}</Text>
            <TouchableOpacity
                onLayout={onReady}
                onPress={showModal}
                activeOpacity={1}
            >
                <ShadowView pointerEvents="none">
                    <View style={[styles.container, style, isError && styles.error]}>
                        <Text style={[styles.dropdownTextStyle, dropdownTextStyle]}>
                            {capitalize(value ?? placeholder)}
                        </Text>
                        <Animated.View style={[styles.chevron, { transform: [{ rotate: rotation }] }]} >
                            <Feather
                                name="chevron-down"
                                color="#F35E2E"
                                size={24}
                            />
                        </Animated.View>
                    </View>
                </ShadowView>
                {isError && <Text style={styles.errorMessageText}>{errorMessage}</Text>}
            </TouchableOpacity>

            <MB_ModalDropdown
                visible={modalInfo.isVisible}
                callerX={modalInfo.callerX}
                callerY={modalInfo.callerY}
                callerHeight={modalInfo.callerHeight}
                callerWidth={modalInfo.callerWidth}
                onClose={hideModal}
            >
                <View style={[styles.modal, { width: modalInfo.callerWidth }]}>
                    <FlatList
                        data={items}
                        renderItem={renderItem}
                        keyExtractor={keyExtractor}
                        ItemSeparatorComponent={itemSeparator}
                    />
                </View>
            </MB_ModalDropdown>
        </React.Fragment>
    );
};

export default DropdownPicker;

const styles = StyleSheet.create({
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        color: mbApplyTransparency('#000000', 0.55),
        fontSize: 18,
        textAlign: 'left',
        marginBottom: 8,
    }]),
    container: {
        borderWidth: 1,
        borderColor: '#00000000',
        height: 50,
        borderRadius: 8,
        justifyContent: 'center',
        paddingHorizontal: 10,
    },
    dropdownTextStyle: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: mbApplyTransparency('#000000', 0.45),
        fontWeight: '400',
    }]),
    chevron: {
        position: 'absolute',
        right: 10,
        top: 0,
        bottom: 0,
        justifyContent: 'center',
    },
    modal: {
        height: 154,
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#D1D1D1',
    },
    itemText: mbTextStyles([textStyles.normalText, {
        color: '#2C2826',
        textAlign: 'center',
        marginVertical: 15,
    }]),
    separator: {
        backgroundColor: mbApplyTransparency('#D1D1D1', 0.50),
        height: 1,
        marginHorizontal: 10,
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: -1,
            color: '#FFFFFF',
            opacity: 0.5,
            radius: 10,
            elevation: 0,
        }),
    },
    inputTitleAsteriks: {
        color: '#6D3139',
    },
    error: {
        borderColor: mbApplyTransparency('#FF0000', 0.80),
    },
    errorMessageText: mbTextStyles([textStyles.smallText, {
        position: 'absolute',
        zIndex: 1,
        color: mbApplyTransparency('#FF0000', 0.80),
        textAlign: 'center',
        fontWeight: '400',
        fontSize: 11,
        left: 0,
        bottom: -19,
    }]),
});
