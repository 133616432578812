import React, { useCallback } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';

type BarChartData = {
    label: string;
    value: number;
    isMax?: boolean;
}

type BarChartProps = {
    data: BarChartData[];
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
    const chartWrapperWidth = 400;
    const baseWidth = 100;

    const getBarWidth = useCallback((value: number) => {
        const flexibleWidth =  chartWrapperWidth - baseWidth;
        return baseWidth + (flexibleWidth * value) / 100;
    }, []);

    return (
        <View style={{ }}>
            {data.map((item, index) => (
                <View key={index} style={{ marginBottom: 10, width: getBarWidth(item?.value)}}>
                    <View style={styles.labelContainer}>
                        <Text style={styles.labelText}>{item?.label}</Text>
                        <Text style={styles.labelText}>{item?.value}</Text>
                    </View>
                    <View
                        style={[styles.bar, {
                            height: 30,
                            width: '100%',
                            backgroundColor: item.isMax ? '#6A2C8C' : '#3C3C3C',
                        }]}
                    />
                </View>
            ))}
        </View>
    );
};
const styles = StyleSheet.create({
    labelContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 10,
        height: 30,
    },
    bar: {
        position: 'absolute',
        zIndex: -1,
    },
    labelText:  mbTextStyles([
        textStyles.smallText,
        {
            color: '#fff',
            textAlign: 'left',
        },
    ]),

});

export {BarChart};
export type {BarChartData, BarChartProps};
