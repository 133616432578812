import React, { useCallback } from 'react';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { StyleSheet, Text } from 'react-native';
import { FONT_STYLES } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { ShadowTextInput } from '../../../helperComponents/ShadowTextInput';
import sharedStyles from './Shared/styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Header } from './Shared/Header';
import { z } from 'zod';
import { useForm } from '../../../../hooks/useForm';
import { UniqueUserFields } from '@sapphicsavvy/business';
import { useIsUnique } from '../../../../utils/queries';

type ValidForm = {
    email: string
}

const schemas = {
    email: z.string().min(1, { message: 'Email is required' }).email({ message: 'Please enter a valid email' }),
};

const ConnectYourEmailStep = ({ onComplete }: { onComplete?: (data: { email: string }) => void }) => {

    const { email, setEmail, validateEmail, validateAll, errors, updateErrors } = useForm<Partial<ValidForm>>({ schemas }, { email: '' });

    const { isLoading: isCheckingEmail } = useIsUnique({
        value: email,
        fieldName: UniqueUserFields.email,
        enabled: (value) => validateEmail(value).isValid,
        onFetch(isUnique) {
            if (isUnique === undefined) {
                updateErrors('email', 'couldn\'t check the email');
            } else if (!isUnique) {
                updateErrors('email', 'email is used');
            } else {
                updateErrors('email', { deleteMessage: true });
            }
        },
    });

    const complete = useCallback(() => {
        const result = validateAll<ValidForm>();
        if (isCheckingEmail) {
            updateErrors('email', 'we are verifying your email');
        } else if (result.isValid) {
            onComplete?.({ email: result.email });
        }
    }, [isCheckingEmail, onComplete, updateErrors, validateAll]);

    return (
        <KeyboardAwareScrollView
            style={sharedStyles.container}
            contentContainerStyle={sharedStyles.contentContainer}
        >
            <Header title="ACCOUNT SETUP" step={4} steps={6} />
            <Spacer height={40} />
            <Text style={styles.title}>CONNECT YOUR EMAIL</Text>
            <Spacer height={40} />
            <ShadowTextInput
                showRequiredAsteriks
                title="Email address"
                placeholder="Enter your email address"
                onChangeText={value => setEmail(value.toLowerCase().replace(/ /g, ''))}
                autoCapitalize="none"
                titleStyle={styles.inputTitle}
                isError={errors.email !== undefined}
                errorMessage={errors.email}
            />
            <Spacer />
            <GradientButton
                style={styles.nextBtn}
                title="Next"
                onPress={complete}
            />
        </KeyboardAwareScrollView>
    );
};

export default ConnectYourEmailStep;

const styles = StyleSheet.create({
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 28,
        color: '#242121',
    }]),
    inputTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        textAlign: 'left',
        fontSize: 18,
        color: mbApplyTransparency('#000000', 0.55),
        lineHeight: 18,
    }], { allowLineHeight: true }),
    nextBtn: {

    },
});
