import { Image } from 'react-native-image-crop-picker';

const ImagePicker = {
    fromGallery: async ({ }: {
        multiple?: boolean;
        includeExif?: boolean;
        includeBase64?: boolean;
    } = {}): Promise<Image[]> => [],
};

export default ImagePicker;
