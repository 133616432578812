import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getColors } from '../../constants/colors';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';

const COLORS = getColors();

const LoadingPage = () => {

    return (
        <View style={styles.container}>
            <View>
                <BarIndicator
                    color="#F35E2E"
                    count={5}
                    style={styles.loadingIndicator}
                />
                <Text style={styles.text}>Loading...</Text>
            </View>
        </View>
    );
};

export { LoadingPage };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: mbTextStyles([textStyles.normalText, {
        color: COLORS.textPrimary,
    }]),
    loadingIndicator: {
        alignSelf: 'center',
        marginBottom: 32,
        flex: undefined,
    },
});
