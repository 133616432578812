export const DEFAULT_TABLE_MAX_ROWS = 10;

export const enum SERVER_ERROR_CODES {
    EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
    // SIGN_UP_USER_TYPE_REQUIRED = 'USER_TYPE_REQUIRED',
    // INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE',
    CLIENT_DATA_MISMATCH_ERROR = 'CLIENT_DATA_MISMATCH_ERROR',
    INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
    INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',
    EXPIRED_ACCESS_TOKEN = 'EXPIRED_ACCESS_TOKEN',
    // EXPIRED_PASSWORD_RECOVERY_TOKEN = 'EXPIRED_PASSWORD_RECOVERY_TOKEN',
    // INVALID_ACCESS_TOKEN_REDIRECT = 'INVALID_ACCESS_TOKEN_REDIRECT',
    // INVALID_PASSWORD = 'INVALID_PASSWORD',
    // GENERAL_ERROR = 'GENERAL_ERROR',
    // GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED',
    // APPLE_LOGIN_FAILED = 'APPLE_LOGIN_FAILED',
    // INVALID_SIGN_UP_CODE = 'INVALID_SIGN_UP_CODE',
    // SIGN_UP_CODE_EXPIRED = 'SIGN_UP_CODE_EXPIRED',
    // NOT_FOUND = 'NOT_FOUND',
    // INVALID_PARAMETER = 'INVALID_PARAMETER',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    NETWORK_ERROR = 'NETWORK_ERROR',
    // LIMIT_FILE_SIZE = 'LIMIT_FILE_SIZE',
    // IMAGE_WITH_GIVEN_NAME_ALREADY_EXISTS = 'IMAGE_WITH_GIVEN_NAME_ALREADY_EXISTS',
    // CATEGORY_WITH_GIVEN_NAME_ALREADY_EXISTS = 'CATEGORY_WITH_GIVEN_NAME_ALREADY_EXISTS',
    // QUESTION_NOT_READY_ENABLE = 'QUESTION_NOT_READY_ENABLE',
    // DUPLICATE_ANSWER_NAME = 'DUPLICATE_ANSWER_NAME',
    // VIDEO_IS_PROCESSING = 'VIDEO_IS_PROCESSING',
    // IMAGE_USED_ON_ANSWER = 'IMAGE_USED_ON_ANSWER',
    // VIDEO_USED_ON_ANSWER = 'VIDEO_USED_ON_ANSWER',
    // CANNOT_ENABLE_CATEGORY = 'CANNOT_ENABLE_CATEGORY',
}

export const STRING_CONSTANTS = {
    SUCCESS: 'Success',
    ERROR: 'Error',
    EMAIL_ALREADY_IN_USE:
        'This email address is already taken. Please try to log in or use a different email.',
    FAILED_TO_LOG_IN: 'Failed to log in, please try again',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    // NEW_PASSWORD_IS_SAME_AS_OLD: 'The new password can`t be the same as the old password',
    // CURRENT_PASSWORD_WAS_INVALID: 'Current password was invalid',
    SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN:
        'Something went wrong. Please try again',
    // PASSWORD_RECOVERY_LINK_SEND_SUCCESS: 'Password reset link sent to your given email address',
    // PASSWRORD_RECOVERY_EXPIRED: 'Password reset link expired. Please request a new password recovery link',
    // REGISTRATION_CODE_WAS_SENT_TO_YOUR_EMAIL: 'We sent a verification code to your email.\nPlease enter the code below.',
    // IMAGE_UPLOADER_ERROR_TOO_MANY_FILES: 'You can only upload one file',
    // IMAGE_UPLOADER_ERROR_INCORRECT_FORMAT: 'Incorrect file format',
    // FILE_IS_TOO_LARGE: 'Provided file was too large.\nPlease try an image with < ' + (CONSTANTS.MAX_IMAGE_SIZE_AFTER_COMPRESSION / (1024 * 1024)) + ' MB in size',
    // VIDEO_FILE_IS_TOO_LARGE: 'Provided video file was too large.\nPlease try a video file with < ' + (CONSTANTS.MAX_VIDEO_SIZE / (1024 * 1024)) + ' MB in size',
    // VIDEO_FILE_INCORRECT_FORMAT: 'This video file could not be read by your browser\nTo continue, please choose another video file.',
    // VIDEO_FILE_TOO_LONG: 'Provided video was too long.\nMaximum allowed video duration is 1 minute',
    // FILL_IN_ALL_THE_REQUIRED_FIELDS: 'Please fill in all the required fields',
    // FIELDS_CANT_BE_NEGATIVE: 'Some of the fields contain negative numbers.\nPlease fix the issue and try again',
    // FIELDS_CANT_BE_LESS_OR_EQUAL_ZERO: ' can\'t be less than or equal to zero',
};
