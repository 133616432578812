import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useState } from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import { MB_TextInput, MB_TextInputInputType } from '@mightybyte/rnw.components.text-input';
import DateTimePicker, { DateType } from 'react-native-ui-datepicker';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import CalendarIcon from '../../resources/svgComponents/CalendarIcon';
import { endOfToday, format, sub } from 'date-fns';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { getColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';

const COLORS = getColors();

type DatePickerProps = {
    date?: Date,
    onDateChange?: (date: Date) => void,
    title?: string
    titleStyle?: StyleProp<TextStyle>,
    showRequiredAsteriks?: boolean,
    isError?: boolean,
    errorMessage?: string
    disabled?: boolean,
    minDate?: Date
}

type Dayjs = {
    toDate: () => Date
}

const DatePicker = ({ date, onDateChange, title, titleStyle, showRequiredAsteriks, isError, disabled, minDate }: DatePickerProps) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showCalendarPicker = () => {
        setIsModalVisible(true);
    };

    const onClose = () => setIsModalVisible(false);

    const onChange = (params: { date: DateType }) => {
        if (params.date) {
            onDateChange?.((params.date as Dayjs).toDate());
        }
    };

    const formatteDate = date ? format(date, 'MMM dd yyyy') : undefined;
    console.log('minDate',   sub(endOfToday(), { days: -1 }));

    return (
        <React.Fragment>
            <View>
                <MB_TextInput
                    title={title}
                    showRequiredAsteriks={showRequiredAsteriks}
                    placeholder="Month Day Year"
                    titleStyle={titleStyle}
                    style={[styles.calendarInput, disabled ? styles.disabled : {}]}
                    leftIcon={<CalendarIcon style={styles.calendarIcon} />}
                    inputType={MB_TextInputInputType.numbers}
                    value={formatteDate}
                    disable
                    isError={isError}
                />
                <TouchableOpacity
                    style={styles.pressable}
                    activeOpacity={1}
                    onPress={showCalendarPicker}
                    disabled={disabled}
                />
            </View>

            <MB_Modal
                key={`${isModalVisible}`}
                isVisible={isModalVisible}
                hideCloseButton
                childrenWrapperStyle={styles.modal}
                withoutScrollView={true}
                backdropOpacity={0.3}
                onBackdropPress={onClose}
            >
                <DateTimePicker
                    mode="single"
                    date={date}
                    onChange={onChange}
                    minDate={ minDate ?? sub(endOfToday(), { years: 100 })}
                    weekDaysTextStyle={styles.calendarWeekDaysText}
                    headerTextStyle={styles.calendarText}
                    calendarTextStyle={styles.calendarText}
                    selectedItemColor={COLORS.orange}
                />
                <View style={{width: '100%'}}>
                    <MB_Button
                        title="Ok"
                        onPress={onClose}
                        style={{flex: 1, backgroundColor: COLORS.orange}}
                        textStyle={styles.buttonText}
                        />

                </View>
            </MB_Modal>
        </React.Fragment>
    );
};

export default DatePicker;

const styles = StyleSheet.create({
    modal: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        width: 350,
    },
    calendarInput: {
        maxWidth: 200,
        minHeight: 51,
        paddingHorizontal: 10,
    },
    calendarIcon: {
        marginRight: 10,
    },
    pressable: {
        position: 'absolute',
        width: '100%',
        height: '65%',
        bottom: 0,
    },
    closeButton: {
        position: 'absolute',
        right: 5,
        height: 30,
        width: 30,
        backgroundColor: mbApplyTransparency('#000000', 0.2),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 30,
    },
    calendarText: {
        color: '#757575',
    },
    calendarWeekDaysText: {
        color: '#7e7e7e',
    },
    disabled: {
        backgroundColor: '#F2F2F2',
    },

    buttonText: mbTextStyles([
        textStyles.normalText,
        {
            color: '#FFFFFF',
            fontSize: 16,
        },
    ]),
});
