import React, { useMemo } from 'react';
import { Image, View } from 'react-native';
import { imageUtils } from '../../utils/imageUtils';
import Svg, { Circle, Defs, LinearGradient, Stop } from 'react-native-svg';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';

type CirculaireSliderProps = {
    size?: number,
    step?: number,
    percentage?: number,
    colors?: [string, string],
    onChange?: (percentage: number) => void,
}

export default function CirculaireSlider({ size = 200, step = 1, percentage = 0, onChange, colors = ['#0495CD', '#2EB0E2'] }: CirculaireSliderProps) {

    const circulairePercentage = Math.floor(percentage * 134 / 100);
    const id = useMemo(() => `${Math.random()}-${Date.now()}`, []);

    const gesture = Gesture
        .Pan()
        .runOnJS(true)
        .onUpdate(ev => {
            const x = ev.x;
            const y = ev.y;
            const cx = size / 2;
            const cy = size / 2;
            if (y > size / 2) {
                return;
            }
            if ((Math.pow(x - cx, 2) + Math.pow(y - cy, 2)) - Math.pow(size / 2, 2) < 10) {
                const radianAngle = Math.atan2(y - cy, x - cx);
                const angle = Math.floor(Math.abs(radianAngle * (180 / Math.PI)));
                const newPercentage = Math.floor(100 - (angle * 100 / 180));
                if (newPercentage % step === 0) {
                    onChange?.(newPercentage);
                }
            }
        });

    const rotation = percentage * 170 / 100 + 5;

    return (
        <GestureDetector gesture={gesture}>
            <View style={{ width: size, height: size / 2 }}>
                <Image
                    style={{ position: 'absolute', left: 3, top: 0, width: '98%', height: '100%' }}
                    source={imageUtils.screens.quarterSliderMask}
                    resizeMode="contain"
                />
                <Svg
                    width={size}
                    height={size / 2}
                    viewBox="-1 0 102 50"
                >
                    <Circle
                        cx={0}
                        cy={0}
                        r={46}
                        strokeLinecap="round"
                        fill="none"
                        stroke={`url(#${id})`}
                        strokeWidth={circulairePercentage === 0 ? 0 : 6}
                        strokeDasharray={`${circulairePercentage} 1000000000`}
                        translateX={50}
                        translateY={50}
                        rotation={-172}
                    />
                    <Circle fill="#D6E3F3" r={5} cx={-46} cy={0} translateX={50} translateY={50} rotation={rotation} />
                    <Circle fill={colors[1]} r={4.5} cx={-46} cy={0} translateX={50} translateY={50} rotation={rotation} />
                    <Circle fill="#D6E3F3" r={3.5} cx={-46} cy={0} translateX={50} translateY={50} rotation={rotation} />
                    <Circle fill={colors[0]} r={3} cx={-46} cy={0} translateX={50} translateY={50} rotation={rotation} />
                    <Defs>
                        <LinearGradient id={id} x1={0} y1={50} x2={30} y2={50} gradientUnits="userSpaceOnUse">
                            <Stop stopColor={colors[0]} />
                            <Stop offset={1} stopColor={colors[1]} />
                        </LinearGradient>
                    </Defs>
                </Svg>
            </View>
        </GestureDetector>
    );
}

