import React, { useCallback, useRef, useState } from 'react';
import { Animated, GestureResponderEvent, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import ProfileCircle from '../../../../resources/svgComponents/Admin/ProfileCircle';
import { MB_ModalDropdown } from '@mightybyte/rnw.components.dropdown';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../../context/SignedInContext';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { utils } from '../../../../utils/utils';
import { trpc } from '../../../../apiCalls/trpcClient';
import { ChangePasswordModal } from '../modals/ChangePasswordModal';

const ModalInfoState = {
    isVisible: false,
    callerX: 0,
    callerY: 0,
    callerWidth: 0,
};

const Header = () => {
    const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
    const [modalInfo, setModalInfo] = useState(ModalInfoState);
    const chevronAnimation = useRef(new Animated.Value(0)).current;

    const { currentUserData, setSignedInStatus } = useSignedInContext();
    const trpcUtils = trpc.useUtils();

    const hideModal = useCallback(() => {
        setModalInfo(prev => ({ ...prev, isVisible: false }));
        Animated.timing(chevronAnimation, {
            toValue: 0,
            duration: 100,
            useNativeDriver: false,
        }).start();
    }, [chevronAnimation]);

    const showModal = useCallback((event: GestureResponderEvent) => {
        const layerX = (event.nativeEvent as any).layerX;
        const callerWidth = (event.target as any)?.clientWidth;
        setModalInfo(prev => ({
            ...prev,
            isVisible: true,
            callerX: event.nativeEvent.pageX - layerX,
            callerY: 70,
            callerWidth: callerWidth ?? 131,
        }));
        Animated.timing(chevronAnimation, {
            toValue: 1,
            duration: 200,
            useNativeDriver: false,
        }).start();
    }, [chevronAnimation]);

    const rotation = chevronAnimation.interpolate({ inputRange: [0, 1], outputRange: ['0deg', '180deg'] });

    const changePassword = useCallback(() => {
        hideModal();
        setIsChangePasswordModalVisible(true);
    }, [hideModal]);

    const hideChangePassword = useCallback(() => setIsChangePasswordModalVisible(false), []);

    const logout = useCallback(async () => {
        hideModal();
        utils.showPopUp('Are you sure want to log out?', {
            buttons: [{
                text: 'Yes',
                onPress: async () => {
                    await MB_accessTokenUtils.removeAccessToken();
                    setSignedInStatus(SIGNED_IN_STATUS.signedOut);
                    await utils.delay(500);
                    trpcUtils.invalidate();
                },
            }, {
                text: 'No',
            }],
        });
    }, [hideModal, setSignedInStatus, trpcUtils]);

    return (
        <View style={styles.container}>
            <View style={styles.menuContainer}>
                <TouchableOpacity
                    style={styles.menu}
                    onPress={showModal}
                    activeOpacity={1}
                >
                    <View pointerEvents="none" style={styles.menuLeftElement}>
                        <ProfileCircle />
                    </View>
                    <View pointerEvents="none">
                        <Text style={styles.menuText}>{currentUserData?.userName ?? 'Loading'}</Text>
                    </View>
                    <Animated.View pointerEvents="none" style={[styles.menuChevron, styles.menuRightElement, { transform: [{ rotate: rotation }] }]} >
                        <Feather
                            name="chevron-down"
                            color="#292D32"
                            size={18}
                        />
                    </Animated.View>
                </TouchableOpacity>
            </View>
            <MB_ModalDropdown
                visible={modalInfo.isVisible}
                callerX={modalInfo.callerX}
                callerY={modalInfo.callerY}
                callerHeight={0}
                callerWidth={modalInfo.callerWidth}
                onClose={hideModal}
                style={styles.modal}
            >
                <MB_OnHoverWrapper
                    wrapperStyle={styles.menuItem}
                    onHoverWrapperStyleModifier={styles.menuItemHover}
                    onPress={changePassword}
                >
                    <Text style={styles.menuItemText}>Change Password</Text>
                </MB_OnHoverWrapper>
                <MB_OnHoverWrapper
                    wrapperStyle={styles.menuItem}
                    onHoverWrapperStyleModifier={styles.menuItemHover}
                    onPress={logout}
                >
                    <Text style={styles.menuItemText}>Logout</Text>
                </MB_OnHoverWrapper>
            </MB_ModalDropdown>
            <ChangePasswordModal isVisible={isChangePasswordModalVisible} onDismiss={hideChangePassword} />
        </View>
    );
};

export { Header };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 20,
        paddingHorizontal: 30,
    },
    menuContainer: {
        marginLeft: 'auto',
    },
    menu: {
        height: 45,
        backgroundColor: '#FFFFFF',
        borderRadius: 30,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 15,
        ...mbShadow({
            offsetWidth: 14,
            offsetHeight: 17,
            color: '#7090B0',
            opacity: 0.08,
            radius: 40,
            elevation: 40,
        }),
    },
    menuChevron: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuText: mbTextStyles([textStyles.normalText, {
        fontWeight: '400',
        color: '#1E1E1E',
    }]),
    menuLeftElement: {
        marginEnd: 10,
    },
    menuRightElement: {
        marginStart: 2,
    },
    modal: {
        height: 93,
        width: 156,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: mbApplyTransparency('#000000', 0.05),
        backgroundColor: '#FFFFFF',
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: 0,
            color: '#000000',
            opacity: 0.05,
            radius: 25,
            elevation: 25,
        }),
        padding: 5,
    },
    menuItem: {
        height: 40,
        // alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        paddingLeft: 10,
    },
    menuItemHover: {
        backgroundColor: '#F6F6F6',
    },
    menuItemText: mbTextStyles([textStyles.smallText, {
        fontWeight: '400',
        textAlign: 'left',
        color: '#333333',
    }]),
});
