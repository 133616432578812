import { z } from 'zod';
import { PaginationInputSchema, SortBySchema } from '../../common';

export const AdminEditUserInputSchema = z.object({
    id: z.string(),
    hasFullAccess: z.boolean().optional(),
    isInStudy: z.boolean().optional(),
});

export const GetUsersPageInputSchema = PaginationInputSchema.extend({
    sort: SortBySchema,
    searchString: z.string().optional(),
});
