import { EnumLike, z } from 'zod';
import { FilterQueryType } from '.';

const StringSchema = z.string().trim(); // Can't import this from common inputs due to circular dependency

const equalsStringSchema = z.object({
    queryType: z.literal(FilterQueryType.equals),
    value: StringSchema,
});

const startsWithSchema = z.object({
    queryType: z.literal(FilterQueryType.startsWith),
    value: StringSchema,
});

const isAnyOfSchema = <T extends z.Schema>(schema: T) => {
    return z.object({
        queryType: z.literal(FilterQueryType.isAnyOf),
        values: z.array(schema),
    });
};

export const filterTextSchema = z.union([startsWithSchema, equalsStringSchema]);

export const filterDropdownSchema = <Enum extends EnumLike>(
    enumObject: Enum,
) => {
    const enumSchema = z.nativeEnum(enumObject);

    return isAnyOfSchema(enumSchema);
};

export const filterStringDropdownSchema = z.union([
    equalsStringSchema,
    isAnyOfSchema(StringSchema),
]);

export const filterDatetimeSchema = z.union([
    z.object({
        queryType: z.literal(FilterQueryType.before),
        value: StringSchema.datetime(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.after),
        value: StringSchema.datetime(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.between),
        values: z.object({
            from: StringSchema.datetime(),
            to: StringSchema.datetime(),
        }),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.equals),
        value: StringSchema.datetime(),
    }),
]);

export const filterNumberSchema = z.object({
    queryType: z.literal(FilterQueryType.equals),
    value: z.number(),
});

export const filterPhoneSchema = equalsStringSchema;
