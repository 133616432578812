import React, { useRef } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { StyleSheet, Text, View } from 'react-native';
import { GradientButton } from '../../../../helperComponents/gradients/GradientButton';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import { Spacer } from '../../../../helperComponents/Spacer';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { utils } from '../../../../../utils/utils';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../../../context/SignedInContext';
import { trpc } from '../../../../../apiCalls/trpcClient';

export type LogoutModalProps = {
    isVisible: boolean,
    onDismiss?: () => void,
}

export default function LogoutModal({ isVisible, onDismiss }: LogoutModalProps) {

    const { setSignedInStatus } = useSignedInContext();
    const trpcUtils = trpc.useUtils();
    const onModalHide = useRef(() => {});

    const onLogout = async () => {
        onModalHide.current = async () => {
            await MB_accessTokenUtils.removeAccessToken();
            setSignedInStatus(SIGNED_IN_STATUS.signedOut);
            await utils.delay(500);
            trpcUtils.invalidate();
        };
        onDismiss?.();
    };

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={() => onDismiss?.()}
            hideCloseButton
            childrenWrapperStyle={styles.container}
            withoutScrollView
            onModalHide={onModalHide.current}
        >
            <Text style={styles.titleText}>lOG OUT</Text>
            <Spacer height={30} />
            <Text style={styles.questionText}>Are you sure you want to Log out of your account?</Text>
            <Spacer height={14} />
            <Text style={styles.noteText}>If you log out, you will need to sign in again to access your account.</Text>
            <Spacer height={30} />
            <View style={styles.actions}>
                <GradientButton
                    title="LOG OUT"
                    textStyle={styles.logoutBtnText}
                    colors={['#FFFFFF', '#FFFFFF']}
                    style={styles.action}
                    onPress={onLogout}
                />
                <Spacer width={9} />
                <GradientButton
                    title="CANCEL"
                    style={styles.action}
                    onPress={onDismiss}
                />
            </View>
        </MB_Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#EFEFF3',
        width: '90%',
        paddingHorizontal: 21,
        paddingTop: 27,
        paddingBottom: 27,
    },
    titleText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 25,
        color: '#F35E2E',
    }]),
    questionText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '600',
        color: '#000000',
        paddingHorizontal: 30,
    }]),
    noteText: mbTextStyles([textStyles.normalText, {
        fontSize: 15,
        fontWeight: '400',
        color: '#000000',
        paddingHorizontal: 25,
    }]),
    actions: {
        flexDirection: 'row',
    },
    action: {
        width: 141,
    },
    logoutBtnText: {
        color: '#F35E2E',
    },
});
