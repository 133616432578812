import { StyleProp, ViewStyle } from 'react-native';

type CountDownProps = {
    endTime: number,
    style?: StyleProp<ViewStyle>
    labelsStyle?: StyleProp<ViewStyle>,
    disabled?: boolean,
};

export default function CountDown({ }: CountDownProps) {
    return null;
}
