import React from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';
import { Header } from './Shared/Header';
import BookIcon from '../../../resources/svgComponents/BookIcon';


const Read = () => {

    return (
        <ComponentWrapper
            containerStyle={sharedStyles.container}
        >
            <Header title="READ" icon={<BookIcon size={40} />} />
        </ComponentWrapper>
    );

};

export { Read };
