import * as React from 'react';
import Svg, { Circle, Defs, LinearGradient, Path, Stop } from 'react-native-svg';

const SearchIcon = ({ color = '#EE3823', backgroundColor }: { color?: string, backgroundColor?: string }) => {
    return (
        <Svg
            width={34}
            height={34}
            viewBox="0 0 34 34"
            fill="none"
        >
            <Path
                d="M15.144.298c4.039 0 7.702 1.637 10.355 4.27a14.494 14.494 0 014.286 10.315c0 2.943-.88 5.693-2.385 7.983l4.329 4.698a2.95 2.95 0 11-4.348 3.987l-4.127-4.523a14.626 14.626 0 01-8.11 2.44c-4.04 0-7.703-1.637-10.356-4.27A14.495 14.495 0 01.5 14.884c0-4.023 1.644-7.672 4.287-10.315A14.608 14.608 0 0115.144.298z"
                fill={color}
            />
            <Circle
                cx={15.3483}
                cy={14.9154}
                r={12.5741}
                fill={backgroundColor ?? 'url(#paint0_linear_1142_2562)'}
            />
            <Path
                d="M15.307 14.5a1.666 1.666 0 00-.304 0c-2.198-.075-3.943-1.886-3.943-4.114 0-2.275 1.828-4.123 4.1-4.123 2.262 0 4.099 1.848 4.099 4.123-.01 2.228-1.754 4.039-3.952 4.113zM10.805 16.777c-2.234 1.504-2.234 3.956 0 5.45 2.539 1.71 6.703 1.71 9.242 0 2.234-1.504 2.234-3.955 0-5.45-2.53-1.7-6.694-1.7-9.242 0z"
                fill={color}
            />
            <Defs>
                <LinearGradient
                    id="paint0_linear_1142_2562"
                    x1={4.82586}
                    y1={4.58244}
                    x2={19.319}
                    y2={21.9267}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#F14B28" />
                    <Stop offset={1} stopColor="#F78236" />
                </LinearGradient>
            </Defs>
        </Svg>
    );
};

export default SearchIcon;

