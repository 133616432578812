import { z } from 'zod';
import { AdminSortBy, ImageFileExtension, SortOrder } from '../..';
export const StringSchema = z.string().trim();
export const EmailSchema = StringSchema.email().trim().toLowerCase();
export const DateInputSchema = z.object({
    /**
     * Day of the month between 1 and 31.
     */
    day: z.number().int().positive().min(1).max(31),

    /**
     * Month of the year between 1 and 12.
     */
    month: z.number().int().positive().min(1).max(12),

    /**
     * Full year, e.g. 2024.
     */
    year: z.number().int().positive().min(1900),
});

export const ImageUploadInputSchema = z.object({
    fileExtension: z.nativeEnum(ImageFileExtension),
});

export const PaginationInputSchema = z.object({
    page: z.number().int().positive(),

    /**
     * @max 100
     * @default 10
     */
    pageSize: z.number().int().positive().max(100).optional().default(10),

    /**
     * Token obtained from the previous call.
     */
    lastToken: StringSchema.optional().nullable(),
});

export const InfiniteScrollingInputSchema = z.object({
    /**
     * Cursor obtained from a previous call. The result will be
     * items after this cursor. If ommitted, the result will be
     * items from the beginning of the list.
     */
    cursor: StringSchema.optional().nullable(),

    /**
     * The number of items to return.
     *
     * @max 100
     * @default 10
     */
    limit: z.number().int().positive().max(100).optional().default(10),
});

export const SortBySchema = z
    .object({
        field: z.nativeEnum(AdminSortBy),
        direction: z.nativeEnum(SortOrder),
    })
    .optional();
