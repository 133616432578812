import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import GradientContainer from '../../../helperComponents/gradients/GradientContainer';
import renderRandomStars from '../../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { ShadowView } from '../../../helperComponents/ShadowView';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import StarIcon from '../../../../resources/svgComponents/StarIcon';
import { QuarterDataToPlay } from '@sapphicsavvy/business';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { Answer } from '../../../../navigations/RootNavigator';

const stars: [number | string, number | string, number][] = [
    ['27%', '4%', 19],
    ['6%', '36%', 35],
    ['9%', '62%', 19],
    ['13%', '92%', 19],
    ['23%', '55%', 57],
    ['50%', '80%', 19],
    ['94%', '4%', 35],
    ['90%', '14%', 19],
    ['76%', '36%', 19],
    ['85%', '62%', 19],
    ['90%', '86%', 35],
];

type CardProps = {
    title: string,
    points: number,
    backgroundColor: string,
    textColor: string,
}

function Card({ backgroundColor, textColor, points, title }: CardProps) {
    return (
        <ShadowView style={[styles.card, { backgroundColor }]}>
            <Text style={[styles.cardTitleText, { color: textColor }]}>{title}</Text>
            <Text style={[styles.cardPointsText, { color: textColor }]}>{points}</Text>
        </ShadowView>
    );
}

type CompleteProps = {
    quarterData: QuarterDataToPlay,
    onPress?: () => void,
    answers: Answer[],
}

export default function Complete({ quarterData, answers, onPress }: CompleteProps) {

    const basePointsInFlux = quarterData.questions.length * 50;
    const bonusPointInFlux = 100; // TODO: first week should 200
    const extraBetsFlux = answers.reduce((points, answer) => points + (answer.extraBet || 0), 0);
    const pointsAtCompletion = extraBetsFlux + basePointsInFlux + bonusPointInFlux;

    return (
        <GradientContainer style={styles.container} angle={-90}>
            <View style={styles.topSection}>
                {renderRandomStars(stars)}
                <Text style={styles.setText}>SET {quarterData.setNumber}</Text>
                <Text style={styles.quarterText}>QUARTER {quarterData.quarterNumber}</Text>
                <View style={styles.complete}>
                    <AntDesign name="checkcircle" size={19} color="#FFFFFF" />
                    <Text style={styles.completeText}> Complete</Text>
                </View>
            </View>
            <View style={styles.bottomSection}>
                <Card
                    title="Points at completion"
                    points={pointsAtCompletion}
                    backgroundColor="#F9EFFF"
                    textColor="#6A2C8C"
                />
                <Card
                    title="Base points in flux"
                    points={basePointsInFlux}
                    backgroundColor="#FFF0EC"
                    textColor="#F3522B"
                />
                <Card
                    title="Bonus points in flux"
                    points={bonusPointInFlux}
                    backgroundColor="#FFF9F1"
                    textColor="#FCAF41F4"
                />
                <Card
                    title="Extra bets flux"
                    points={extraBetsFlux}
                    backgroundColor="#EBF9FF"
                    textColor="#0295CD"
                />
                <Text style={styles.resultTopTitleText}>Results will be ready</Text>
                <ShadowView style={styles.result}>
                    <Text style={styles.resultTitleText}>SATURDAY NOON PST</Text>
                    <View style={{ position: 'absolute', top: 0, left: 20, justifyContent: 'center' }}>
                        <StarIcon size={57} color={mbApplyTransparency('#F88337', 0.08)} />
                    </View>
                    <View style={{ position: 'absolute', top: 0, right: 50 }}>
                        <StarIcon size={38} color={mbApplyTransparency('#F88337', 0.08)} />
                    </View>
                </ShadowView>
                <GradientButton
                    title="BACK TO HOME"
                    containerStyle={styles.btn}
                    onPress={onPress}
                />
            </View>
        </GradientContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    topSection: {
        height: '33%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    setText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '700',
    }]),
    quarterText: mbTextStyles([textStyles.normalText, {
        fontSize: 45,
        fontWeight: '400',
        fontFamily: FONT_STYLES.Tabasco_400,
    }]),
    complete: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    completeText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '700',
    }]),
    bottomSection: {
        height: '67%',
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        backgroundColor: '#EEEEF2',
        paddingTop: Platform.select({
            default: 25,
            ios: 40,
        }),
        paddingHorizontal: 30,
        ...mbShadow({
            offsetWidth: -5,
            offsetHeight: 0,
            color: '#000000',
            opacity: 0.15,
            radius: 10,
            elevation: 2,
        }),
    },
    resultTopTitleText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        color: '#292E30',
        marginBottom: 10,
    }]),
    result: {
        paddingHorizontal: 20,
        paddingVertical: 20,
        marginBottom: 15,
    },
    resultTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 25,
        color: '#EF3A24',
    }]),
    btn: {
        width: 227,
        alignSelf: 'center',
    },

    // card
    card: {
        borderWidth: 1,
        borderColor: mbApplyTransparency('#FFFFFF', 0.55),
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 30,
        paddingVertical: 15,
        marginBottom: 15,
    },
    cardTitleText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        textAlign: 'left',
    }]),
    cardPointsText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 22,
        fontWeight: '400',
    }]),
});
