import React, { useRef } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { Header } from '../Tabs/Settings/components/Header';
import { StyleSheet, Text } from 'react-native';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES } from '../../../constants/textStyles';
import { Spacer } from '../../helperComponents/Spacer';
import { useForm } from '../../../hooks/useForm';
import { z } from 'zod';
import { GradientButton } from '../../helperComponents/gradients/GradientButton';
import { CodeInput, CodeInputRef } from '../../helperComponents/CodeInput';
import { ScreenProps } from '../../../typesAndInterfaces/componentProps';
import { trpc } from '../../../apiCalls/trpcClient';
import { utils } from '../../../utils/utils';
import { STRING_CONSTANTS } from '../../../constants/constants';

type ValidForm = {
    code: string
}

const schemas = {
    code: z.string().min(1, { message: 'Code is required' }).min(6, { message: 'The code must be 6 characters long' }),
};

const VerifyForgetPassword = ({ route, navigation }: ScreenProps<'VerifyForgetPassword'>) => {

    const phoneNumber = route.params.phoneNumber;

    const { setCode, errors, validateAll } = useForm<Partial<ValidForm>>({ schemas }, { code: '' });
    const { mutate: requestPasswordRecovery, isLoading: isResending } = trpc.user.requestPasswordRecovery.useMutation();
    const codeInputRef = useRef<CodeInputRef>(null);

    const resend = () => {
        requestPasswordRecovery({
            phoneNumber,
        }, {
            onSuccess: (data) => {
                if (!data) {
                    utils.showPopUp('Phone number not found. Please check your number and try again');
                } else {
                    navigation.setParams({ token: data.token });
                    codeInputRef.current?.reset();
                }
            },
            onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
        });
    };

    const onNext = () => {
        const result = validateAll<ValidForm>();
        if (result.isValid) {
            navigation.navigate('ResetPassword', {
                code: result.code,
                token: route.params.token,
            });
        }
    };

    return (
        <ComponentWrapper containerStyle={styles.container}>
            <Header title="FORGOT PASSWORD" />
            <Spacer height={40} />
            <Text style={styles.title}>Enter Verification code</Text>
            <Spacer height={5} />
            <Text style={styles.subTitle}>Enter the verification code sent to your registered phone number ending in {phoneNumber.slice(-2)}</Text>
            <Spacer height={40} />
            <CodeInput
                ref={codeInputRef}
                onCodeChange={setCode}
                isError={errors.code !== undefined}
                errorMessage={errors.code}
            />
            <Spacer height={40} />
            <Text style={styles.codeSent}>{isResending ? 'Resending Code...' : 'Code successfully sent!'}</Text>
            <Spacer height={10} />
            <Text style={styles.questionText}>Haven’t received  verification code? <Text onPress={isResending ? undefined : resend} style={styles.important}>Try Again</Text></Text>
            <Spacer />
            <GradientButton
                title="Next"
                onPress={onNext}
            />
        </ComponentWrapper>
    );
};

export { VerifyForgetPassword };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingBottom: 30,
    },
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 28,
        color: '#242121',
    }]),
    subTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        textAlign: 'left',
        fontSize: 18,
        color: mbApplyTransparency('#000000', 0.55),
    }]),
    codeSent: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        fontSize: 14,
        fontWeight: '400',
        color: mbApplyTransparency('#000000', 0.55),
        textAlign: 'center',
    }]),
    questionText: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        fontSize: 14,
        fontWeight: '400',
        color: '#000000',
        textAlign: 'center',
    }]),
    important: {
        color: '#F14728',
    },
});
