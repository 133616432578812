import React from 'react';
import { StyleSheet } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { textStyles } from '../../../../constants/textStyles';
import { MB_PageIndicator } from '@mightybyte/rnw.components.page-indicator';

const Indicators = ({ page, totalPages, onPageChange }: { page: number, totalPages: number, onPageChange?: (page: number) => void }) => {

    return (
        <MB_PageIndicator
            current={page}
            pages={totalPages}
            style={styles.pageIndicatorStyle}
            onChange={onPageChange}
            leftButtonProps={{
                title: '',
                icon: <Feather name="chevron-left" color="#F35E2E" size={18} />,
                hoverColor: '#FFFFFF',
                activeColor: '#FFFFFF',
                buttonStyle: styles.button,
                containerStyle: styles.buttonContainer,
            }}
            rightButtonProps={{
                title: '',
                icon: <Feather name="chevron-right" color="#F35E2E" size={18} />,
                hoverColor: '#FFFFFF',
                activeColor: '#FFFFFF',
                buttonStyle: styles.button,
                containerStyle: [styles.buttonContainer, styles.rightButtonContainer],
            }}
            indicatorButtonProp={{
                selectColor: '#F35E2E',
                activeColor: '#FFFFFF',
                hoverColor: 'transparent',
                textStyle: styles.buttonText,
                activeTextColor: '#FFFFFF',
                inactiveTextColor: '#000000',
                buttonStyle: styles.button,
                containerStyle: styles.indicator,
            }}
        />
    );
};

export { Indicators };

const styles = StyleSheet.create({
    pageIndicatorStyle: {
        alignSelf: 'center',
        marginTop: 20,
    },
    buttonText: StyleSheet.flatten([textStyles.smallText, {
        fontWeight: '400',
    }]),
    button: {
        borderWidth: 0,
        borderRadius: 5,
        borderColor: '#EEEEEE',
    },
    buttonContainer: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        cursor: 'auto',
    },
    rightButtonContainer: {
        marginLeft: 28,
    },
    indicator: {
        cursor: 'auto',
        backgroundColor: '#FFFFFF',
        justifyContent: 'center',
        marginEnd: -20,
        borderRadius: 12,
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
});
