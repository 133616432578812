import React from 'react';
import { View, Text , StyleSheet} from 'react-native';
import { Svg, Circle } from 'react-native-svg';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';

interface DonutChartProps {
    percentage: number; // Percentage to display in the half-donut chart (0-100)
}

const DonutChart: React.FC<DonutChartProps> = ({ percentage }) => {
    const radius = 100;
    const strokeWidth = 20;
    const normalizedRadius = radius - strokeWidth / 2;
    console.log('normalizedRadius', normalizedRadius);
    const circumference = normalizedRadius * 2 * Math.PI;
    console.log('circumference', circumference);
    const strokeDashoffset = circumference - (percentage / 200) * circumference;
    console.log('strokeDashoffset', strokeDashoffset);

    return (
        <View style={styles.container}>
            <Svg height={radius} width={radius * 2} viewBox={`0 0 ${radius * 2} ${radius}`}>
                <Circle
                    stroke="#fff"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${circumference} ${circumference}`}
                    r={normalizedRadius}
                    strokeLinecap="round"
                    cx={radius}
                    cy={radius}
                />
                <Circle
                    stroke="#6A2C8C"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    transform={`rotate(-180 ${radius} ${radius})`}
                />
            </Svg>
            <View style={styles.textContainer}>
                <Text style={styles.text}>{percentage}%</Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    textContainer: {
        position: 'absolute',
        bottom: 10,
    },
    text: mbTextStyles([
        textStyles.largerText,
        {
            color: '#6A2C8C',
        },
    ]),
});


export {DonutChart};
