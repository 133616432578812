import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useCallback } from 'react';
import { FlatList, Image, ListRenderItemInfo, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import { ShadowView } from '../../../../helperComponents/ShadowView';
import { Spacer } from '../../../../helperComponents/Spacer';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { ListResultUserDataForClient } from '@sapphicsavvy/business';
import { capitalize } from 'lodash';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { useMBInfiniteFetch } from '@mightybyte/rnw.utils.util-hooks';
import HeartIcon from '../../../../../resources/svgComponents/HeartIcon';
import { mutateToggleLike } from '../../../../../utils/mutations';
import XIcon from '../../../../../resources/svgComponents/XIcon';
import LoadingContent from '../../../../helperComponents/LoadingContent';
import { Filter } from '../modals/FilterModal';
import { ShouldSetResponderWrapper } from '../../../../../utils/shared';

type SapphicsViewProps = {
    likedOnly?: boolean
    filter?: Filter
    query?: string,
    onViewUser?: (userId: string) => void
}

function useInfiniteListOfUsers(options: Pick<SapphicsViewProps, 'likedOnly' | 'filter' | 'query'>) {
    return trpc
        .sapphics
        .listOfUsers
        .useInfiniteQuery({
            liked: !!options.likedOnly,
            filter: options.filter,
            search: options.query ?? undefined,
        }, {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
            cacheTime: 0,
        });
}

function useToggleLike(options: Pick<SapphicsViewProps, 'likedOnly' | 'filter' | 'query'>) {
    const trpcUtils = trpc.useUtils();
    return trpc
        .sapphics
        .toggleLike
        .useMutation(mutateToggleLike(trpcUtils, [{
            liked: false,
            filter: options.filter,
            search: options.query ?? undefined,
        }, {
            liked: true,
            filter: options.filter,
            search: options.query ?? undefined,
        }]));
}

function getEmptyMessage(options: Pick<SapphicsViewProps, 'likedOnly' | 'filter' | 'query'>) {
    if (options.filter || options.query) {
        return 'No Sapphic found with the applied search text or filter';
    } else if (options.likedOnly) {
        return "You haven't liked any sapphic yet!";
    }
    return 'No Sapphic Found!.';
}


const SapphicsView = ({ likedOnly, filter, query, onViewUser }: SapphicsViewProps) => {

    const trpcUtils = trpc.useUtils();

    const listOfUsersHook = useInfiniteListOfUsers({ likedOnly, filter, query });
    const { mutate: toggleLike } = useToggleLike({ likedOnly, filter, query });
    const { data, loadMore, isLoading, isFetching } = useMBInfiniteFetch(listOfUsersHook, { prefillWhileLoading: true });

    const onToggleLike = useCallback((userId: string, liked: boolean) => toggleLike({ userId }, {
        onSuccess: () => !liked && trpcUtils.sapphics.listOfUsers.invalidate({ liked: true }),
    }), [toggleLike, trpcUtils.sapphics.listOfUsers]);

    const renderItem = useCallback(({ index, item }: ListRenderItemInfo<ListResultUserDataForClient | string>) => {
        const desc = typeof item === 'string' ? 'Loading' : `${capitalize(item.identity)}, ${item.ageGroup} years`;
        const title = typeof item === 'string' ? 'Loading' : item.userName;
        const onPress = typeof item === 'string' ? undefined : () => onViewUser?.(item.id);
        return (
            <ShouldSetResponderWrapper>
                <ShadowView
                    style={[styles.sapphicCard, index % 2 === 0 && styles.sapphicCardRight]}
                    onPress={onPress}
                    disabled={typeof item === 'string'}
                >
                    {typeof item !== 'string' &&
                        <TouchableOpacity
                            style={styles.action}
                            onPress={() => onToggleLike(item.id, item.liked)}
                        >
                            {(likedOnly && item.liked) ?
                                <XIcon />
                                :
                                <HeartIcon isFull={item.liked} />
                            }
                        </TouchableOpacity>
                    }
                    {typeof item === 'string' ?
                        <LoadingContent style={styles.sapphicCardImage} />
                        :
                        <Image style={styles.sapphicCardImage} source={mbGetMediumImage(item.avatarUrl, { asUri: true })} />
                    }
                    <Text style={styles.sapphicCardTitleText} numberOfLines={1}>{title}</Text>
                    <Text style={styles.sapphicCardDescText} numberOfLines={1}>{desc}</Text>
                </ShadowView>
            </ShouldSetResponderWrapper>
        );
    }, [likedOnly, onToggleLike, onViewUser]);

    const itemSeparator = useCallback(() => (
        <ShouldSetResponderWrapper>
            <Spacer height={12} />
        </ShouldSetResponderWrapper>
    ), []);
    const keyExtractor = useCallback((item: ListResultUserDataForClient | string) => typeof item === 'string' ? item : item.id, []);

    const ListFooterComponent = useCallback(() => {
        return (
            <View style={styles.footer}>
                <UIActivityIndicator size={30} color="#F35E2E" />
            </View>
        );
    }, []);

    const ListEmptyComponent = useCallback(() => {
        const message = getEmptyMessage({ likedOnly, filter, query});
        return (
            <View style={styles.empty}>
                <Text style={styles.emptyText}>{message}</Text>
            </View>
        );
    }, [filter, likedOnly, query]);

    return (
        <ShouldSetResponderWrapper style={styles.container}>
            <FlatList
                numColumns={2}
                data={data as (ListResultUserDataForClient | string)[]}
                renderItem={renderItem}
                contentContainerStyle={styles.contentContainer}
                ItemSeparatorComponent={itemSeparator}
                keyExtractor={keyExtractor}
                onEndReached={loadMore}
                onEndReachedThreshold={0.3}
                ListFooterComponent={(isFetching && !isLoading && data.length >= 10) ? ListFooterComponent : null}
                ListEmptyComponent={ListEmptyComponent}
            />
        </ShouldSetResponderWrapper>
    );
};

export { SapphicsView };

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    contentContainer: {
        alignSelf: 'center',
        paddingTop: 12,
        paddingBottom: 12,
        flexGrow: 1,
        width: (13 * 2 + 147) * 2 + 12,
    },
    sapphicCard: {
        padding: 13,
    },
    sapphicCardRight: {
        marginRight: 12,
    },
    sapphicCardImage: {
        width: 147,
        height: 158,
        borderRadius: 8,
    },
    sapphicCardTitleText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 22,
        color: '#F35E2E',
        textAlign: 'center',
        marginTop: 12,
        width: 147,
    }]),
    sapphicCardDescText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.55),
        marginTop: 4,
        width: 147,
    }]),
    action: {
        width: 36,
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: 17,
        top: 17,
        backgroundColor: '#FFFFFF',
        borderRadius: 360,
        zIndex: 12,
        ...mbShadow({
            offsetWidth: 4,
            offsetHeight: 0,
            color: '#000000',
            opacity: 0.25,
            radius: 10,
            elevation: 0,
        }),
    },
    empty: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.55),
    }]),
    footer: {
        height: 60,
    },
});
