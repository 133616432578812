import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SettingsIcon = ({ color = '#EE3823' }: { color?: string }) => {
    return (
        <Svg
            width={37}
            height={37}
            viewBox="0 0 37 37"
            fill="none"
        >
            <Path
                d="M18.422 24.566a5.627 5.627 0 01-5.625-5.625 5.627 5.627 0 015.625-5.625 5.627 5.627 0 015.625 5.625 5.627 5.627 0 01-5.625 5.625zm0-9a3.38 3.38 0 00-3.375 3.375 3.38 3.38 0 003.375 3.375 3.38 3.38 0 003.375-3.375 3.38 3.38 0 00-3.375-3.375z"
                fill={color}
            />
            <Path
                d="M23.237 34.226a4.08 4.08 0 01-.945-.12 3.684 3.684 0 01-2.205-1.665l-.18-.3c-.885-1.53-2.1-1.53-2.985 0l-.165.285a3.596 3.596 0 01-2.205 1.68c-.945.255-1.92.12-2.745-.375l-2.58-1.485a3.983 3.983 0 01-1.47-5.43c.435-.765.555-1.455.3-1.89-.255-.435-.9-.69-1.785-.69a3.982 3.982 0 01-3.975-3.975v-2.64a3.982 3.982 0 013.975-3.975c.885 0 1.53-.255 1.785-.69.255-.435.15-1.125-.3-1.89a3.992 3.992 0 01-.39-3.015 3.87 3.87 0 011.86-2.415l2.595-1.485c1.695-1.005 3.93-.42 4.95 1.305l.18.3c.885 1.53 2.1 1.53 2.985 0l.165-.285c1.02-1.74 3.255-2.325 4.965-1.305l2.58 1.485a3.983 3.983 0 011.47 5.43c-.435.765-.555 1.455-.3 1.89.255.435.9.69 1.785.69a3.982 3.982 0 013.975 3.975v2.64a3.982 3.982 0 01-3.975 3.975c-.885 0-1.53.255-1.785.69-.255.435-.15 1.125.3 1.89.525.915.675 1.995.39 3.015a3.87 3.87 0 01-1.86 2.415l-2.595 1.485c-.57.315-1.185.48-1.815.48zm-4.815-5.55c1.335 0 2.58.84 3.435 2.325l.165.285c.18.315.48.54.84.63.36.09.72.045 1.02-.135l2.595-1.5a1.735 1.735 0 00.645-2.355c-.855-1.47-.96-2.985-.3-4.14.66-1.155 2.025-1.815 3.735-1.815.96 0 1.725-.765 1.725-1.725v-2.64c0-.945-.765-1.725-1.725-1.725-1.71 0-3.075-.66-3.735-1.815-.66-1.155-.555-2.67.3-4.14.225-.39.285-.855.165-1.305-.12-.45-.405-.81-.795-1.05l-2.595-1.485c-.645-.39-1.5-.165-1.89.495l-.165.285c-.855 1.485-2.1 2.325-3.435 2.325s-2.58-.84-3.435-2.325l-.165-.3a1.377 1.377 0 00-1.86-.48l-2.595 1.5a1.735 1.735 0 00-.645 2.355c.855 1.47.96 2.985.3 4.14-.66 1.155-2.025 1.815-3.735 1.815-.96 0-1.725.765-1.725 1.725v2.64c0 .945.765 1.725 1.725 1.725 1.71 0 3.075.66 3.735 1.815.66 1.155.555 2.67-.3 4.14-.225.39-.285.855-.165 1.305.12.45.405.81.795 1.05l2.595 1.485c.315.195.69.24 1.035.15.36-.09.66-.33.855-.645l.165-.285c.855-1.47 2.1-2.325 3.435-2.325z"
                fill={color}
            />
        </Svg>
    );
};

export default SettingsIcon;
