import React, { useCallback} from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';
import { View } from 'react-native';
import { Header } from './Shared/Header';
import { AdminScreenProps} from '../../../typesAndInterfaces/componentProps';
import { QuartersList, QuarterQuestionsView } from './components';

const QuarterQuestions = ({route, navigation}: AdminScreenProps<'QuarterQuestions'>) => {

    const addNewQuarterButtonAction = useCallback(() => {
        navigation.setParams({id: 'new'});
    }, [navigation]);

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.innerContainer}
            containerStyle={sharedStyles.container}
            scrollViewStyle={sharedStyles.scrollView}
            wrapInScrollView
        >
            <Header />
            <View style={sharedStyles.body}>
                {route?.params?.id ? <QuarterQuestionsView id={route.params.id} initialSet={route.params.initialSet}/> :
                    <QuartersList addNewButtonAction={addNewQuarterButtonAction}/>}


            </View>
        </ComponentWrapper>
    );

};

export { QuarterQuestions };

