import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const StandingIcon = ({ color = '#EE3823', size = 25, style }: { color?: string, size?: number, style?: StyleProp<ViewStyle> }) => {
    return (
        <Svg
            width={size}
            height={size * (32 / 25)}
            viewBox="0 0 25 32"
            fill="none"
            style={style}
        >
            <Path
                d="M18.814 23.453h4.811c.255 0 .467.206.467.459v7.129c0 .25-.21.458-.467.458h-4.811a.464.464 0 01-.467-.458v-7.13c0-.25.211-.458.467-.458zM4.696 2.501c5.672-3.854 6.098 3.773 11.667-.988v7.772c-5.312 4.695-6.458-2.892-11.667.965V2.5zM3.296.38c.599 0 1.083.476 1.083 1.064 0 .39-.214.732-.53.916h.05v9.423h2.135c.255 0 .466.205.466.459v18.8c0 .25-.209.458-.466.458H1.22a.464.464 0 01-.467-.458v-18.8c0-.25.209-.459.467-.459h1.476V2.361h.052a1.06 1.06 0 01-.533-.916C2.215.857 2.699.38 3.297.38zm6.72 16.894h4.81c.256 0 .467.205.467.458v13.31c0 .25-.208.458-.466.458h-4.812a.464.464 0 01-.466-.458v-13.31a.466.466 0 01.466-.458z"
                fill={color}
            />
        </Svg>
    );
};

export default StandingIcon;

