import { z } from 'zod';
import { DataExport } from '../../../entities/dataExport';
import { AgeGroup, UserIdentity } from '../../../entities';

export const PopulateVotesInputSchema = z.object({
    quarterId: z.string(),
});

export const DataExportInputTypeSchema = z.object({
    dataType: z.nativeEnum(DataExport),
    filters: z.object({
        userFilter: z
            .object({
                identity: z.nativeEnum(UserIdentity).optional(),
                ageGroup: z.nativeEnum(AgeGroup).optional(),
                isInStudy: z.boolean().optional(),
            })
            .optional(),
        quarterFilter: z
            .object({
                quarterNumber: z.number().optional(),
                setNumber: z.number().optional(),
            })
            .optional(),
    }),
});
