import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native';
import { textStyles } from '../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

import Feather from 'react-native-vector-icons/Feather';
import { ShadowView } from './ShadowView';

type CardProps = {
    icon?: JSX.Element,
    title: string,
    titleStyle?: StyleProp<TextStyle>
    onPress?: () => void,
    rightIcon?: React.ReactChild | null,
}

const MenuCard = ({ icon, title, titleStyle, onPress, rightIcon }: CardProps) => {

    return (
        <ShadowView
            style={[styles.card, styles.cardContainer]}
            onPress={onPress}
        >
            {icon}
            <View style={styles.cardTextContainer}>
                <Text style={[styles.cardTitle, titleStyle]}>{title}</Text>
            </View>
            {rightIcon === undefined ?
                <Feather name="chevron-right" size={20} color="#F35E2E" />
                :
                rightIcon
            }
        </ShadowView>
    );
};

export { MenuCard };

const styles = StyleSheet.create({
    card: {
        borderRadius: 10,
        marginTop: 15,
        justifyContent: 'center',
    },
    cardContainer: {
        height: 54,
        paddingHorizontal: 14,
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardTextContainer: {
        marginLeft: 10,
        marginRight: 'auto',
    },
    cardTitle: mbTextStyles([textStyles.normalText, {
        fontWeight: '400',
        color: '#000000',
    }]),
});
