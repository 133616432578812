import React, { useState } from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { getColors } from '../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { textStyles } from '../../constants/textStyles';
import { useSignedInContext } from '../../context/SignedInContext';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

const ServerIsDownPage = ({ onTryAgainPressed, isLoading }: { onTryAgainPressed: () => void, isLoading: boolean }) => {
    const [numTries, setNumTries] = useState(0);
    const { signOut } = useSignedInContext();

    return (
        <View style={styles.container}>
            <View>
                <Text style={styles.technicalIssuesText}>We are having technical difficulties on our end{'\n'}Please try again later</Text>
                <MB_Button
                    title={'Try Again'}
                    disabled={isLoading}
                    loading={isLoading}
                    onPress={() => {
                        if (numTries >= 3) {
                            signOut({ skipSignOutRequest: true });
                            return;
                        }
                        onTryAgainPressed();
                        setNumTries(old => old + 1);
                    }}
                    style={{ marginTop: 32, width: 200, alignSelf: 'center', height: 54 }}
                />
            </View>
        </View>
    );
};

export { ServerIsDownPage };

const COLORS = getColors();

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        justifyContent: 'center',
    },
    technicalIssuesText: mbTextStyles([
        textStyles.largerText, {
            color: COLORS.primaryColor,
        },
    ]),
});
