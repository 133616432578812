import React from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import { Spacer } from '../../../helperComponents/Spacer';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { trpc } from '../../../../apiCalls/trpcClient';
import { utils } from '../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { formUtils, useForm } from '../../../../hooks/useForm';
import { z } from 'zod';
import { TextInput } from '../../../helperComponents/ShadowTextInput';
import { useSignedInContext } from '../../../../context/SignedInContext';

type ValidForm = {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

const schemas = {
    currentPassword: z.string().min(1, { message: 'Current password is required' }),
    newPassword: formUtils.validatePassword,
    confirmPassword: formUtils.validatePassword,
};

const onValidateAll = (schema: z.ZodSchema) => {
    return schema.superRefine((args, ctx) => {
        if (args.newPassword !== args.confirmPassword) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: STRING_CONSTANTS.PASSWORDS_DO_NOT_MATCH,
                path: ['confirmPassword'],
            });
        }
    });
};

const ChangePasswordModal = ({ isVisible, onDismiss }: { isVisible: boolean, onDismiss?: () => void }) => {

    const { mutate: changePassword, isLoading } = trpc.user.changePassword.useMutation();
    const { currentUserData } = useSignedInContext();

    const {
        setCurrentPassword,
        setNewPassword,
        setConfirmPassword,
        errors,
        validateAll,
    } = useForm<Partial<ValidForm>>(
        { schemas, onValidateAll },
        {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        }
    );

    const onSave = () => {

        const result = validateAll<ValidForm>();
        if (result.isValid) {
            changePassword({
                currentPassword: result.currentPassword,
                newPassword: result.newPassword,
                confirmPassword: result.confirmPassword,
            }, {
                onSuccess: () => {
                    onDismiss?.();
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    };

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            hideCloseButton
            childrenWrapperStyle={styles.container}
        >
            <View style={styles.row}>
                <View style={styles.row}>
                    <View style={styles.icon}>
                        <Feather name="settings" color="#FFFFFF" size={18} />
                    </View>
                    <Text style={[styles.text, styles.primaryText]}>Change Password</Text>
                </View>
                <Text style={[styles.text]}>{currentUserData?.email}</Text>
            </View>
            <Spacer height={40} />
            <TextInput
                title="Current Password"
                placeholder="Current Password"
                showRequiredAsteriks
                secureTextEntry
                onChangeText={setCurrentPassword}
                isError={errors.currentPassword !== undefined}
                errorMessage={errors.currentPassword}
                style={styles.textInput}
                placeholderTextColor="#959595"
                textInputStyle={styles.textInputText}
            />
            <Spacer height={25} />
            <TextInput
                title="Create Password"
                placeholder="Create Password"
                showRequiredAsteriks
                secureTextEntry
                onChangeText={setNewPassword}
                isError={errors.newPassword !== undefined}
                errorMessage={errors.newPassword}
                style={styles.textInput}
                placeholderTextColor="#959595"
                textInputStyle={styles.textInputText}
            />
            <Spacer height={25} />
            <TextInput
                title="Re-Enter Password"
                placeholder="Re-Enter Password"
                showRequiredAsteriks
                secureTextEntry
                onChangeText={setConfirmPassword}
                isError={errors.confirmPassword !== undefined}
                errorMessage={errors.confirmPassword}
                style={styles.textInput}
                placeholderTextColor="#959595"
                textInputStyle={styles.textInputText}
            />
            <Spacer height={40} />
            <View style={styles.footer}>
                <MB_Button
                    title="Save Changes"
                    style={styles.primaryBtn}
                    textStyle={styles.primaryBtnText}
                    onPress={onSave}
                    disabled={isLoading}
                    loading={isLoading}
                />
                <MB_Button
                    title="Cancel"
                    style={styles.secondaryBtn}
                    textStyle={[styles.primaryBtnText, styles.secondaryBtnText]}
                    onPress={onDismiss}
                />
            </View>
        </MB_Modal>
    );
};

export { ChangePasswordModal };

const styles = StyleSheet.create({
    container: {
        width: 533,
        paddingTop: 20,
        paddingBottom: 0,
        paddingHorizontal: 20,
        alignItems: 'stretch',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        width: 28,
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 80,
        backgroundColor: '#F35E2E',
        marginRight: 8,
    },
    text: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        textAlign: 'left',
    }]),
    primaryText: {
        fontWeight: '600',
        color: '#F35E2E',
    },
    textInput: {
        backgroundColor: '#FFFFFF',
        borderWidth: 0.5,
        borderColor: '#696969',
        borderRadius: 5,
    },
    textInputText: mbTextStyles([textStyles.smallText, {
        fontWeight: '400',
        textAlign: 'left',
        color: '#000000',
    }]),
    footer: {
        height: 95,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FEF2EE',
        paddingHorizontal: 15,
        flexDirection: 'row',
        marginHorizontal: -20,
    },
    primaryBtn: {
        width: 244,
        height: 49,
        backgroundColor: '#F35E2E',
        borderRadius: 8,
        borderWidth: 0,
    },
    primaryBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
    }]),
    secondaryBtn: {
        width: 244,
        height: 49,
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: '#F35E2E',
    },
    secondaryBtnText: {
        color: '#F35E2E',
    },
});
