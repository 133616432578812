import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { quarterSharedStyles } from './quarterSharedStyles';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { AgeGroup, QuarterStatus, UserIdentity} from '@sapphicsavvy/business';
import { Spacer } from '../../../../helperComponents/Spacer';
import { QuestionUI, ResultOptions } from './QuestionUI';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { getColors } from '../../../../../constants/colors';
import { MB_TextInput, MB_TextInputInputType } from '@mightybyte/rnw.components.text-input';
import DatePicker from '../../../../helperComponents/DatePicker';
import { DefaultSwitch } from '../../../../helperComponents/DefaultSwitch';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { envs } from '../../../../../../env';
import { endOfToday, sub } from 'date-fns';
import {Question} from './QuestionUI';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { utils } from '../../../../../utils/utils';

const COLORS = getColors();


type Props = {
    onBackButtonPress: () => void;
    onSave: () => void;
    onPublish: () => void;
    id: string;
    errors?: Record<string, string>;
    canEditQuarter: boolean;
    viewResults: boolean;
    setNumber?: number;
    setSetNumber: (value: number) => void;
    quarterNumber?: number;
    setQuarterNumber: (value: number) => void;
    questions?: Question[];
    addQuestion: () => void;
    setQuestions: (questions: Question[]) => void;
    startDate?: Date
    setStartDate: (date: Date | undefined) => void;
    endDate?: Date
    setEndDate: (date: Date | undefined) => void;
    quarterData?: any;
    viewResultsIdentity: UserIdentity | undefined;
    setViewResultsIdentity: (identity: UserIdentity | undefined) => void;
    viewResultsAge: AgeGroup | undefined;
    setViewResultsAge: (age: AgeGroup | undefined) => void;
    modifyQuestion: (index:number, data: Partial<Question>) => void;
    onDeletePress: (index: number) => void;
    canPublishQuarter: ()=> boolean;
    setQuestionToPreview: (question: string) => void;
    expandedTabs: number[];
    toggleExpand: (index: number) => void;
    canUpdateFacts: boolean;
    resultOptions: ResultOptions
}

const QuarterQuestionsViewUI = (
    {
        onBackButtonPress,
        onSave,
        onPublish,
        id,
        errors,
        canEditQuarter,
        viewResults,
        setNumber,
        setSetNumber,
        quarterNumber,
        setQuarterNumber,
        questions,
        addQuestion,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        quarterData,
        viewResultsIdentity,
        setViewResultsIdentity,
        viewResultsAge,
        setViewResultsAge,
        modifyQuestion,
        onDeletePress,
        canPublishQuarter,
        setQuestionToPreview,
        expandedTabs,
       toggleExpand,
        canUpdateFacts,
        resultOptions,

    }:Props) => {


    function calculateEndDate(start: Date | undefined) {
        if (!start) {return undefined;}

        const date = new Date(start);
        date.setHours(date.getHours() + envs.QUARTER_DURATION_HOURS); // Add 24 hours to the date
        return date;
    }



    const ErrorsComponent = useMemo(() => {
        return (<View style={{justifyContent: 'center', height: 40}}>
            {(!errors || Object.keys(errors).length === 0) ? null : Object.entries(errors).map(([key, value]) => {
                return <Text style={styles.errorText} key={key}>{value}</Text>;
            })}
        </View>);
    }, [ errors]);

    const TopSectionContent = useMemo(() => {
        if (canEditQuarter) {
            return (
                <>
                    <MB_TextInput
                        title={'Set'}
                        onChangeText={(text) => setSetNumber(Number(text))}
                        value={setNumber?.toString()}
                        showRequiredAsteriks
                        inputType={MB_TextInputInputType.numbers}
                        titleStyle={quarterSharedStyles.textInputTitle}
                        textInputStyle={quarterSharedStyles.textInputStyle}
                        style={quarterSharedStyles.smallInput}
                    />

                    <Spacer width={40}/>
                    <MB_TextInput
                        title={'Quarter'}
                        onChangeText={(text) => setQuarterNumber(Number(text))}
                        value={quarterNumber?.toString()}
                        showRequiredAsteriks
                        inputType={MB_TextInputInputType.numbers}
                        titleStyle={quarterSharedStyles.textInputTitle}
                        textInputStyle={quarterSharedStyles.textInputStyle}
                        style={quarterSharedStyles.smallInput}
                    />
                    <Spacer width={40}/>
                    <View>
                        <DatePicker title={'Start Date'}
                                    minDate={ sub(endOfToday(), { days: 0 })}
                                    onDateChange={(d)=> {
                                        setStartDate(d);
                                        setEndDate(calculateEndDate(d));
                                    }}
                                    showRequiredAsteriks
                                    date={startDate}
                                    titleStyle={quarterSharedStyles.textInputTitle}
                                    />
                    </View>
                    <Spacer width={40}/>
                    <View>
                        <DatePicker title={'End Date'}
                                    key={endDate?.toString()}
                                    onDateChange={(d)=> {
                                        setStartDate(d);
                                        setEndDate(calculateEndDate(d));
                                    }}
                                    disabled
                                    date={endDate}
                                    titleStyle={quarterSharedStyles.textInputTitle}
                        />
                    </View>
                </>
            );
        }
        const startDateToDisplay = `${quarterData?.startDate.month}/${quarterData?.startDate.day}/${quarterData?.startDate.year}`;
        const endDateToDisplay = `${quarterData?.endDate.month}/${quarterData?.endDate.day}/${quarterData?.endDate.year}`;


        return (
            <View style={[quarterSharedStyles.rowSpaceBetween, {flex: 1}]}>
                <Text style={styles.orangeAccentText}>
                    {`Set ${quarterData?.setNumber}, Quarter ${quarterData?.quarterNumber}`}
                </Text>
                <Text  style={mbTextStyles([textStyles.normalText,{color: COLORS.bodyText, textAlign: 'left'}])}>
                    {`${startDateToDisplay} - ${endDateToDisplay}`}
                </Text>
            </View>

        );
    }, [canEditQuarter, quarterData?.startDate.month, quarterData?.startDate.day, quarterData?.startDate.year, quarterData?.endDate.month, quarterData?.endDate.day, quarterData?.endDate.year, quarterData?.setNumber, quarterData?.quarterNumber, setNumber, quarterNumber, startDate, endDate, setSetNumber, setQuarterNumber, setStartDate, setEndDate]);

    const QuestionsHeader = useMemo(() => {
        if (canEditQuarter) {
            return (
                <View style={quarterSharedStyles.rowSpaceBetween}>
                    <Text style ={styles.titleText2}>Quarter Questions</Text>
                    <MB_Button title={'Add Question'}
                               style={quarterSharedStyles.button}
                               textStyle={quarterSharedStyles.buttonText}
                               leftElement={<AntDesign name="plus" size={20} color="white" />}
                               onPress={addQuestion}
                               disabled={questions?.length ? questions.length >= 4 : false}
                    />

                </View>
            );
        }

        const status = quarterData?.status || '';

        const displayStatus = status?.slice(0, 1)?.toUpperCase() + status?.slice(1)?.toLowerCase();
        return (
            <View style={[quarterSharedStyles.section, {padding: 3, zIndex: 1000}]}>
                <View style={[quarterSharedStyles.section, {backgroundColor: '#FFF7F5'}]}>
                    {viewResults ? <View style={quarterSharedStyles.rowSpaceBetween}>
                            <Text style={[styles.orangeAccentText, {fontSize: 16}]}>Quarter Results</Text>
                            <View style={quarterSharedStyles.row}>
                                {Object.values(UserIdentity).map((identity) => {
                                    return <>
                                        <DefaultSwitch key={identity} isToggled={viewResultsIdentity === identity}
                                                       onToggle={(isToggled) => {
                                                           setViewResultsIdentity(isToggled ? identity : undefined);
                                                       }}
                                                       labelLeft={true}
                                                       label={identity}
                                        />
                                        <Spacer width={30}/>
                                    </>;
                                })}
                                <View style={[quarterSharedStyles.row, {zIndex: 1000}]} >
                                    <Text style={mbTextStyles([textStyles.normalText, {
                                        color: COLORS.textInputText,
                                        textAlign: 'left',
                                    }])}>Age group:</Text>
                                    <Spacer width={10}/>
                                    <View style={{width: 200}}>
                                        <MB_DropDownPicker multiple={false}
                                                           placeholder={'Select Age Group'}
                                                           value={viewResultsAge}
                                                           containerStyle={{
                                                               backgroundColor: COLORS.white,
                                                           }}
                                                           arrowColor={COLORS.orange}
                                                           placeholderStyle={mbTextStyles([textStyles.smallerText, {
                                                               color: COLORS.orange,
                                                               fontSize: 14,
                                                           }])}
                                                           searchContainerStyle={{
                                                               backgroundColor: COLORS.white,
                                                           }}

                                                           dropdownLabelStyle={mbTextStyles([textStyles.normalText, {
                                                                color: COLORS.textPrimary,
                                                                textAlign: 'left',
                                                           }])}

                                                           dropdownContainerStyle={{
                                                               backgroundColor: COLORS.white,
                                                               borderColor: '#D1D1D18C',
                                                               borderWidth: 1,
                                                               borderRadius: 6,
                                                               padding: 10,
                                                              maxHeight: 250,
                                                           }}
                                                           labelStyle={mbTextStyles([textStyles.normalText, {
                                                               color: COLORS.textPrimary,
                                                               textAlign: 'left',
                                                           }])}
                                                           highlightColor={'#D1D1D1'}
                                                           itemSeparatorStyle={{
                                                               height: 1,
                                                               backgroundColor: '#D1D1D18C',
                                                           }}
                                                           onValueChange={
                                                               (value) => {
                                                                   setViewResultsAge(value);
                                                               }
                                                           }
                                                           items={[...[{label: 'All', value: undefined}], ...Object.values(AgeGroup).map((age) => {
                                                               return {
                                                                   label: age,
                                                                   value: age,
                                                               };
                                                           })]}/>
                                    </View>

                                </View>


                            </View>
                        </View> :
                        <Text style={styles.orangeAccentText}>{`Quarter is ${displayStatus}`}</Text>
                    }

                </View>

            </View>
        );
    }, [canEditQuarter, viewResults, quarterData, viewResultsIdentity, viewResultsAge, questions, addQuestion, setViewResultsAge, setViewResultsIdentity]);

    const headerText2 = id === 'new' ? '' : quarterData ? `Quarter ${quarterData.quarterNumber} - Set ${quarterData.setNumber}` : null;

    const isDraft = quarterData?.status === QuarterStatus.draft || !quarterData?.status;

    const ButtonsSection = useMemo(() => {
        if (canEditQuarter) {
            return (
            <>
                {canPublishQuarter() ? null : <><Text>
                    Add 4 questions to publish quarter
                </Text>
                <Spacer height={12}/>
                </>}

                <View style={quarterSharedStyles.row}>
                    <MB_Button title={id === 'new' ? 'Save' : 'Save Changes'}
                               style={quarterSharedStyles.button}
                               textStyle={quarterSharedStyles.buttonText}
                               onPress={onSave}
                    />
                    <Spacer width={20}/>
                    <MB_Button title={'Publish quarter'}
                               style={[quarterSharedStyles.secondaryBtn, {
                                   borderColor: canPublishQuarter() ? COLORS.orange : mbApplyTransparency(COLORS.orange, 0.5),
                               }]}
                               textStyle={quarterSharedStyles.secondaryBtnText}
                               onPress={onPublish}
                               disabled={!canPublishQuarter()}
                    />
                </View>
            </>
            );
        } else if (canUpdateFacts) {
           return  (<View style={quarterSharedStyles.row}>
                <MB_Button title={'Save Changes'}
                           style={quarterSharedStyles.button}
                           textStyle={quarterSharedStyles.buttonText}
                           onPress={onSave}
                />
            </View>);
        }
        return null;
    }, [canPublishQuarter, canUpdateFacts, canEditQuarter, onSave, id, onPublish]);

    const {mutate: voteForBots, isLoading: voting} = trpc.admin.utils.populateVotes.useMutation();
    const triggerBotVotes = ()=> {
        voteForBots({quarterId: id}, {
            onSuccess: ()=> {
                utils.showToast('success', 'Bot votes generated successfully');
            },
            onError: (error)=> {
                utils.showToast('error', error.message);
            },
        });
    };

    const {mutate: deleteBotVotes, isLoading: deletingBotVotes} = trpc.admin.utils.deleteBotVotes.useMutation();

    const triggerDeleteBotVotes = ()=> {
        deleteBotVotes({quarterId: id}, {
            onSuccess: ()=> {
                utils.showToast('success', 'Bot votes deleted successfully');
            },
            onError: (error)=> {
                utils.showToast('error', error.message);
            },
        });
    };

    const {mutate: calculateResults, isLoading: calculating} = trpc.admin.utils.calculateResults.useMutation();
    const triggerCalculations = ()=> {
        calculateResults({quarterId: id}, {
            onSuccess: ()=> {
                utils.showToast('success', 'Results calculated successfully');
            },
            onError: (error)=> {
                utils.showToast('error', error.message);
            },
        });
    };

    return (
        <View style={styles.container}>
            <View style={quarterSharedStyles.row}>
                <TouchableOpacity onPress={onBackButtonPress} style={styles.backButton}>
                    <AntDesign name={'arrowleft'} size={28}/>
                </TouchableOpacity>
                {id === 'new' || quarterData?.status === QuarterStatus.draft ?
                    <Text style = {[quarterSharedStyles.titleText, quarterSharedStyles.uppercase]}>{id === 'new' ? 'New' : 'Draft'}</Text> : null
                }
                <Spacer width={10}/>
                <Text style={[quarterSharedStyles.titleText]}>{`${headerText2}`}</Text>

                {process.env.NODE_ENV === 'test' ? null :
                    <View style={{flexDirection: 'row', marginLeft: 50}}>
                    <MB_Button title={'Generate Bot Votes'}
                               onPress={triggerBotVotes}
                               loading={voting}
                    />
                        <Spacer width={50}/>
                        <MB_Button title={'Delete Bot Votes'}
                                   onPress={triggerDeleteBotVotes}
                                   loading={deletingBotVotes}
                        />
                        <Spacer width={50}/>
                        <MB_Button title={'Calculate Results'}
                                   onPress={triggerCalculations}
                                      loading={calculating}
                        />
                    </View>
                }

            </View>
            <View>
                {/*top section*/}
                <View style={[quarterSharedStyles.section, styles.topSection]}>
                    {TopSectionContent}

                </View>

                {/*    Questions*/}
                {QuestionsHeader}
                <Spacer height={20}/>
                {questions ? questions?.map((question, index) => {
                    return <View key={index}>
                        <QuestionUI question={question}
                                    index={index}
                                    expanded={expandedTabs.includes(index)}
                                    toggleExpand={toggleExpand}
                                    onDeletePress = {onDeletePress}
                                    modifyQuestion={modifyQuestion}
                                    setQuestionToPreview={setQuestionToPreview}
                                    viewResults={viewResults}
                                    isDraft={isDraft}
                                    canUpdateFacts={canUpdateFacts}
                                    resultOptions={resultOptions}
                        />
                        <Spacer height={20}/>
                    </View>;
                }) : null}
                <Spacer height={20}/>

                {ErrorsComponent}

                {ButtonsSection}
            </View>
        </View>

    );
};

export {QuarterQuestionsViewUI};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },

    titleText2: mbTextStyles([textStyles.largerText, {
        fontSize: 18,
        textAlign: 'left',
        color: COLORS.headerText,
    }]),
    backButton: {
        padding: 10,
    },

    topSection: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        paddingTop: 28,
        paddingBottom: 28,
    },


    dateInput: {
        maxWidth: 200,
        minHeight: 51,
        backgroundColor: 'transparent',
    },

    disabled: {
        backgroundColor: '#F2F2F2',
    },

    requiredAsteriksColor: {
        color: '#DD3636',
        fontSize: 16,
    },
    errorText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#FF0000', 0.80),
    }]),
    orangeAccentText: mbTextStyles([textStyles.largerText,{fontSize: 18, color: COLORS.orange, textAlign: 'left'}]),

    selectedDateContainerStyle: {
        backgroundColor: COLORS.orange,
        borderColor: COLORS.orange,
        borderWidth: 1,
    },
    headerTextStyle: {
        color: COLORS.textPrimary,
    },
});
