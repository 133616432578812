import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const HomeIcon = ({ color = '#EE3823' }: { color?: string }) => {
    return (
        <Svg
            width={37}
            height={37}
            viewBox="0 0 37 37"
            fill="none"
        >
            <Path
                d="M27.107 35.066H9.737c-4.11 0-7.44-3.345-7.44-7.455V16.496c0-2.04 1.26-4.605 2.88-5.865l8.085-6.3c2.43-1.89 6.315-1.98 8.835-.21l9.27 6.495c1.785 1.245 3.18 3.915 3.18 6.09v10.92c0 4.095-3.33 7.44-7.44 7.44zM14.642 6.101l-8.085 6.3c-1.065.84-2.01 2.745-2.01 4.095v11.115c0 2.865 2.325 5.205 5.19 5.205h17.37a5.192 5.192 0 005.19-5.19v-10.92c0-1.44-1.035-3.435-2.22-4.245l-9.27-6.495c-1.71-1.2-4.53-1.14-6.165.135z"
                fill={color}
            />
            <Path
                d="M18.422 29.066c-.615 0-1.125-.51-1.125-1.125v-4.5c0-.615.51-1.125 1.125-1.125s1.125.51 1.125 1.125v4.5c0 .615-.51 1.125-1.125 1.125z"
                fill={color}
            />
        </Svg>
    );
};

export default HomeIcon;
