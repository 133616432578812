import React from 'react';
import { Dimensions, Platform, StatusBar, StyleSheet, Text, View } from 'react-native';
import { Spacer } from '../../../../helperComponents/Spacer';
import { ShadowView } from '../../../../helperComponents/ShadowView';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES } from '../../../../../constants/textStyles';
import { initialWindowMetrics } from 'react-native-safe-area-context';

const Header = ({ title, withoutShadow, step, steps }: { title: string, withoutShadow?: boolean, step: number, steps: number }) => {

    const HeaderContainer = withoutShadow ? View : ShadowView;
    const stepWidth = ((step) / steps) * 100;

    return (
        <HeaderContainer style={styles.header}>
            <StatusBar backgroundColor="#F35E2E" />
            <Text style={styles.headerText}>{title}</Text>
            <Spacer height={10} />
            <View style={styles.steps}>
                <View style={[styles.step, { width: `${stepWidth}%` }]} />
                <View style={styles.bar} />
            </View>
        </HeaderContainer>
    );
};

export { Header };

const styles = StyleSheet.create({
    header: {
        marginHorizontal: -30,
        height: Dimensions.get('window').height - (Platform.OS === 'android' ? initialWindowMetrics?.insets.top || 0 : 0),
        backgroundColor: '#F35E2E',
        borderRadius: 0,
        marginTop: -Dimensions.get('window').height + 130,
    },
    headerText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'center',
        fontSize: 25,
        color: '#FFFFFF',
        marginTop: 'auto',
    }]),
    steps: {
        height: 14,
        width: '100%',
        backgroundColor: mbApplyTransparency('#FFFFFF', 0.25),
    },
    step: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: 6,
        backgroundColor: '#FFFFFF',
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
    },
    bar: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 8,
        backgroundColor: '#DF5628',
        width: '100%',
    },
});
