import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function GoodHeartIcon({ color = '#8D9297' }: { color?: string }) {
  return (
    <Svg
      width={23}
      height={20}
      viewBox="0 0 23 20"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.51 3.853C12.927 2.4 13.92 1.144 16.103.899 20.2.436 23.97 4.563 21.9 8.625c-.589 1.157-1.788 2.533-3.115 3.883-1.456 1.482-3.067 2.935-4.196 4.036l-3.078 3.003-2.544-2.408C5.908 14.241.918 10.593.753 6.073.638 2.907 3.18.878 6.101.915c2.61.034 3.71 1.311 5.41 2.938zM7.704 9.798a.967.967 0 01-.029-1.378.998.998 0 011.4-.028l1.507 1.42 3.584-3.275a1.01 1.01 0 01.736-.268l.012.002c.25.016.492.125.672.322a.96.96 0 01.251.703l-.002.012a.967.967 0 01-.316.65l-4.263 3.908a1 1 0 01-1.364-.005L7.704 9.798z"
        fill={color}
      />
    </Svg>
  );
}

export default GoodHeartIcon;
