import * as Font from 'expo-font';
import {
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
} from '@expo-google-fonts/inter';
import {
    PlusJakartaSans_400Regular,
    PlusJakartaSans_500Medium,
    PlusJakartaSans_600SemiBold,
} from '@expo-google-fonts/plus-jakarta-sans';

import { Staatliches_400Regular } from '@expo-google-fonts/staatliches';

export const fontLoader = async (): Promise<any> => {
    const expoFontPromise = Font.loadAsync({
        AntDesign: require('../../node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
        Feather: require('../../node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
        FontAwesome: require('../../node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf'),
        MaterialCommunityIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
        MaterialIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
        Entypo: require('../../node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),
        Tabasco_400: require('../resources/fonts/Tabasco/Tabasco_400Regular.ttf'),
        Tabasco_700: require('../resources/fonts/Tabasco/Tabasco_700Bold.ttf'),
        Inter_100: Inter_100Thin,
        Inter_200: Inter_200ExtraLight,
        Inter_300: Inter_300Light,
        Inter_400: Inter_400Regular,
        Inter_500: Inter_500Medium,
        Inter_600: Inter_600SemiBold,
        Inter_700: Inter_700Bold,
        Inter_800: Inter_800ExtraBold,
        Inter_900: Inter_900Black,
        PlusJakartaSans_400: PlusJakartaSans_400Regular,
        PlusJakartaSans_500: PlusJakartaSans_500Medium,
        PlusJakartaSans_600: PlusJakartaSans_600SemiBold,
        Staatliches_400: Staatliches_400Regular,
    });

    return Promise.all([expoFontPromise]);
};
