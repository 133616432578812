import React from 'react';
import { ComponentWrapper } from '../../../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from '../../Shared/styles';
import { Header } from './Header';
import { Linking, StyleSheet, Text, View } from 'react-native';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import { ShadowView } from '../../../../helperComponents/ShadowView';

export default function ContactUs() {

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.container}
            wrapInScrollView={false}
        >
            <Header title="CONTACT US" />
            <View style={styles.content}>
                <Text style={styles.titleText}>Share your Thoughts</Text>
                <Text style={styles.messageText}>We'd love to hear from you!</Text>
                <ShadowView style={styles.note}>
                    <Text style={styles.noteText}>If you have any questions, feedback, or concerns about Sapphic Savvy, please don't hesitate to get in touch. Our dedicated support team is always ready to assist.</Text>
                    <Text />
                    <Text style={styles.noteText}>You can reach us via email at</Text>
                    <Text style={[styles.noteText, styles.mailText]} onPress={() => Linking.openURL('mailto:support@sapphicsavvy.com')}>
                        support@sapphicsavvy.com.
                    </Text>
                    <Text />
                    <Text style={styles.noteText}>We'll respond as quickly as possible!</Text>
                </ShadowView>
            </View>
        </ComponentWrapper>
    );
}

const styles = StyleSheet.create({
    content: {
        paddingTop: 50,
        paddingHorizontal: 15,
    },
    titleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 25,
        color: '#000000',
    }]),
    messageText: mbTextStyles([textStyles.normalText, {
        fontWeight: '400',
        color: mbApplyTransparency('#6D6E71', 0.96),
        marginBottom: 40,
        marginTop: 5,
    }]),
    note: {
        paddingHorizontal: 25,
        paddingVertical: 30,
    },
    noteText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.85),
        textAlign: 'left',
    }]),
    mailText: {
        color: '#FF3737',
    },
});
