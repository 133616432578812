import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { getColors } from '../../../../../constants/colors';
import TrashIcon from '../../../../../resources/svgComponents/TrashIcon';

const COLORS = getColors();

const AdminConfirmPopup = ({
    icon,
    iconBackground,
    title,
    middle,
    note,
    buttons,
}:{
    icon?: React.ReactNode;
    iconBackground?: string;
    title: string;
    middle?: string;
    note?: string;
    buttons: React.ReactNode[];
}) => {
    return (
        <View style={styles.container}>
            <View style={[styles.iconContainer, {backgroundColor: iconBackground}]}>
                {icon ? icon : <TrashIcon />}
            </View>
            <Text style={styles.titleText}>{title}</Text>
            {middle || note ? <View style={styles.middleWrapper}>

                {middle ? <View style={styles.recordDetailsWrapper}>
                    <Text style={styles.recordDetailsText}>{middle}</Text>
                </View> : null}

                {note ? <Text style={styles.noteText}>{note}</Text> : null}
            </View> : null}

            <View style={styles.buttonsContainerWrapper}>
                {buttons.map((button, index) => {
                    return (
                        <View key={index} style={{width: buttons.length === 1 ? '100%' : undefined}}>
                            {button}
                        </View>
                    );
                })}
            </View>

        </View>
    );
};

export { AdminConfirmPopup };

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        width: 409,
        alignItems: 'center',
        paddingTop: 24,
    },
    iconContainer: {
        width: 61,
        height: 61,
        borderRadius: 31,
        backgroundColor: '#E5323221',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 18,
    },
    titleText: mbTextStyles([textStyles.largerText, {
        fontSize: 21,
        color: COLORS.headerText,
        marginBottom: 16,
        paddingHorizontal: 24,
    }]),
    noteText: mbTextStyles([textStyles.smallerText, {
        fontSize: 16,
        color: COLORS.headerText,
    }]),

    recordDetailsText: mbTextStyles([textStyles.normalText, {
        fontSize: 16,
        color: COLORS.headerText,
    }]),

    recordDetailsWrapper: {
        backgroundColor: '#F5F7F7',
        padding: 20,
        marginBottom: 16,
    },
    buttonsContainerWrapper: {
        marginTop: 18,
        backgroundColor: '#FEF2EE',
        padding: 20,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    middleWrapper: {
        padding: 24,
    },


});

