import React, { useContext, useState } from 'react';
import { deleteResponseInterceptors, setResponseInterceptors } from '../apiCalls/customAxios';
import { userApiCalls } from '../apiCalls/userApiCalls';
import { useQueryClient } from '@tanstack/react-query';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_accessTokenUtils, Token } from '@mightybyte/rnw.utils.access-token-utils';
import { getRootNavRef } from '../navigations/RootNavigator';
import { UserDataForAdmin, UserPrivateDataForClient } from '@sapphicsavvy/business';
import { trpc } from '../apiCalls/trpcClient';

export const signedInContextGlobalFunction: {
    signOut?: (params: ISignOut) => void,
    getSignedInStatus?: () => SIGNED_IN_STATUS,
} = {};

export interface ISignOut { skipSignOutRequest?: boolean; showExpiredError?: boolean; makeRefreshTokenCall?: boolean }

export const enum SIGNED_IN_STATUS {
    loading = 'loading',
    signedIn = 'signedIn',
    signedOut = 'signedOut',
}

const isAdmin = (userData: any): userData is UserDataForAdmin => userData.hasFullAccess !== undefined;

export interface ISignedInContextType {
    isAdmin: (userData?: UserPrivateDataForClient | UserDataForAdmin) => userData is UserDataForAdmin,
    isSignedIn: boolean,
    signedInStatus: SIGNED_IN_STATUS,
    currentUserData: UserPrivateDataForClient | undefined,
    setSignedInStatus: (isSignedIn: SIGNED_IN_STATUS) => void,
    signOut: (params?: ISignOut) => Promise<void>,
    refetchCurrentUserData: () => void,
}

const SignedInContext = React.createContext<ISignedInContextType | undefined>(undefined);


const SignedInStatusContextProvider = ({ children }: { children?: React.ReactNode }) => {
    const [signedInStatus, setSignedInStatus] = useState<SIGNED_IN_STATUS>(SIGNED_IN_STATUS.loading);
    const { data: currentUserData, refetch: refetchCurrentUserData } = trpc.user.getCurrentUserData.useQuery(undefined, {
        enabled: signedInStatus === SIGNED_IN_STATUS.signedIn,
    });
    const queryClient = useQueryClient();

    const signOutMutation = trpc.user.signOut.useMutation();

    const signOut = async (params?: ISignOut) => {
        if (signedInStatus !== SIGNED_IN_STATUS.signedOut) {
            setSignedInStatus(SIGNED_IN_STATUS.signedOut);
            await signOutHelper(params);
            getRootNavRef()?.navigate('Login');
        } else {
            getRootNavRef()?.navigate('Login');
        }
    };

    /**
     *
     * @param params object of config params
     * SkipSignOutRequest - Will skip the request to sign out - Intended to be set to true when signing out due to invalid access or refresh token
     * showExpiredError - Will show a pop-up saying that session expired - Intended to be set to true when signing out due to expired access token
     * makeRefreshTokenCall - Will make a new access token request - Intended to be set to true when signing out due to invalid access or refresh token.
     */
    const signOutHelper = async (params?: ISignOut) => {
        if (params?.makeRefreshTokenCall) {
            console.error('Signing out because of invalid token');
        }

        let tokens: Token | undefined;
        if (params?.makeRefreshTokenCall) {
            const tokensString = await MB_accessTokenUtils.getAccessToken();
            if (tokensString) {
                tokens = JSON.parse(tokensString) as Token;
            }
        }

        // NOTE: We need this on the server side for detecting a potential malicious activity.
        // NOTE: We are deleting the interceptor before making the requestNewAccessToken to prevent loops where this call
        // will throw an invalid access token error and will be caught by the interceptor again.
        // Make sure to always add it back afterwards.
        if (params?.makeRefreshTokenCall && tokens) {
            try {
                deleteResponseInterceptors();
                await userApiCalls.requestNewAccessToken(tokens.accessToken.token, tokens.refreshToken, true);
                setResponseInterceptors();
            } catch (error) {
                setResponseInterceptors();
            }
        }

        if (!params?.skipSignOutRequest) {
            try {
                signOutMutation.mutate();
            } catch (error) { }
        }

        try {
            await MB_accessTokenUtils.removeAccessToken();

            queryClient.cancelQueries();
            queryClient.removeQueries();
            queryClient.clear();
        } catch (error) {
            console.error('Error when signing out');
        }

        if (params?.showExpiredError) {
            setTimeout(() => {
                mbShowPopUp({ message: 'Login Session expired. Please try logging in' });
            }, 500);
        }
    };


    signedInContextGlobalFunction.signOut = signOut;
    signedInContextGlobalFunction.getSignedInStatus = () => signedInStatus;


    return (
        <SignedInContext.Provider value={{
            isAdmin,
            isSignedIn: signedInStatus === SIGNED_IN_STATUS.signedIn,
            signedInStatus: signedInStatus,
            currentUserData: currentUserData?.userData as UserPrivateDataForClient | undefined,
            refetchCurrentUserData,
            setSignedInStatus,
            signOut,
        }}>
            {children}
        </SignedInContext.Provider>
    );
};

function useSignedInContext() {
    const context = useContext(SignedInContext);
    if (context === undefined) {
        throw new Error('useSignedInContext must be used within a SignedInStatusContextProvider');
    }

    return context;
}

export { SignedInStatusContextProvider, useSignedInContext };
