import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

enum FONT_STYLES {
    Inter_100 = 'Inter_100',
    Inter_200 = 'Inter_200',
    Inter_300 = 'Inter_300',
    Inter_400 = 'Inter_400',
    Inter_500 = 'Inter_500',
    Inter_600 = 'Inter_600',
    Inter_700 = 'Inter_700',
    Inter_800 = 'Inter_800',
    Inter_900 = 'Inter_900',
    Tabasco_700 = 'Tabasco_700',
    Tabasco_400 = 'Tabasco_400',
    PlusJakartaSans_400 = 'PlusJakartaSans_400',
    PlusJakartaSans_500 = 'PlusJakartaSans_500',
    PlusJakartaSans_600 = 'PlusJakartaSans_600',
    Staatliches_400 = 'Staatliches_400',
}

const textStyles = StyleSheet.create({
    superLargeText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_700,
        fontSize: 44,
        fontWeight: '700',
        color: 'white',
        textAlign: 'center',
    }),
    largestText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_700,
        fontSize: 32,
        fontWeight: '700',
        color: 'white',
        textAlign: 'center',
    }),
    largerText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_600,
        fontSize: 28,
        fontWeight: '600',
        color: 'white',
        textAlign: 'center',
    }),
    largeText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_500,
        fontSize: 24,
        fontWeight: '500',
        color: 'white',
        textAlign: 'center',
    }),
    normalText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_500,
        fontSize: 16,
        fontWeight: '500',
        color: 'white',
        textAlign: 'center',
    }),
    smallText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_500,
        fontSize: 14,
        fontWeight: '500',
        color: 'white',
        textAlign: 'center',
    }),
    smallerText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_400,
        fontWeight: 'normal',
        fontSize: 12,
        color: 'white',
        textAlign: 'center',
    }),
    tinyText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_400,
        fontWeight: 'normal',
        fontSize: 10,
        color: 'white',
        textAlign: 'center',
    }),
    popUpErrorMessageText: mbTextStyles({
        fontFamily: FONT_STYLES.Inter_400,
        fontWeight: 'normal',
        fontSize: 12,
        textAlign: 'center',
        color: 'red',
    }),
});

export { textStyles, FONT_STYLES };
