import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
        backgroundColor: '#EFEFF3',
        flex: 1,
    },
    contentContainer: {
        paddingBottom: Platform.OS === 'android' ? 20 : 40,
        paddingHorizontal: 30,
        flexGrow: 1,
    },
});
