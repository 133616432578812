import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

type MinusSignIconProps = {
    size?: number
}

export default function MinusSignIcon({ size = 31 }: MinusSignIconProps) {
    return (
        <Svg
            width={size}
            height={size * (30 / 31)}
            viewBox="0 0 31 30"
            fill="none"
        >
            <G>
                <Path
                    d="M3.855 14.447h23.112"
                    stroke="#8C9FB1"
                    strokeWidth={6.35294}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </G>
        </Svg>
    );
}
