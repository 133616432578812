import React, { Fragment, useCallback } from 'react';
import { ComponentWrapper } from '../../../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from '../../Shared/styles';
import { Header } from './Header';
import { Spacer } from '../../../../helperComponents/Spacer';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { Clipboard, StyleSheet, Text, View } from 'react-native';
import { InnerShadow } from '../../../../helperComponents/InnerShadow';
import { ShadowView } from '../../../../helperComponents/ShadowView';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import { utils } from '../../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../../constants/constants';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import RefreshIcon from '../../../../../resources/svgComponents/RefreshIcon';

export default function ReferFriend() {

    const trpcUtils = trpc.useUtils();
    const { data, isError, isLoading } = trpc.user.getReferralCode.useQuery();
    const { mutate: generateCode, isLoading: isGenerating } = trpc.user.generateReferralCode.useMutation();

    const code = isError ? 'No Code available' : (data?.referralCode ?? 'Loading');

    const onCopy = useCallback(() => {
        if (isLoading) {
            utils.showToast('error', 'Please wait for the code to load');
        } else if (isError) {
            utils.showToast('error', 'Please generate code before copying');
        } else {
            // TODO: install lib for this
            Clipboard.setString(code);
            utils.showToast('success', 'Code copied successfully');
        }
    }, [code, isError, isLoading]);

    const onGenerate = useCallback(() => {
        generateCode(undefined, {
            onSuccess({ referralCode }) {
                trpcUtils.user.getReferralCode.setData(undefined, { referralCode });
            },
            onError(error) {
                utils.showToast('error', error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
            },
        });
    }, [generateCode, trpcUtils.user.getReferralCode]);

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.container}
            wrapInScrollView={false}
        >
            <Header title="REFER YOUR FRIENDS" />
            <View style={styles.content}>
                <Text style={styles.titleText}>Your Referral Code</Text>
                <Text style={styles.messageText}>Share this code with your friends to earn bonus points!</Text>
                <InnerShadow>
                    <View style={styles.code}>
                        <Text style={styles.codeText}>{code}</Text>
                        <Text style={styles.copyText} onPress={onCopy}>Copy</Text>
                    </View>
                </InnerShadow>
                <Spacer />
                <ShadowView
                    style={styles.generateCode}
                    disabled={isGenerating}
                    onPress={onGenerate}
                >
                    {isGenerating ?
                        <UIActivityIndicator size={20} color="#F35E2E" />
                        :
                        <Fragment>
                            <RefreshIcon />
                            <Text style={styles.generateCodeText}>Regenerate referral code</Text>
                        </Fragment>
                    }
                </ShadowView>
            </View>
        </ComponentWrapper>
    );
}


const styles = StyleSheet.create({
    content: {
        paddingTop: 50,
        paddingHorizontal: 15,
        paddingBottom: 40,
        flex: 1,
    },
    code: {
        height: 62,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 20,
        flexDirection: 'row',
    },
    titleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 25,
        color: '#000000',
    }]),
    messageText: mbTextStyles([textStyles.normalText, {
        fontWeight: '400',
        color: mbApplyTransparency('#6D6E71', 0.96),
        marginBottom: 40,
        marginTop: 5,
    }]),
    codeText: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        textAlign: 'left',
    }]),
    copyText: mbTextStyles([textStyles.normalText, {
        color: '#F35E2E',
        textAlign: 'left',
    }]),
    generateCode: {
        height: 60,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    generateCodeText: mbTextStyles([textStyles.normalText, {
        color: '#F35E2E',
        marginLeft: 8,
    }]),
});
