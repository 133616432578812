import React, { useMemo, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Pressable, Image} from 'react-native';
import {
    AnswerType,
    GroupBonuses,
    QuestionDataType,
    ImageFileExtension,
    UserIdentity,
    AgeGroup,
} from '@sapphicsavvy/business';
import { MB_AnimatedChevron, MB_AnimatedChevronRef } from '@mightybyte/rnw.components.animated-chevron';
import { quarterSharedStyles } from './quarterSharedStyles';
import TrashIcon from '../../../../../resources/svgComponents/TrashIcon';
import { Spacer } from '../../../../helperComponents/Spacer';
import { getColors } from '../../../../../constants/colors';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { MB_TextInput, MB_TextInputInputType } from '@mightybyte/rnw.components.text-input';
import { RadioButton } from '../../../../helperComponents/RadioButton';
import NoImageIcon from '../../../../../resources/svgComponents/NoImageIcon';
import {
    MB_RESOURCE_PICKER_TYPE,
    MB_ResourceUploader,
    MB_ResourceUploaderFunctionProps,
} from '@mightybyte/rnw.components.resource-uploader';
import {MB_utilHooks} from '@mightybyte/rnw.utils.util-hooks';
import { trpc } from '../../../../../apiCalls/trpcClient';
import {utils} from '../../../../../utils/utils';
import {resourceUploadApiCalls} from '../../../../../apiCalls/resourceUploadApiCalls';
import {BarChartData, BarChart} from '../../../../helperComponents/BarChart';
import {DonutChart} from '../../../../helperComponents/DonutChart';

const COLORS = getColors();

type ResultOptions = {
    identity: UserIdentity | undefined,
    ageGroup: AgeGroup | undefined,
}

export type Question = Omit<QuestionDataType, 'id' | 'imageUrl'> & {id?: string, imageUrl?: string | undefined}
type Item = {
    label: string;
    value: number;
};
function moveGreatestValueToTop (items: Item[]): Item[] {
    const maxValue = Math.max(...items.map(item => item.value));

    // Filter out the items that have the maximum value
    const maxItems = items.filter(item => item.value === maxValue).map(item => ({...item, isMax: true}));

    // Filter out the rest of the items
    const nonMaxItems = items.filter(item => item.value !== maxValue);

    // Insert the max value items at the top
    return [...maxItems, ...nonMaxItems];
}

const aggregateData = (resultString: string, resultOptions: ResultOptions) => {
    const parsedData = JSON.parse(resultString);
    const data: BarChartData[] = [];
    const list = [];
    const groupData:Record<string, number> = {};

    if (resultOptions.ageGroup && resultOptions.identity) {
        list.push(parsedData[resultOptions.identity][resultOptions.ageGroup]);

    } else if (resultOptions.ageGroup) {
        for (const identity in parsedData) {
            list.push(parsedData[identity][resultOptions.ageGroup]);
        }
    } else if (resultOptions.identity) {
        const identityData = parsedData[resultOptions.identity];
        for (const ageGroup in identityData) {
            list.push(identityData[ageGroup]);
        }

    } else {
        for (const identity in parsedData) {
            for (const ageGroup in parsedData[identity]) {
                list.push(parsedData[identity][ageGroup]);
            }
        }
    }

    list.forEach((item) => {
        for (const key in item) {
            if (groupData[key]) {
                groupData[key] += item[key];
            } else {
                groupData[key] = item[key];
            }
        }
    });
    Object.entries(groupData).forEach(([key, value]) => {
        data.push({
            label: key,
            value: value,
        });
    });
    return data;
};

function getNumericDataToDisplay(question: Question, resultOptions: ResultOptions) {
    const resultString = question?.result;
    if (!resultString) {
        return {
            value: [],
            text: 'No data',
        };
    }

    const data: BarChartData[] = aggregateData(resultString, resultOptions);
    return {
        value: moveGreatestValueToTop(data),
        text: `Results of sapphics with identity: ${resultOptions?.identity || 'All'} and age group: ${resultOptions?.ageGroup || 'All'}`,
    };
}

function getPercentageDataToDisplay(question: Question, resultOptions: ResultOptions) {
    const resultString = question?.result;
    if (!resultString) {
        return {
            value: 0,
            text: 'No data',
        };
    }

    const data: BarChartData[] = aggregateData(resultString, resultOptions);

    const total = data.find(item => item.label === 'total')?.value || 0;
    const yes = data.find(item => item.label === 'yes')?.value || 0;
    const percentage = total === 0 ? 0 : (yes / total) * 100;
    return {
        value: percentage.toFixed(2),
        text: `% of "YES" answer for sapphics with identity: ${resultOptions?.identity || 'All'}, and age group: ${resultOptions?.ageGroup || 'All'}`,
    };
}

type Props = {
    question: Question,
    index: number
    expanded: boolean
    toggleExpand: (index:number) => void
    onDeletePress: (index:number) => void
    modifyQuestion: (index:number, data: Partial<Question>) => void
    setQuestionToPreview: (question: string) => void
    viewResults: boolean;
    isDraft: boolean;
    canUpdateFacts: boolean;
    resultOptions: ResultOptions;
}

const QuestionUI = ({
    question,
    index,
    expanded,
    toggleExpand,
    onDeletePress,
    modifyQuestion,
    setQuestionToPreview,
    viewResults,
    isDraft,
    resultOptions,
}:Props) => {
    const ref = React.useRef<MB_AnimatedChevronRef>(null);

    React.useEffect(() => {
        if (expanded){
            ref.current?.setToFinalRotation();
        } else {
            ref.current?.setToInitialRotation();
        }
    }, [expanded]);

    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();

    const [tempUrl, setTempUrl] = useState< string | undefined>(undefined);


    const {mutate: getUploadUrl} = trpc.admin.quarters.getQuestionImageUploadUrl.useMutation();

    function imageUploader({ fileProps, uploadProgressListener }: { fileProps: MB_ResourceUploaderFunctionProps, uploadProgressListener: (percentCompleted: number) => void }): Promise<string> {

        return new Promise<string>((resolve, reject) => {
            if (!fileProps.blob) {
                reject('Something went wrong');
            }

            const extension = utils.enumFromStringValue(ImageFileExtension, fileProps.mimeType.split('/')[1]);

            if (!extension) {
                reject('Error: Invalid image format');
                return;
            }


            getUploadUrl({ fileExtension: extension }, {
                onSuccess: async (data) => {
                    resourceUploadApiCalls.uploadResource({
                        method: data.requestMethod,
                        url: data.signedUrl,
                        imageData: fileProps.blob!,
                        headers: data.headers,
                        uploadProgress: uploadProgressListener,
                    })
                                          .then(() => {
                                              modifyQuestion(index, {imageUrl:data.fileName});
                                              resolve(data.fileName);
                                          })
                                          .catch(() => reject('Something went wrong during upload'));
                },
                onError: (error) => {
                    // TODO: Yulia: Finish
                    console.log('Error data', error);
                },
            });
        });
    }
    const imageVideoUploader = (uploadProgressListener: (percentCompleted: number) => void) => async (fileProps: MB_ResourceUploaderFunctionProps): Promise<void> => {
        return new Promise((resolve, reject) => {

            imageUploader({ fileProps, uploadProgressListener })
                .then(() => {
                    setTempUrl(fileProps.uri);
                    resolve();
                })
                .catch(error => reject(error));
        });
    };

    const ImageComponent = useMemo(()=> {
        if (tempUrl) {
            return <Image style={{width: '100%', height: '100%'}} source={{uri: tempUrl}}/>;
        }
        if (!question.imageUrl) {
            return <NoImageIcon/>;
        }
        return <Image style={{width: '100%', height: '100%'}} source={{uri: question.imageUrl}}/>;
    }, [question.imageUrl, tempUrl]);

    const cardContent = useMemo(() => {
        return (
            <>
                <Text style={styles.subsectionTitleText}>Question type</Text>
                <Spacer height={5}/>
                {/*Answer type*/}
                <View style={quarterSharedStyles.row}>
                    <RadioButton value={AnswerType.percentage}
                                 radioText={'Percentage'}
                                 groupValue={question.answerType}
                                 onPressCallback={()=> {
                                     modifyQuestion(index, {answerType: AnswerType.percentage});
                                 }}
                                 disabled={!isDraft}
                    />
                    <Spacer width={20} />
                    <RadioButton value={AnswerType.numeric}
                                 radioText={'Numeric'}
                                 groupValue={question.answerType}
                                 onPressCallback={()=> {
                                     modifyQuestion(index, {answerType: AnswerType.numeric});
                                 }}
                                 disabled={!isDraft}
                    />



                </View>
                <View style={[quarterSharedStyles.row, {alignItems: 'flex-start'}]}>

                    <View style={{flex:1}}>
                        {/*Core and sapphic question*/}
                        <View style={styles.questionBlock} >
                            <View style={[quarterSharedStyles.rowSpaceBetween, {position: 'relative'}]}>
                                <MB_TextInput
                                    title={'Core question'}
                                    placeholder={'Enter the core question for admin input'}
                                    placeholderTextColor={mbApplyTransparency(COLORS.textPrimary, 0.4)}
                                    onChangeText={(text) => modifyQuestion(index, {coreQuestion: text})}
                                    value={question.coreQuestion}
                                    multiline={true}
                                    titleStyle={quarterSharedStyles.textInputTitle}
                                    textInputStyle={[quarterSharedStyles.textInputStyle, {textAlign: 'left'}]}
                                    style={[styles.inputStyle, styles.multiline]}
                                    disable={!isDraft}
                                />
                                <TouchableOpacity onPress={()=> setQuestionToPreview(question.coreQuestion)} style={styles.preview}>
                                    <Text style={{color: COLORS.accentColor}}>Preview</Text>
                                </TouchableOpacity>
                            </View>

                            <Spacer height={16} />

                            <MB_TextInput
                                title={'Sapphic question'}
                                placeholder={'Enter the sapphic question'}
                                placeholderTextColor={mbApplyTransparency(COLORS.textPrimary, 0.4)}
                                onChangeText={(text) => modifyQuestion(index, {sapphicQuestion: text})}
                                value={question.sapphicQuestion}
                                multiline={true}
                                titleStyle={quarterSharedStyles.textInputTitle}
                                textInputStyle={[quarterSharedStyles.textInputStyle, {textAlign: 'left'}]}
                                style={[styles.inputStyle, styles.multiline]}
                                disable={!isDraft}
                            />
                        </View>
                        <Spacer height={10} />
                        {/*Question settings*/}
                        <View style={styles.questionBlock} >
                            <View style={quarterSharedStyles.rowSpaceBetween}>
                                <View style={styles.questionSettingsWrapper}>
                                    <Text style={styles.subsectionTitleText}>Response options</Text>

                                    <View style={[quarterSharedStyles.row, {flexWrap: 'wrap'}]}>
                                        {question.answerType === AnswerType.numeric ? <View style={{alignItems: 'flex-start'}}>
                                            <View style={[question?.answerOptions?.strictMax ? styles.activeRadioWrapper : styles.radioWrapper, {
                                                marginBottom:8,
                                            }]}>
                                            <RadioButton value={true}
                                                         radioText={'Apply strict max'}
                                                         groupValue={question?.answerOptions?.strictMax }
                                                         onPressCallback={()=> {
                                                             modifyQuestion(index, {answerOptions: {...question.answerOptions, strictMax: !question?.answerOptions?.strictMax}});
                                                         }}
                                                         disabled={!isDraft}
                                            />
                                            </View>
                                            <View style={{flexDirection: 'row'}}>
                                                <MB_TextInput
                                                    title={'Min'}
                                                    onChangeText={(text) => modifyQuestion(index, {answerOptions:
                                                            {...question.answerOptions, min: Number(text)},
                                                    })}
                                                    value={question.answerOptions?.min?.toString() || ''}
                                                    inputType={MB_TextInputInputType.numbers}
                                                    titleStyle={quarterSharedStyles.textInputTitle}
                                                    textInputStyle={quarterSharedStyles.textInputStyle}
                                                    style={[quarterSharedStyles.smallInput, {marginBottom: 12}]}
                                                    disable={!isDraft}
                                                />
                                                <Spacer width={12}/>
                                                <MB_TextInput
                                                    title={'Max'}
                                                    onChangeText={(text) => modifyQuestion(index, {answerOptions:
                                                            {...question.answerOptions, max: Number(text)},
                                                    })}
                                                    value={question.answerOptions?.max?.toString() || ''}
                                                    inputType={MB_TextInputInputType.numbers}
                                                    titleStyle={quarterSharedStyles.textInputTitle}
                                                    textInputStyle={quarterSharedStyles.textInputStyle}
                                                    style={[quarterSharedStyles.smallInput, {marginBottom: 12}]}
                                                    disable={!isDraft}
                                                />
                                            </View>

                                        </View> : null}


                                        <View>
                                            {question.answerType === AnswerType.numeric ? null :  <Spacer height={20}/>}
                                            <MB_TextInput
                                                title={'Increment'}
                                                onChangeText={(text) => modifyQuestion(index, {answerOptions:
                                                        {...question.answerOptions, increment: Number(text)},
                                                })}
                                                value={question.answerOptions?.increment?.toString() || ''}
                                                inputType={MB_TextInputInputType.numbers}
                                                titleStyle={quarterSharedStyles.textInputTitle}
                                                textInputStyle={quarterSharedStyles.textInputStyle}
                                                style={[quarterSharedStyles.smallInput, {marginBottom: 12}]}
                                                disable={!isDraft}
                                            />
                                        </View>



                                    </View>
                                </View>
                                <View style={[styles.questionSettingsWrapper, styles.idealBonusContainer]}>
                                    <Text style={styles.subsectionTitleText}>Ideal Bonus?</Text>
                                    <Spacer height={8}/>
                                    <View style={question.hasIdealBonus ? styles.activeRadioWrapper : styles.radioWrapper}>
                                        <RadioButton value={true}
                                                     radioText={'Yes'}
                                                     groupValue={question.hasIdealBonus}
                                                     onPressCallback={()=> {
                                                         modifyQuestion(index, {hasIdealBonus: true});
                                                     }}
                                                     disabled={!isDraft}
                                        />
                                    </View>
                                    <View style={!question.hasIdealBonus ? styles.activeRadioWrapper : styles.radioWrapper}>
                                        <RadioButton value={false}
                                                     radioText={'No'}
                                                     groupValue={question.hasIdealBonus}
                                                     onPressCallback={()=> {
                                                         modifyQuestion(index, {hasIdealBonus: false});
                                                     }}
                                                     disabled={!isDraft}
                                        />
                                    </View>
                                </View>
                                <View style={styles.questionSettingsWrapper}>
                                    <Text style={styles.subsectionTitleText}>Other Group Bonus</Text>

                                    <Spacer height={8}/>
                                    <View style={question.groupBonusType !== GroupBonuses.none ? styles.activeRadioWrapper : styles.radioWrapper}>
                                        <RadioButton value={true}
                                                     radioText={'Yes'}
                                                     groupValue={question.groupBonusType  === GroupBonuses.identity || question.groupBonusType === GroupBonuses.ageGroup}
                                                     onPressCallback={()=> {
                                                         modifyQuestion(index, {groupBonusType: GroupBonuses.identity});
                                                     }}
                                                     disabled={!isDraft}
                                        />
                                        <View style={quarterSharedStyles.row}>

                                            <RadioButton value={GroupBonuses.identity}
                                                         radioText={'L/B/Q'}
                                                         groupValue={question.groupBonusType}
                                                         onPressCallback={()=> {
                                                             modifyQuestion(index, {groupBonusType: GroupBonuses.identity});
                                                         }}
                                                         disabled={question.groupBonusType === GroupBonuses.none || !isDraft}
                                            />
                                            <Spacer width={16} />

                                            <RadioButton value={GroupBonuses.ageGroup}
                                                         radioText={'Age-GR'}
                                                         groupValue={question.groupBonusType}
                                                         onPressCallback={()=> {
                                                             modifyQuestion(index, {groupBonusType: GroupBonuses.ageGroup});
                                                         }}
                                                         disabled={question.groupBonusType === GroupBonuses.none || !isDraft}
                                            />

                                        </View>
                                    </View>
                                    <Spacer height={8}/>
                                    <View style={question.groupBonusType === GroupBonuses.none ? styles.activeRadioWrapper : styles.radioWrapper}>
                                        <RadioButton value={false}
                                                     radioText={'No'}
                                                     groupValue={question.groupBonusType !== GroupBonuses.none}
                                                     onPressCallback={()=> {
                                                         modifyQuestion(index, {groupBonusType: GroupBonuses.none});
                                                     }}
                                                     disabled={!isDraft}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Spacer width={10} />
                    <View style={[styles.questionBlock, styles.imageContainer]}>
                        {isDraft ? <>
                        <Text style={quarterSharedStyles.fakeTitle}>Upload question image</Text>
                        <View style={styles.fileUploadWrapper}>
                            <View style={styles.chooseFileSubsection}>
                                <Pressable style={[styles.chooseFileLeft]} onPress={()=>{
                                    showResourcePicker();
                                }}>
                                    <Text style={{color: COLORS.white}}>Choose file</Text>
                                </Pressable>
                                <TouchableOpacity onPress={()=> {
                                    modifyQuestion(index, {imageUrl: undefined});
                                    setTempUrl(undefined);
                                }}
                                                  style={[styles.iconButton]}>
                                    <TrashIcon size={16}/>
                                </TouchableOpacity>
                            </View>
                        </View> </> : null}

                        <View style={styles.imagePreviewContainer}>
                            {ImageComponent}
                        </View>
                    </View>


                </View>

            </>
        );
    }, [ImageComponent, index, isDraft, modifyQuestion, question.answerOptions, question.answerType, question.coreQuestion, question.groupBonusType, question.hasIdealBonus, question.sapphicQuestion, setQuestionToPreview, showResourcePicker]);

    const resultsCardContent = useMemo(() => {
        const resultDataToDisplay = question?.answerType === AnswerType.numeric ? getNumericDataToDisplay(question, resultOptions) :
            getPercentageDataToDisplay(question, resultOptions);

        return (
            <>
                <View>
                    <Text style={quarterSharedStyles.fakeTitle}>Core Question</Text>
                    <Text style={[quarterSharedStyles.textInputStyle, {textAlign: 'left'}]}>{question.coreQuestion}</Text>

                    <Spacer height={20} />

                    <Text style={quarterSharedStyles.fakeTitle}>Sapphic Question</Text>
                    <Text style={[quarterSharedStyles.textInputStyle, {textAlign: 'left'}]}>{question.sapphicQuestion}</Text>
                </View>
                    <Spacer height={20} />
                    <View style={[styles.questionBlock, {flexDirection: 'row',
                        padding: 20,
                        alignItems:question?.answerType === AnswerType.numeric ? 'flex-start' : 'center',
                    }]} >
                        {question?.result ? <>
                        <View style={{marginRight: 20}}>
                                {question?.answerType === AnswerType.numeric ?
                                    <BarChart data={resultDataToDisplay.value as BarChartData[]} /> :
                                    <DonutChart percentage={resultDataToDisplay.value as number} />}
                            </View>
                            <Text style={styles.resultAgendaText}>{resultDataToDisplay.text}</Text>
                        </> :  <Text style={styles.subsectionTitleText}>No results</Text>}

                    </View>

                <Spacer height={20} />
                <View style={styles.questionBlock} >
                    <View style={{maxWidth: 620}}>
                        <MB_TextInput title={'Add an interesting fact'}
                                      placeholder={'Enter an interesting fact for the users'}
                                      placeholderTextColor={mbApplyTransparency(COLORS.textPrimary, 0.4)}
                                      onChangeText={(text) => modifyQuestion(index, {fact: text})}
                                      value={question.fact}
                                      multiline={true}
                                      titleStyle={quarterSharedStyles.textInputTitle}
                                      textInputStyle={[quarterSharedStyles.textInputStyle, {textAlign: 'left'}]}
                                      style={[styles.inputStyle, styles.multiline]}
                        />
                    </View>

                </View>
            </>
        );
    }, [question, resultOptions, modifyQuestion, index]);


    return (
        <>
            <MB_ResourceUploader
                isVisible={isResourcePickerVisible}
                onDismiss={() => {
                    hideResourcePicker();
                }}
                uploaderFunction={imageVideoUploader}
                pageTitle={'Select question image'}
                uploadButtonTitle="Upload"
                resourcePickerType={MB_RESOURCE_PICKER_TYPE.image}
                uploadButtonStyle={quarterSharedStyles.button}
                uploadButtonTextStyle={quarterSharedStyles.buttonText}
                previewContainerStyle={styles.previewContainerStyle}
                dragAndDropAndProcessingResourceButtonColor={COLORS.accentColor}
                dragAndDropAndProcessingResourceTextStyle={quarterSharedStyles.secondaryBtnText}


            />
            <View style={quarterSharedStyles.section}>
                <View style={quarterSharedStyles.rowSpaceBetween}>
                    <View>
                        <Text style={quarterSharedStyles.titleText}>{`Question ${index + 1}`}</Text>
                        {(question?.coreQuestion && question.coreQuestion.length > 0 && !expanded) ?
                            <Text style={[quarterSharedStyles.textInputTitle, {marginTop: 8}]}>{question.coreQuestion}</Text> : null}
                    </View>

                    <View style={quarterSharedStyles.row}>
                        {isDraft ? <>
                        <TouchableOpacity onPress={()=> onDeletePress(index)}
                                              style={[styles.iconButton, styles.deleteButton]}>
                                <TrashIcon size={16}/>
                            </TouchableOpacity>
                        <Spacer width={20} />
                        </> : null}
                        <TouchableOpacity onPress={()=> toggleExpand(index)} style={styles.iconButton}>
                            <MB_AnimatedChevron
                                startingRotation={0}
                                finalRotation={180}
                                chevronSize={16}
                                chevronColor={'black'}
                                containerStyle={{ backgroundColor: 'white' }}
                                duration={100}
                                ref={ref} />
                        </TouchableOpacity>
                    </View>


                </View>
                {expanded ? <View style={styles.expandedContainer}>
                    {viewResults ? resultsCardContent : cardContent}

                </View> : null}

            </View>
            </>
        );

};

export {QuestionUI};
export type {ResultOptions};

const styles = StyleSheet.create({
    iconButton: {
        padding: 10,
    },
    deleteButton: {
        borderWidth: 1,
        borderColor: COLORS.strokes,
        borderRadius: 8,
        backgroundColor: mbApplyTransparency(COLORS.red, 0.02),
    },
    expandedContainer: {
        borderTopWidth: 1,
        borderTopColor: COLORS.strokes,
        paddingTop: 16,
        marginTop: 16,
    },

    subsectionTitleText: mbTextStyles([
        textStyles.largerText,
        {
            color: mbApplyTransparency('#000000', 0.55),
            fontSize: 16,
            textAlign: 'left',
        },
    ]),
    questionBlock: {
        backgroundColor: COLORS.lighterGray,
        padding: 16,
        borderRadius: 5,
    },
    imageContainer: {
        width: 400,
    },

    inputStyle: {
        backgroundColor: COLORS.white,
        width: '100%',
    },
    multiline: {
        height: 50,
    },
    questionSettingsWrapper: {
        alignContent: 'flex-start',
        alignItems: 'stretch',
        alignSelf: 'flex-start',
        flex: 1,
        paddingHorizontal: 16,
        overflow: 'hidden',
    },

    idealBonusContainer: {
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: COLORS.strokes,
        maxWidth: 180,
    },

    radioWrapper: {
        flex: 1,
        alignItems: 'flex-start',
        padding: 8,
    },
    activeRadioWrapper: {
        flex: 1,
        alignItems: 'flex-start',
        padding: 8,
        backgroundColor: COLORS.white,
    },
    imagePreviewContainer: {
        borderRadius: 5,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: COLORS.strokes,
        width: '100%',
        height: 184,
        backgroundColor: '#F9F9F9',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 24,
    },
    fileUploadWrapper: {
        width: '100%',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems:'flex-start',
        marginBottom: 16,
    },

    chooseFileSubsection: {
        padding: 0,
        justifyContent: 'space-between',
        flexDirection: 'row',
        zIndex: 200,
        width: '100%',
    },

    chooseFileLeft: {
        borderRadius: 8,
        backgroundColor: COLORS.orange,
        width: 150,
        height: 41,
        paddingLeft: 16,
        justifyContent: 'center',
    },

    preview: {
      position: 'absolute',
        right: 0,
        top: 0,
    },
    previewContainerStyle: {
        backgroundColor: '#FCAF4140',
    },
    resultAgendaText: mbTextStyles([
        textStyles.largerText,
        {
            color: '#6A2C8C',
            fontSize: 16,
            textAlign: 'left',
        },
    ]),
});
