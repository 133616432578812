import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from '../Shared/styles';
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Header } from '../Shared/Header';
import { GradientButton, GradientContainer } from '../../../helperComponents/gradients/GradientButton';
import PeopleIcon from '../../../../resources/svgComponents/PeopleIcon';
import { MB_TabView, MB_TabViewRef } from '@mightybyte/rnw.components.tab-view';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { SapphicsView } from './components/SapphicsView';
import { ShadowTextInput, TextInputProps } from '../../../helperComponents/ShadowTextInput';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { ShadowView } from '../../../helperComponents/ShadowView';
import FilterIcon from '../../../../resources/svgComponents/FilterIcon';
import { Spacer } from '../../../helperComponents/Spacer';
import FilterModal, { Filter } from './modals/FilterModal';
import Search from './components/Search';
import XIcon from '../../../../resources/svgComponents/XIcon';
import SapphicProfileModal from './modals/SapphicProfileModal';
import { useModalState } from '../../../../utils/shared';
import { MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { useFocusEffect } from '@react-navigation/core';
import { setAdjustResize, setAdjustPan } from 'rn-android-keyboard-adjust';

// function useKeyboard() {
//     const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

//     useEffect(() => {
//         const keyboardWillHide = Keyboard.addListener(
//             Platform.OS === 'android' ? 'keyboardDidHide' : 'keyboardWillHide',
//             () => setIsKeyboardOpen(false)
//         );
//         const keyboardWillShow = Keyboard.addListener(
//             Platform.OS === 'android' ? 'keyboardDidShow' : 'keyboardWillShow',
//             () => setIsKeyboardOpen(true)
//         );
//         return () => {
//             keyboardWillHide.remove();
//             keyboardWillShow.remove();
//         };
//     }, []);

//     return isKeyboardOpen;
// }

function useFilter() {
    const [isVisible, setIsVisible] = useState(false);
    const [filter, setFilter] = useState<Filter>();
    const [query, setQuery] = useState<string>();

    return {
        query,
        setQuery,
        filter,
        isVisible,
        show: useCallback(() => setIsVisible(true), []),
        hide: useCallback((updatedFilter?: Filter) => {
            setFilter(prev => updatedFilter ?? prev);
            setIsVisible(false);
        }, []),
    };
}

function SearchInput(props: TextInputProps) {
    return (
        <View style={styles.textInputConatiner}>
            <ShadowTextInput
                {...props}
                placeholder="Search by name"
                containerStyle={styles.textInputConatiner}
                style={styles.textInput}
                textInputStyle={styles.textInputText}
                leftIcon={<Ionicons name="search" size={24} color="#F35E2E" />}
                keyboardType="default"
            />
            <TouchableOpacity
                style={[styles.clearInput, !props.value && styles.hide]}
                onPress={() => props.onChangeText?.('')}
            >
                <XIcon size={12} color="#F35E2E" />
            </TouchableOpacity>
        </View>
    );
}


export function Sapphics() {
    const titles = useRef(['Explore', 'Liked Users']).current;
    const tabViewRef = useRef<MB_TabViewRef>(null);

    const canReportFocusState = useRef(true);
    const textRef = useRef<MB_TextInputRef>(null);
    const [textFocus, setTextFocus] = useState(false);

    const [currentTab, setCurrentTab] = useState<0 | 1>(0);

    const { filter, isVisible, show: showFilter, hide: hideFilter, query, setQuery } = useFilter();
    const { state: userId, show: showUser, hide: hideUser } = useModalState<string>();

    const numOfFilter = Object.keys(filter ?? {}).length;

    useFocusEffect(useCallback(() => {
        setAdjustPan();
        return () => setAdjustResize();
    }, []));

    const onModalHide = useCallback(() => {
        if (Platform.OS === 'android' && textFocus) {
            textRef.current?.blur();
            canReportFocusState.current = false;
            setTimeout(() => {
                textRef.current?.focus();
                canReportFocusState.current = true;
            }, 200);
        }
    }, [textFocus]);

    const changeTab = useCallback(() => setCurrentTab(prevTab => {
        const newTab = prevTab === 0 ? 1 : 0;
        tabViewRef.current?.navigateToTab(newTab);
        return newTab;
    }), []);

    const screens = useMemo(() => [
        () => (<SapphicsView filter={filter} query={query} onViewUser={showUser} />),
        () => (<SapphicsView likedOnly filter={filter} query={query} onViewUser={showUser} />),
    ], [filter, query, showUser]);

    return (
        <ComponentWrapper
            containerStyle={[sharedStyles.container]}
            wrapInScrollView={false}
        >
            <Header
                title="SAPPHICS"
                rightElements={
                    <GradientButton
                        title="Socialize"
                        leftElement={<PeopleIcon />}
                        style={styles.socialize}
                        colors={['#F35E2E', '#F35E2E']}
                    />
                }
            />
            <View style={styles.filter}>
                <SearchInput
                    value={query}
                    onChangeText={setQuery}
                    MB_Ref={textRef}
                    onFocus={() => canReportFocusState.current && setTextFocus(true)}
                    onBlur={() => canReportFocusState.current && setTextFocus(false)}
                />
                <Spacer width={10} />
                <ShadowView
                    style={styles.filterBtn}
                    onPress={showFilter}
                >
                    <FilterIcon />
                    {numOfFilter > 0 &&
                        <View style={styles.filterNote}>
                            <Text style={styles.filterNoteText}>{Object.keys(filter ?? {}).length}</Text>
                        </View>
                    }
                </ShadowView>
                <Search
                    query={query}
                    style={[styles.search, !textFocus && styles.hide]}
                    onViewUser={showUser}
                    likedOnly={currentTab === 1}
                />
            </View>
            <View style={styles.sapphics}>
                <GradientContainer style={styles.tabs}>
                    <TouchableOpacity
                        style={[styles.tabsBtn, currentTab === 0 && styles.tabsBtnActive]}
                        onPress={changeTab}
                        activeOpacity={0.5}
                    >
                        <Text style={[styles.tabsBtnText, currentTab === 0 && styles.tabsBtnTextActive]}>Explore</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.tabsBtn, currentTab === 1 && styles.tabsBtnActive]}
                        onPress={changeTab}
                        activeOpacity={0.5}
                    >
                        <Text style={[styles.tabsBtnText, currentTab === 1 && styles.tabsBtnTextActive]}>Liked Users</Text>
                    </TouchableOpacity>
                </GradientContainer>
                <MB_TabView
                    MB_Ref={tabViewRef}
                    titles={titles}
                    screens={screens}
                    tabStyle={styles.tab}
                    swipeEnabled={false}
                />
                <View style={[styles.backdrop, !textFocus && styles.hide]} />
            </View>
            <SapphicProfileModal userId={userId} onDismiss={hideUser} onModalHide={onModalHide} />
            <FilterModal isVisible={isVisible} filter={filter} onDismiss={hideFilter} onModalHide={onModalHide} />
        </ComponentWrapper>
    );
}

const styles = StyleSheet.create({
    socialize: {
        height: 38,
        borderColor: mbApplyTransparency('#FFFFFF', 0.50),
        borderWidth: 1,
        borderRadius: 10,
    },
    filter: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    textInputConatiner: {
        flex: 1,
    },
    textInput: {
        paddingHorizontal: 15,
    },
    textInputText: mbTextStyles([textStyles.smallText, {
        fontWeight: '400',
        color: '#000000',
        marginLeft: 6,
        textAlign: 'left',
    }]),
    clearInput: {
        position: 'absolute',
        width: 25,
        height: 25,
        right: 15,
        top: 12,
        backgroundColor: '#FFFFFF',
        borderRadius: 7,
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterBtn: {
        height: 50,
        width: 50,
        backgroundColor: '#EFE7E8',
        borderColor: mbApplyTransparency('#FFFFFF', 0.55),
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterNote: {
        borderRadius: 360,
        backgroundColor: '#E50914',
        position: 'absolute',
        height: 15,
        width: 15,
        right: -5,
        top: -5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterNoteText: mbTextStyles(textStyles.tinyText),
    search: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 58,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: mbApplyTransparency('#D1D1D1', 0.55),
    },
    sapphics: {
        flex: 1,
        marginHorizontal: -16,
        zIndex: -1,
    },
    tab: {
        display: 'none',
    },
    absolute: {
        position: 'absolute',
        width: '100%',
        height: 47,
    },
    tabs: {
        height: 47,
        flexDirection: 'row',
        paddingHorizontal: 10,
        backgroundColor: '#f0f',
    },
    tabsBtn: {
        height: 41,
        marginTop: 6,
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabsBtnActive: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        backgroundColor: '#FFFFFF',
    },
    tabsBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
        fontWeight: '700',
    }]),
    tabsBtnTextActive: {
        color: '#F35E2E',
    },
    backdrop: {
        position: 'absolute',
        backgroundColor: mbApplyTransparency('#000000', 0.47),
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    hide: {
        display: 'none',
    },
});
