import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';
import * as FileSystem from 'expo-file-system';

const utilHooks = {
    useWidthChange: (isActive: boolean, callBack: () => void) => {
        const dims = useWindowDimensions();
        const windowWidth = useRef(dims.width);

        // We need to set the width when window gets activated, otherwise if you change the window width and
        // then mount this, callback will get called.
        useEffect(() => {
            if (isActive) {
                windowWidth.current = Dimensions.get('window').width;
            }
        }, [isActive]);

        useEffect(() => {
            if (!isActive) {
                return;
            }

            if (dims.width !== windowWidth.current) {
                callBack();
                windowWidth.current = dims.width;
            }
        }, [callBack, dims.width, isActive]);
    },
    useUploadFile: () => {
        return useMutation<void, Error, { file: string, url: string, method?: 'POST' | 'PUT', headers?: Record<string, string> }, unknown>(
            async ({ file, url, method, headers }) => {
                await FileSystem.uploadAsync(url, file,
                    {
                        httpMethod: method,
                        uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
                        headers,
                    },
                );
            }
        );
    },
    useDebounce<T extends any>(value: T, delay: number) {
        const [debouncedValue, setDebouncedValue] = useState<T>(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    },
};

export { utilHooks };
