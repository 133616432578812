import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

type StepperProps = {
    step: number,
    max: number
    style?: StyleProp<ViewStyle>
}

export default function Stepper({ step, max, style }: StepperProps) {

    return (
        <View style={[styles.container, style]}>
            <View style={[styles.step, { width: `${(100 / max) * step}%` }]} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: 5,
        backgroundColor: mbApplyTransparency('#FFFFFF', 0.25),
        borderRadius: 2,
    },
    step: {
        height: 5,
        backgroundColor: '#FFFFFF',
        borderRadius: 2,
    },
});
