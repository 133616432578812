import { z } from 'zod';
import { AgeGroup, AnswerType, UserIdentity } from '../../entities';

export const BonusTypeIdealSchema = z.object({
    guess: z.number(),
    answer: z.number(),
});

export const IdentityGroupBonusSchema = z.object({
    [UserIdentity.lesbian]: z.number()?.optional(),
    [UserIdentity.bisexual]: z.number()?.optional(),
    [UserIdentity.queer]: z.number()?.optional(),
});

export const AgeGroupBonusSchema = z.object({
    [AgeGroup.lateTeens]: z.number()?.optional(),
    [AgeGroup['20s']]: z.number()?.optional(),
    [AgeGroup['30s']]: z.number()?.optional(),
    [AgeGroup['40s']]: z.number()?.optional(),
    [AgeGroup['50s+']]: z.number()?.optional(),
});

// Define the schema for QuarterAnswerData
export const QuarterAnswerDataSchema = z.object({
    questionId: z.string(),
    answerType: z.nativeEnum(AnswerType),
    extraBet: z.number().optional(),
    guess: z.number().optional(),
    answer: z.number().optional(),
    idealBonus: BonusTypeIdealSchema.optional(),
    identityGroupBonus: IdentityGroupBonusSchema.optional(),
    ageGroupBonus: AgeGroupBonusSchema.optional(),
});

// Define the schema for AddQuarterAnswerInput
export const AddQuarterAnswerInputSchema = z.object({
    quarterId: z.string(),
    data: z.array(QuarterAnswerDataSchema),
});

export const GetQuarterDataToPlayInputSchema = z.object({
    quarterId: z.string(),
});
