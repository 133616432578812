import { trpc } from '../apiCalls/trpcClient';
import ImagePicker from '../utils/ImagePicker';
import type {
    ImageFileExtension,
    UniqueUserFields,
} from '@sapphicsavvy/business';
import { utilHooks } from '../hooks/utilHooks';
import { utils } from '../utils/utils';
import { STRING_CONSTANTS } from '../constants/constants';
import { useCallback, useEffect, useRef, useState } from 'react';

const useUploadImage = ({ initialImage }: { initialImage?: string } = {}) => {
    const {
        mutate: getProfileImageUploadUrl,
        isLoading: isGettingUploadUrl,
        data: { signedUrl } = {},
    } = trpc.user.getProfileImageUploadUrl.useMutation();
    const { mutate: uploadFile, isLoading: isUploading } =
        utilHooks.useUploadFile();
    const [image, setImage] = useState(initialImage);

    const onError = (error: { message?: string }) =>
        utils.showPopUp(
            error.message ??
                STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
        );

    const uploadImage = useCallback(
        (imagePath: string) => {
            getProfileImageUploadUrl(
                { fileExtension: 'jpg' as ImageFileExtension },
                {
                    onSuccess: (data) => {
                        uploadFile(
                            {
                                file: imagePath.includes('file://')
                                    ? imagePath
                                    : 'file://' + imagePath,
                                url: data.signedUrl,
                                method: data.requestMethod,
                                headers: data.headers,
                            },
                            { onError },
                        );
                    },
                    onError,
                },
            );
        },
        [getProfileImageUploadUrl, uploadFile],
    );

    const pickImage = useCallback(
        ({ onSelectImage }: { onSelectImage?: () => void } = {}) => {
            ImagePicker.fromGallery({ multiple: false }).then((images) => {
                if (images.length > 0) {
                    setImage(images[0].path);
                    uploadImage(images[0].path);
                    onSelectImage?.();
                }
            });
        },
        [uploadImage],
    );

    return {
        pickImage,
        isUploading: isUploading || isGettingUploadUrl,
        image,
        uploadUrl: signedUrl,
    };
};

const useIsUnique = ({
    value,
    fieldName,
    enabled,
    onFetch,
}: {
    value?: string;
    fieldName: UniqueUserFields;
    enabled?: boolean | ((value?: string) => boolean);
    onFetch?: (isUnique?: boolean) => void;
}) => {
    const fetchCallback = useRef(onFetch);
    const debouncedValue = utilHooks.useDebounce(value, 100);
    const {
        data: isUniqueField,
        isError,
        isFetching: isLoading,
    } = trpc.user.isUniqueField.useQuery(
        {
            fieldName,
            value: debouncedValue as string,
        },
        {
            enabled:
                typeof enabled !== 'boolean'
                    ? enabled?.(debouncedValue)
                    : enabled,
        },
    );

    useEffect(() => {
        if (isError) {
            fetchCallback.current?.();
        } else if (isUniqueField) {
            fetchCallback.current?.(isUniqueField.isUnique);
        }
    }, [isError, isUniqueField]);

    return { isLoading, isUnique: isUniqueField?.isUnique };
};

export { useUploadImage, useIsUnique };
