import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const BookIcon = ({ color = '#EE3823', size = 37 }: { color?: string, size?: number }) => {
    return (
        <Svg
            width={size}
            height={size}
            viewBox="0 0 37 37"
            fill="none"
        >
            <Path
                d="M33.422 26.051V7.946c0-1.8-1.47-3.135-3.255-2.985h-.09c-3.15.27-7.935 1.875-10.605 3.555l-.255.165c-.435.27-1.155.27-1.59 0l-.375-.225c-2.67-1.665-7.44-3.255-10.59-3.51-1.785-.15-3.24 1.2-3.24 2.985v18.12c0 1.44 1.17 2.79 2.61 2.97l.435.06c3.255.435 8.28 2.085 11.16 3.66l.06.03c.405.225 1.05.225 1.44 0 2.88-1.59 7.92-3.255 11.19-3.69l.495-.06c1.44-.18 2.61-1.53 2.61-2.97z"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M18.422 9.176v22.5M12.047 13.676H8.672M13.172 18.176h-4.5"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default BookIcon;
