import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function MailIcon({ size = 22 }: { size?: number }) {
    return (
        <Svg
            width={size}
            height={size}
            viewBox="0 0 22 22"
            fill="none"
        >
            <Path
                d="M14.994 18.889h-8.75c-2.625 0-4.375-1.313-4.375-4.375V8.389c0-3.063 1.75-4.375 4.375-4.375h8.75c2.625 0 4.375 1.312 4.375 4.375v6.125c0 3.062-1.75 4.375-4.375 4.375z"
                stroke="#000"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M14.994 8.826l-2.739 2.188c-.9.717-2.38.717-3.28 0l-2.73-2.188"
                stroke="#000"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
}

export default MailIcon;
