export enum FilterFieldType {
    text = 'text',
    dropdown = 'dropdown',
    datetime = 'datetime',
    date = 'date',
    number = 'number',
    phone = 'phone',
}

export enum FilterQueryType {
    equals = 'equals',
    isAnyOf = 'isAnyOf',
    after = 'after',
    before = 'before',
    between = 'between',
    startsWith = 'startsWith',
}
