import { z } from 'zod';
import { InfiniteScrollingInputSchema } from '../common';
import { UserIdentity } from '../../entities';

export const GetListOfUsersInputSchema = InfiniteScrollingInputSchema.extend({
    search: z.string().optional().nullable(),
    filter: z
        .object({
            identity: z.array(z.nativeEnum(UserIdentity)).optional(),
            age: z
                .object({
                    min: z.number(),
                    max: z.number().nullable(),
                })
                .optional(),
        })
        .optional()
        .nullable(),
    liked: z.boolean().optional().nullable(),
});

export const GetUserSearchOptionsInputSchema =
    InfiniteScrollingInputSchema.extend({
        searchString: z.string().optional().nullable(),
    });

export const GetUserByIdInputSchema = z
    .object({
        id: z.string(),
    })
    .strict();

export const LikeUserInputSchema = z.object({
    userId: z.string(),
});
