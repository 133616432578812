import React, { useCallback, useEffect, useRef } from 'react';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { StyleSheet, Text } from 'react-native';
import { FONT_STYLES } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { CodeInput, CodeInputRef } from '../../../helperComponents/CodeInput';
import sharedStyles from './Shared/styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Header } from './Shared/Header';
import { trpc } from '../../../../apiCalls/trpcClient';
import { utils } from '../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { useForm } from '../../../../hooks/useForm';
import { z } from 'zod';

type ValidForm = {
    code: string
}

const schemas = {
    code: z.string().min(1, { message: 'Code is required' }).min(6, { message: 'The code must be 6 characters long' }),
};

const VerificationStep = ({ verificationToken, phoneNumber, onVerify }: { verificationToken?: string, phoneNumber: string, onVerify?: () => void }) => {

    const { mutate: verifyPhoneNumber, isLoading: isVerifying } = trpc.user.verifyPhoneNumber.useMutation();
    const { mutate: resendPhoneNumberVerificationCode, isLoading: isResending } = trpc.user.resendPhoneNumberVerificationCode.useMutation();

    const { setCode, validateAll, errors } = useForm<Partial<ValidForm>>({ schemas }, { code: '' });
    const token = useRef<string>(verificationToken ?? '');
    const codeInputRef = useRef<CodeInputRef>(null);

    const verify = useCallback(() => {
        const result = validateAll<ValidForm>();
        if (result.isValid) {
            verifyPhoneNumber({ code: result.code, token: token.current }, {
                async onSuccess(data) {
                    await MB_accessTokenUtils.setAccessToken(data.tokenPair);
                    onVerify?.();
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    }, [onVerify, validateAll, verifyPhoneNumber]);

    const resend = useCallback(() => {
        resendPhoneNumberVerificationCode({ phoneNumber }, {
            onSuccess: (data) => {
                if (!data) {
                    utils.showPopUp('Phone number not found. Please check your number and try again');
                } else {
                    token.current = data.token;
                    codeInputRef.current?.reset();
                }
            },
            onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
        });
    }, [phoneNumber, resendPhoneNumberVerificationCode]);

    useEffect(() => {
        if (!token.current) {
            resend();
        }
    }, [resend]);

    return (
        <KeyboardAwareScrollView
            style={sharedStyles.container}
            contentContainerStyle={sharedStyles.contentContainer}
        >
            <Header title="VERIFICATION" step={1} steps={6} />
            <Spacer height={40} />
            <Text style={styles.title}>PHONE NUMBER VERIFICATION</Text>
            <Spacer height={10} />
            <Text style={styles.subTitle}>Please check your text messages for verification code.</Text>
            <Spacer height={20} />
            <CodeInput
                ref={codeInputRef}
                onCodeChange={setCode}
                isError={errors.code !== undefined}
                errorMessage={errors.code}
            />
            <Spacer height={35} />
            <GradientButton
                title="Verify"
                loading={isVerifying}
                onPress={verify}
            />
            <Spacer height={10} />
            <Text style={styles.codeSent}>{isResending ? 'Resending Code...' : 'Code successfully sent!'}</Text>
            <Spacer height={10} />
            <Text style={styles.questionText}>Haven’t received  verification code? <Text onPress={isResending ? undefined : resend} style={styles.important}>Try Again</Text></Text>
            <Spacer />
        </KeyboardAwareScrollView>
    );
};

export default VerificationStep;

const styles = StyleSheet.create({
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 28,
        color: '#242121',
    }]),
    subTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        textAlign: 'left',
        fontSize: 18,
        color: mbApplyTransparency('#000000', 0.55),
    }]),
    codeSent: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        fontSize: 14,
        fontWeight: '400',
        color: mbApplyTransparency('#000000', 0.55),
        textAlign: 'center',
    }]),
    questionText: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        fontSize: 14,
        fontWeight: '400',
        color: '#000000',
        textAlign: 'center',
    }]),
    important: {
        color: '#F14728',
    },
});
