import React, { useCallback } from 'react';
import { MB_Button, MB_ButtonProps } from '@mightybyte/rnw.components.button';
import { GestureResponderEvent, Keyboard, Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { ShadowView } from '../ShadowView';
import GradientContainer from './GradientContainer';

type GradientButtonProps = MB_ButtonProps & {
    containerStyle?: StyleProp<ViewStyle>
    colors?: [string, string]
    angle?: number
};

const GradientButton = ({ containerStyle, style, onPress, ...btnProps }: GradientButtonProps) => {

    const handleOnPress = useCallback((ev: GestureResponderEvent) => {
        Keyboard.dismiss();
        onPress?.(ev);
    }, [onPress]);

    return (
        <ShadowView
            topLeftShadowOffset={{ width: -10, height: -10 }}
            topLeftShadowRadius={10}
            bottomRightShadowOffset={{ width: 10, height: 10 }}
            bottomRightShadowRadius={Platform.OS === 'android' ? 8 : 10}
            style={[styles.container, containerStyle]}
            onPress={handleOnPress}
            disabled={btnProps.disabled}
        >
            <GradientContainer
                colors={btnProps.colors}
                angle={btnProps.angle}
                style={styles.gradient}
                pointerEvents="none"
            >
                <MB_Button
                    {...btnProps}
                    style={[styles.btn, style]}
                    textStyle={[styles.btnText, btnProps.textStyle]}
                />
            </GradientContainer>
        </ShadowView>
    );
};

export { GradientButton, GradientContainer };

const styles = StyleSheet.create({
    container: {
        borderRadius: 12,
        // overflow: 'hidden',
    },
    btn: {
        backgroundColor: 'transparent',
        height: 50,
    },
    btnText: mbTextStyles([textStyles.normalText, {
        color: '#FFFFFF',
        textAlign: 'left',
        fontWeight: '800',
    }]),
    gradient: {
        borderRadius: 12,
        overflow: 'hidden',
    },
});

