import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { trpc, reactTRPCClient } from '../apiCalls/trpcClient';

const queryClient = new QueryClient();


const QueryContextProvider = ({ children }: { children?: ReactNode }) => {
    return (
        <trpc.Provider client={reactTRPCClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </trpc.Provider>
    );
};

export { QueryContextProvider };
