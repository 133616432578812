
import React, { useEffect } from 'react';
import { BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Home } from '../components/screens/Tabs/Home';
import { Results } from '../components/screens/Tabs/Results';
import { Sapphics } from '../components/screens/Tabs/Sapphics/Sapphics';
import { Read } from '../components/screens/Tabs/Read';
import { Settings } from '../components/screens/Tabs/Settings/Settings';
import { RootStackParamList } from './RootNavigator';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RouteProp } from '@react-navigation/core';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import HomeIcon from '../resources/svgComponents/HomeIcon';
import StandingIcon from '../resources/svgComponents/StandingIcon';
import SearchIcon from '../resources/svgComponents/SearchIcon';
import BookIcon from '../resources/svgComponents/BookIcon';
import SettingsIcon from '../resources/svgComponents/SettingsIcon';
import { ShadowView } from '../components/helperComponents/ShadowView';
import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';
import { GradientContainer } from '../components/helperComponents/gradients/GradientButton';
import Svg, { G, Path } from 'react-native-svg';
import { useSignedInContext } from '../context/SignedInContext';
import { ScreenProps } from '../typesAndInterfaces/componentProps';
import { trpc } from '../apiCalls/trpcClient';
import { utils } from '../utils/utils';

const TabBarBackground = () => (
    <Svg
        width={'100%'}
        height={103}
        viewBox={`0 0 393 ${103}`}
        fill="none"
    >
        <G filter="url(#filter0_bi_1142_2541)">
            <Path
                d="M.073.796H392.25v81.846c0 12.15-9.85 22-22 22H22.073c-12.15 0-22-9.85-22-22V.796z"
                fill="#F49F8B"
                fillOpacity={0.50}
            />
            <Path
                d="M.073.796H392.25v81.846c0 12.15-9.85 22-22 22H22.073c-12.15 0-22-9.85-22-22V.796z"
                fill="#fff"
                fillOpacity={0.85}
            />
            <Path
                d="M.573 1.296H391.75v81.346c0 11.874-9.626 21.5-21.5 21.5H22.073c-11.874 0-21.5-9.626-21.5-21.5V1.296z"
                stroke="#fff"
            />
        </G>
    </Svg>
);

export type TabsNavigatorParamList = {
    Home: undefined,
    Results: undefined,
    Sapphics: undefined,
    Read: undefined,
    Settings: undefined,
}

type tabsNavigatorOptionsProps = {
    route: RouteProp<TabsNavigatorParamList, keyof TabsNavigatorParamList>
    navigation: NativeStackNavigationProp<RootStackParamList>
}

const BottomTab = createBottomTabNavigator<TabsNavigatorParamList>();

type Status = 'loading' | 'enabled' | 'disabled'

function withStatus(callback: (options: tabsNavigatorOptionsProps, status: Status) => BottomTabNavigationOptions, status: Status) {
    return (options: tabsNavigatorOptionsProps) => callback(options, status);
}

const TabsNavigator = ({ navigation }: ScreenProps<'Tabs'>) => {

    const { currentUserData } = useSignedInContext();
    const { data: haveToPlay, isLoading: isHaveToPlayLoading } = trpc.quarter.haveToPlay.useQuery();

    useEffect(() => {
        const isSetupNotComplete = currentUserData && (!currentUserData.isPhoneNumberVerified || !currentUserData?.userName);
        if (isSetupNotComplete) {
            navigation.replace('AccountSetup', {
                phoneNumber: currentUserData.phoneNumber,
                skipVerification: currentUserData.isPhoneNumberVerified,
            });
        }
    }, [currentUserData, navigation]);

    const status: Status = isHaveToPlayLoading ? 'loading' : (haveToPlay?.isPlayed ? 'enabled' : 'disabled');

    return (
        <BottomTab.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <BottomTab.Screen name="Home" component={Home} options={withStatus(tabOptions, status)} />
            <BottomTab.Screen name="Results" component={Results} options={withStatus(tabOptions, status)} />
            <BottomTab.Screen name="Sapphics" component={Sapphics} options={withStatus(tabOptions, status)} />
            <BottomTab.Screen name="Read" component={Read} options={withStatus(tabOptions, status)} />
            <BottomTab.Screen name="Settings" component={Settings} options={withStatus(tabOptions, status)} />
        </BottomTab.Navigator>
    );
};

export { TabsNavigator };

const tabOptions = (options: tabsNavigatorOptionsProps, status: Status): BottomTabNavigationOptions => {

    const navOptions: BottomTabNavigationOptions = {
        tabBarShowLabel: false,
        tabBarStyle: {
            height: Platform.OS === 'android' ? 90 : 103,
            paddingTop: Platform.OS === 'android' ? 0 : 10,
            elevation: 0,
        },
        tabBarBackground() {
            return (
                <View style={styles.tabBarBackground}>
                    <TabBarBackground />
                </View>
            );
        },
        tabBarIcon: ({ focused }) => {
            const color = focused ? '#FFFFFF' : '#EE3823';
            const icons = {
                Home: <HomeIcon color={color} />,
                Results: <StandingIcon color={color} />,
                Sapphics: <SearchIcon color={color} backgroundColor={focused ? undefined : '#EFE7E8'} />,
                Read: <BookIcon color={color} />,
                Settings: <SettingsIcon color={color} />,
            };
            if (focused) {
                return (
                    <ShadowView style={styles.tab}>
                        <GradientContainer style={styles.gradientBackground}>
                            {icons[options.route.name]}
                        </GradientContainer>
                    </ShadowView>
                );
            }
            return (
                <ShadowView style={styles.tab}>
                    {icons[options.route.name]}
                </ShadowView>
            );
        },
        tabBarButton: (props) => {
            const isHome = options.route.name === 'Home';
            const onPress = (event: any) => {
                if (status === 'loading') {
                    return utils.showPopUp('Please wait');
                } else if (status === 'disabled') {
                    return utils.showPopUp('Please complete the quarter before navigating the app');
                }
                return props.onPress?.(event);
            };
            return <Pressable {...props} onPress={isHome ? props.onPress : onPress} />;
        },
    };

    return navOptions;
};

const styles = StyleSheet.create({
    tab: {
        height: 52,
        width: 52,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EFE7E8',
        borderWidth: 1,
        borderColor: mbApplyTransparency('#FFFFFF', 0.55),
        borderRadius: 10,
    },
    tabBarBackground: {
        position: 'absolute',
        top: -1,
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: 0,
    },
    gradientBackground: {
        position: 'absolute',
        top: -1,
        left: -1,
        bottom: -1,
        right: -1,
        borderRadius: 10,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

