import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

function TrashIcon({ size = 18 }: { size?: number }) {
    return (
        <Svg
            width={size}
            height={size * (21 / 18)}
            viewBox="0 0 18 21"
            fill="none"
        >
            <G clipPath="url(#clip0_218_29264)">
                <Path
                    d="M.884 1.867H6.12V1.4c0-.016 0-.03.003-.046.011-.259.12-.494.285-.665L6.406.688a.954.954 0 01.679-.294h.003V.393h3.129l.048.003a.957.957 0 01.638.295c.173.18.28.429.283.702v.003h.002V1.867H16.357a.758.758 0 01.54.235c.134.14.218.333.22.548v.005h.002V4.423c0 .24-.187.434-.418.434H.538a.427.427 0 01-.419-.434V2.658c0-.017 0-.034.003-.051v-.004A.81.81 0 01.345 2.1a.75.75 0 01.53-.23h.004v-.002h.005zm11.447 6.517h-.934v9.13h.934v-9.13zm-3.035 0h-.934v9.13h.934v-9.13zm-3.034 0h-.934v9.13h.934v-9.13zM1.82 5.53h13.718l.044.003h.02l.02.003h.002l.026.003a.958.958 0 01.56.278.92.92 0 01.265.692v.011l-.002.025-1.16 12.913-.002.007h.001l-.002.022-.008.047-.001.003a1.143 1.143 0 01-.033.151v.004h-.001l-.002.004-.003.01v.003a1.056 1.056 0 01-.069.164c-.158.306-.452.52-.817.52H2.966l-.04-.003H2.92a.868.868 0 01-.39-.113l-.002-.002c-.28-.158-.467-.468-.498-.817L.885 6.543l-.002-.02-.001-.04v-.02H.88a.92.92 0 01.268-.645.96.96 0 01.59-.281l.02-.001v-.001l.046-.002h.016v-.002zm13.718.868H1.82v-.001h-.018a.122.122 0 00-.069.037c-.01.01-.016.021-.016.029h-.001l.001.019L2.86 19.38v.002c.005.06.032.112.07.134l.013.006.01.002H14.377c.032 0 .063-.026.081-.062l.013-.032.009-.035.002-.022 1.16-12.893v-.017c0-.008-.007-.019-.017-.028a.13.13 0 00-.07-.037h-.017zm-9-3.664H.956v1.254h15.326V2.735H10.77a.426.426 0 01-.418-.434v-.905h.001a.145.145 0 00-.04-.095.134.134 0 00-.082-.04H7.088v-.002a.134.134 0 00-.09.042h-.002a.154.154 0 00-.04.082v.918c0 .24-.187.434-.418.434z"
                    fill="#EE3823"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_218_29264">
                    <Path fill="#fff" transform="translate(.12 .393)" d="M0 0H17V20H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    );
}

export default TrashIcon;
