import React, { } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { UserDataForAdmin } from '@sapphicsavvy/business';
import { StyleSheet, Text, View } from 'react-native';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { trpc } from '../../../../apiCalls/trpcClient';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import TrashIcon from '../../../../resources/svgComponents/TrashIcon';

export default function DeleteUserModal({ isVisible, userInfo, onDismiss }: { isVisible: boolean, userInfo?: UserDataForAdmin, onDismiss?: () => void }) {

    const trpcUtils = trpc.useUtils();
    const { mutate: editUser, isLoading } = trpc.admin.users.editUser.useMutation();

    const onRemove = () => {

    };

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            hideCloseButton
            childrenWrapperStyle={styles.container}
        >
            <View style={styles.headerIcon}>
                <TrashIcon size={23} />
            </View>
            <Spacer height={10} />
            <View>
                <Text style={styles.titleText}>Delete User Account</Text>
                <Spacer height={20} />
                <View style={styles.user}>
                    <Text style={styles.userText}>{userInfo?.userName}</Text>
                </View>
                <Spacer height={20} />
                <Text style={styles.noteText}>Are you sure you want to remove this user from the system? All the data associated with the user will be deleted.</Text>
            </View>
            <Spacer height={10} />
            <View style={styles.footer}>
                <MB_Button
                    title="Remove"
                    style={styles.primaryBtn}
                    textStyle={styles.primaryBtnText}
                    onPress={onRemove}
                    disabled={isLoading}
                    loading={isLoading}
                />
                <MB_Button
                    title="Cancel"
                    style={styles.secondaryBtn}
                    textStyle={[styles.primaryBtnText, styles.secondaryBtnText]}
                    onPress={onDismiss}
                />
            </View>
        </MB_Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        width: 409,
        paddingTop: 20,
        paddingBottom: 0,
        paddingHorizontal: 20,
        alignItems: 'stretch',
    },
    headerIcon: {
        alignSelf: 'center',
        width: 61,
        height: 61,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: mbApplyTransparency('#E53232', 0.13),
        borderRadius: 360,
    },
    titleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        color: '#000000',
        fontWeight: '600',
    }]),
    user: {
        backgroundColor: '#F5F7F7',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 17,
    },
    userText: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        fontWeight: '500',
    }]),
    noteText: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        fontWeight: '400',
    }]),
    footer: {
        height: 95,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FEF2EE',
        paddingHorizontal: 30,
        flexDirection: 'row',
        marginHorizontal: -20,
    },
    primaryBtn: {
        width: 167,
        height: 49,
        backgroundColor: '#F35E2E',
        borderRadius: 8,
        borderWidth: 0,
    },
    primaryBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
    }]),
    secondaryBtn: {
        width: 167,
        height: 49,
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: '#F35E2E',
    },
    secondaryBtnText: {
        color: '#F35E2E',
    },
});
