import React, { ComponentProps, FC, ReactNode } from 'react';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { Platform, Text, View } from 'react-native';
import { mbShowToast, MB_ToastConfigParams } from '@mightybyte/rnw.components.toast';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { DateInput } from '@sapphicsavvy/business';
import { parse } from 'date-fns';

export const utils = {
    createErrorObject: (message: string, errorCode: SERVER_ERROR_CODES | string): ServerError => {
        return {
            message,
            errorCode,
            status: 'error',
        };
    },
    /**
     *
     * @param version string version 1
     * @param version2 string version 2
     * @returns -1 if version 1 < version 2, 0 if version 1 === version 2, 1 if version 1 > version 2
     */
    compareVersion: (version: string, version2: string) => {
        return version.localeCompare(version2, undefined, { numeric: true, sensitivity: 'base' });
    },

    combineComponents: (components: FC[]): FC => {
        const reducerFunction = (
            AccumulatedComponents: FC<ReactNode>,
            CurrentComponent: FC<ReactNode>,
        ): FC<ReactNode> => {
            return ({ children }: ComponentProps<FC<ReactNode>>): JSX.Element => {
                const Component = AccumulatedComponents as FC<ReactNode>;
                return (
                    <Component>
                        <CurrentComponent>{children}</CurrentComponent>
                    </Component>
                );
            };
        };

        return components.reduce(reducerFunction, ({ children }: ComponentProps<FC<ReactNode>>): JSX.Element => (
            <>{children}</>
        )) as FC;
    },
    showLogOutPopUp: (onSignOut?: () => void, onPopUpClosed?: () => void) => {
        mbShowPopUp({
            title: 'Are you sure you want to log out ?',
            buttonText: 'Yes',
            buttonAction: () => {
                // TODO: This is kind of ugly. We needed to do this because of some issues with iOS pop-ups and also on Android it was trying to perform an action on a component that was unmounted.
                mbHidePopUp();
                setTimeout(() => onPopUpClosed?.(), 0);
                setTimeout(() => onSignOut?.(), 400);
            },
            secondaryButtonText: 'No',
        });
    },
    getRelativeValue: (minValue: number, minRelativeValue: number, maxValue: number, maxRelativeValue: number, value: number) => {
        if (value <= minValue) {
            return minRelativeValue;
        } else if (value >= maxValue) {
            return maxRelativeValue;
        } else {
            if (minRelativeValue < maxRelativeValue) {
                return minRelativeValue + ((value - minValue) * ((maxRelativeValue - minRelativeValue) / (maxValue - minValue)));
            } else {
                return minRelativeValue - ((value - minValue) * ((minRelativeValue - maxRelativeValue) / (maxValue - minValue)));
            }
        }
    },
    enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
        return (Object.values(enm) as unknown as string[]).includes(value)
            ? value as unknown as T
            : undefined;
    },
    delay: (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms)),
    showBackDropPopUp: async ({ postDelay, loadingUi, message }: { postDelay?: number, loadingUi?: boolean, message?: string }) => {
        mbShowPopUp({
            animationProps: {
                animationIn: 'fadeIn',
                animationOut: 'fadeOut',
            },
            titleStyle: { display: 'none' },
            containerStyle: { paddingBottom: 0, height: 200, paddingLeft: 0, paddingRight: 0, backgroundColor: 'transparent' },
            topComponent: loadingUi ? <View>
                <BarIndicator color="#EF3A24" count={5} />
                <Text style={{ color: '#FFFFFF', fontSize: 20, textAlign: 'center', marginTop: 40 }}>{message}</Text>
            </View> : undefined,
            buttonStyle: { container: { display: 'none' } },
        });
        if (postDelay) {
            await utils.delay(postDelay);
        }
    },
    showPopUp: (message: string, options?: { type?: 'Alert' | 'Error', timing?: number, buttons?: { text: string, onPress?: () => void | Promise<void> }[] }) => {
        mbShowPopUp({
            animationProps: {
                animationIn: Platform.OS === 'web' ? undefined : 'fadeIn',
                animationOut: Platform.OS === 'web' ? undefined : 'fadeOut',
                animationInTiming: options?.timing,
                animationOutTiming: options?.timing,
            },
            title: options?.type ?? 'Alert',
            message,
            buttonText: options?.buttons?.[1]?.text || options?.buttons?.[0]?.text,
            buttonAction: (options?.buttons?.[1]?.onPress || options?.buttons?.[0]?.onPress) ? async () => {
                if (options?.buttons && options?.buttons.length === 1) {
                    await options?.buttons?.[0]?.onPress?.();
                } else {
                    await options?.buttons?.[1]?.onPress?.();
                }
                mbHidePopUp();
            } : undefined,
            secondaryButtonText: (options?.buttons && options?.buttons.length > 1) ? options?.buttons?.[0]?.text : undefined,
            secondaryButtonAction: (options?.buttons && options?.buttons.length > 1) ? async () => {
                await options?.buttons?.[0]?.onPress?.();
                mbHidePopUp();
            } : undefined,
            containerStyle: {
                maxWidth: '90%',
            },
        });
    },
    hidePopUp: () => mbHidePopUp(),
    showToast: (type: 'success' | 'error', message: string, options?: Omit<MB_ToastConfigParams<void>, 'type' | 'text1' | 'text2'>) => {
        mbShowToast({
            ...options,
            type,
            text2: message, topOffset: Platform.OS === 'ios' ? initialWindowMetrics?.insets.top : undefined,
        });
    },
    getFileNameFromUrl: (url: string) => {
        const urlObj = new URL(url);
        const fileName = urlObj.pathname.split('/').pop();
        if (!fileName) {
            throw new Error(
                'Unable to extract file name from signed URL',
            );
        }
        return fileName;
    },
    getDate: (dateInput: DateInput) => {
        const dateString = `${dateInput.day}/${dateInput.month}/${dateInput.year}`;
        return parse(dateString, 'dd/MM/yyyy', new Date());
    },

    capitalizeFirstLetter: (s: string) => s[0].toUpperCase() + s.slice(1),
};

