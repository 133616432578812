import React, { useEffect, useRef } from 'react';
import { Animated, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { Defs, RadialGradient, Rect, Stop, Svg } from 'react-native-svg';
import { createReactComponent } from '../../utils/shared';

const AnimatedRect = Animated.createAnimatedComponent(createReactComponent(Rect));

type LoadingContentProps = {
    style?: StyleProp<ViewStyle>
    height?: number | string
    width?: number | string
}

export default function LoadingContent(props: LoadingContentProps) {

    const animatedValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.loop(Animated.timing(animatedValue, {
            useNativeDriver: false,
            duration: 2000,
            toValue: 1,
        })).start();
    }, [animatedValue]);

    const flattenStyle = StyleSheet.flatten(props.style);
    const width = props.width ?? flattenStyle?.width;
    const height = props.height ?? flattenStyle?.height;

    return (
        <View style={[props.style, { width, height }]}>
            <Svg style={{ width: '100%', height: '100%' }}>
                <Defs>
                    <RadialGradient id="grad" ry={1} rx={0}>
                        <Stop offset="0" stopColor="#F35E2E" stopOpacity={0.1} />
                        <Stop offset="1" stopColor="#FFFFFF" stopOpacity={0.1} />
                    </RadialGradient>
                </Defs>
                <Rect
                    y={0}
                    x={0}
                    width="100%"
                    height="100%"
                    fill="#FFFFFF"
                    opacity={0.2}
                    rx={5}
                    ry={5}
                />
                <AnimatedRect
                    y={0}
                    x={animatedValue.interpolate({ inputRange: [0, 1], outputRange: ['-60%', '100%'] })}
                    width="40%"
                    height="100%"
                    fill="url(#grad)"
                    rx={5}
                    ry={5}
                />
            </Svg>
        </View>
    );
}
