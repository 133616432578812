import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function ArticleIcon({ color = '#8D9297' }: { color?: string }) {
    return (
        <Svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
        >
            <Path
                d="M16 2.264H8c-3.5 0-5 2-5 5v10c0 3 1.5 5 5 5h8c3.5 0 5-2 5-5v-10c0-3-1.5-5-5-5zm-8 10.25h4c.41 0 .75.34.75.75s-.34.75-.75.75H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75zm8 5.5H8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zm2.5-8.5h-2c-1.52 0-2.75-1.23-2.75-2.75v-2c0-.41.34-.75.75-.75s.75.34.75.75v2c0 .69.56 1.25 1.25 1.25h2c.41 0 .75.34.75.75s-.34.75-.75.75z"
                fill={color}
            />
        </Svg>
    );
}

export default ArticleIcon;
