import { SERVER_ERROR_CODES } from '../constants/constants';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { customAxios } from './customAxios';

const resourceUploadApiCalls = {
    uploadResource: async (props: {
        url: string;
        method: string;
        imageData: Blob;
        headers: { [x: string]: string };
        uploadProgress?: (percentCompleted: number) => void;
    }): Promise<void> => {
        const config = {
            method: props.method,
            url: props.url,
            headers: props.headers,
            data: props.imageData,
        };
        console.log('config', config);

        return customAxios
            .request({
                ...config,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = !progressEvent.total
                        ? 0
                        : Math.round(
                              (progressEvent.loaded * 100) /
                                  progressEvent.total,
                          );
                    props.uploadProgress?.(percentCompleted);
                },
            })
            .then(() => {
                console.log('Resource uploaded successfully');
                return;
            })
            .catch(function (error) {
                console.error('Error while requesting access code', error);
                const customError: ServerError = {
                    message: 'Unknown Error',
                    errorCode: SERVER_ERROR_CODES.UNKNOWN_ERROR,
                    status: 'error',
                };

                throw customError;
            });
    },
};

export { resourceUploadApiCalls };
