import React, { useCallback } from 'react';
import { FlatList, ListRenderItemInfo, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { useMBInfiniteFetch } from '@mightybyte/rnw.utils.util-hooks';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { Spacer } from '../../../../helperComponents/Spacer';
import { UserSearchOptionsForClient } from '@sapphicsavvy/business';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { ShouldSetResponderWrapper } from '../../../../../utils/shared';

type SearchProps = {
    query?: string
    likedOnly?: boolean
    style?: StyleProp<ViewStyle>
    onViewUser?: (userId: string) => void
}

export default function Search({ query, likedOnly, style, onViewUser }: SearchProps) {

    const getUserSearchOptionsHook = trpc.sapphics.getUserSearchOptions.useInfiniteQuery({ search: query, limit: 3, liked: likedOnly }, { enabled: Boolean(query), getNextPageParam: page => page.nextCursor });
    const { data, loadMore, isLoading, isFetching } = useMBInfiniteFetch(getUserSearchOptionsHook, { prefillWhileLoading: false });

    const renderItem = useCallback(({ item }: ListRenderItemInfo<UserSearchOptionsForClient>) => (
        <TouchableOpacity style={styles.item} onPress={() => onViewUser?.(item.id)}>
            <Ionicons name="search" size={24} color="#D1D1D1" />
            <Text style={styles.itemText}>{item.userName}</Text>
        </TouchableOpacity>
    ), [onViewUser]);

    const ListFooterComponent = useCallback(() => {
        return (
            <ShouldSetResponderWrapper>
                <View style={styles.footer}>
                    <UIActivityIndicator size={25} color="#F35E2E" />
                </View>
            </ShouldSetResponderWrapper>
        );
    }, []);

    const ListEmptyComponent = useCallback(() => {
        return (
            <View style={styles.empty}>
                <Text style={styles.emptyText}>{isLoading ? 'Searching...' : 'No Sapphics found!'}</Text>
            </View>
        );
    }, [isLoading]);

    const itemSeparator = useCallback(() => <Spacer height={1} color="#D1D1D1" />, []);
    const keyExtractor = useCallback((item: UserSearchOptionsForClient) => item.id, []);

    if (!query) {
        return null;
    }

    return (
        <View style={[styles.container, style]}>
            <FlatList
                data={data as UserSearchOptionsForClient[]}
                renderItem={renderItem}
                contentContainerStyle={styles.contentContainer}
                ItemSeparatorComponent={itemSeparator}
                keyboardShouldPersistTaps="handled"
                keyExtractor={keyExtractor}
                onEndReached={loadMore}
                onEndReachedThreshold={0.3}
                ListEmptyComponent={ListEmptyComponent}
                ListFooterComponent={(!isLoading && isFetching && data.length > 3) ? ListFooterComponent : undefined}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#FFFFFF',
        height: 152,
    },
    contentContainer: {
        flexGrow: 1,
    },
    item: {
        height: 50,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
    },
    itemText: mbTextStyles([textStyles.normalText, {
        color: '#2C2826',
        marginLeft: 20,
        textAlign: 'left',
    }]),
    footer: {
        height: 50,
    },
    empty: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.55),
        marginTop: 4,
    }]),
});
