import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { isEqual } from 'lodash';
import { MB_passwordUtils } from '@mightybyte/rnw.utils.password-utils';

type ErrorDescription = {
    message?: string,
    external?: boolean
}

type Errors<T, P> = Partial<Record<keyof T, P>>;

type SetterKey<T> = `set${Capitalize<Extract<T, string>>}`;

type Setters<T> = {
    [K in keyof T as SetterKey<K>]-?: (value: T[K]) => void
}

type ValidatorKey<T> = `validate${Capitalize<Extract<T, string>>}`;

type Validators<T> = {
    [K in keyof T as ValidatorKey<K>]-?: (value: T[K]) => { isValid: true, data: T[K] } | { isValid: false, error: string }
}

type useFormProps<P> = {
    schemas: P,
    autoValidate?: boolean,
    onValidateAll?: (schema: z.ZodSchema) => z.ZodSchema
}

type ValidateAllArgs = {
    onlyFirstError?: boolean
}

type UpdateErrorsOptins = {
    omitErrors?: boolean,
    message?: string,
    deleteMessage?: boolean,
    _external?: boolean,
}

const useForm = <T extends Record<string, unknown>, P extends Record<keyof T, z.ZodSchema> = Record<keyof T, z.ZodSchema>>({ schemas, autoValidate, onValidateAll = (schema) => schema }: useFormProps<P>, initialState?: T) => {
    const [form, setForm] = useState<T>((initialState ?? {}) as T);
    const [validationErrors, setValidationErrors] = useState<Errors<T, ErrorDescription>>({});

    const errors = useMemo<Errors<T, string>>(() => {
        return Object.assign({}, ...Object.entries(validationErrors).map(([key, value]) => ({ [key]: value?.message })));
    }, [validationErrors]);

    const updateErrors = useCallback((key: keyof T, options: UpdateErrorsOptins | string | undefined = {}) => {
        const message = (!options || typeof options === 'string') ? options : options.message;
        const shouldDeleteMessage = typeof options !== 'string' ? options.deleteMessage : false;
        const external = typeof options !== 'string' ? (options.message ? (options._external ?? true) : false) : true;

        setValidationErrors(prev => {
            const newState = { ...prev };
            if (shouldDeleteMessage) {
                delete newState[key];
            }
            const newErrors = Object.assign(
                (typeof options !== 'string' && options.omitErrors) ? {} : newState,
                shouldDeleteMessage ? {} : { [key]: { message, external } },
            ) as Errors<T, ErrorDescription>;
            return isEqual(prev, newErrors) ? prev : newErrors;
        });
    }, []);

    const validateAll = useCallback(<V extends T>({ onlyFirstError = true }: ValidateAllArgs = {}): ({ isValid: true } & V) | { isValid: false, errors: Errors<T, string> } => {
        const result = onValidateAll(z.object(schemas)).safeParse(form);
        if (result.success) {
            const hasExternalErrors = Object.values(validationErrors).some(desc => desc?.external);
            if (hasExternalErrors) {
                return { isValid: false, errors };
            }
            return { isValid: true, ...result.data as V };
        }
        const errorsMap = {} as Record<keyof T, string[]>;
        for (const error of result.error.errors) {
            const errorKey = error.path[0] as keyof T;
            const message = error.message;
            if (!errorsMap[errorKey]) {
                errorsMap[errorKey] = [message];
            } else {
                errorsMap[errorKey].push(message);
            }
        }
        const currentErrors = {} as Errors<T, string>;
        for (const key of Object.keys(schemas)) {
            if (validationErrors[key]?.external) {
                if (onlyFirstError) { break; }
                else { continue; }
            } else if (errorsMap[key] !== undefined) {
                const message = errorsMap[key][0];
                currentErrors[key as keyof T] = message;
                if (onlyFirstError) {
                    updateErrors(key, { message, _external: false, omitErrors: true });
                    break;
                } else {
                    updateErrors(key, { message, _external: false });
                }
            }
        }
        return { isValid: false, errors: currentErrors };
    }, [onValidateAll, schemas, form, validationErrors, errors, updateErrors]);

    const validate = useCallback(<K extends keyof T>(key: K, value: T[K]): { isValid: true, data: T[K] } | { isValid: false, error: string } => {
        const result = schemas[key].safeParse(value);
        if (result.success) {
            return { isValid: true, data: result.data };
        } else {
            const error = result.error.errors[0];
            return { isValid: false, error: error.message };
        }
    }, [schemas]);

    const validators = useMemo(() => {
        const map = {} as Validators<T>;
        for (const key of Object.keys(schemas)) {
            const setterKey = `validate${key[0].toUpperCase() + key.slice(1)}` as ValidatorKey<keyof T>;
            map[setterKey] = ((value: T[keyof T]) => validate(key, value)) as Validators<T>[ValidatorKey<keyof T>];
        }
        return map;
    }, [schemas, validate]);

    const set = useCallback(<K extends keyof T>(key: K, value: T[K]) => {
        setForm(prev => ({ ...prev, [key]: value }));
        if (Object.keys(validationErrors).includes(key as string) || autoValidate) {
            const result = validate(key, value);
            if (result.isValid) {
                updateErrors(key, { _external: false });
            } else {
                const message = result.error;
                updateErrors(key, { message, _external: false });
            }
        }
    }, [autoValidate, validationErrors, updateErrors, validate]);

    const setters = useMemo(() => {
        const map = {} as Setters<T>;
        for (const key of Object.keys(schemas)) {
            const setterKey = `set${key[0].toUpperCase() + key.slice(1)}` as SetterKey<keyof T>;
            map[setterKey] = ((value: T[keyof T]) => set(key, value)) as Setters<T>[SetterKey<keyof T>];
        }
        return map;
    }, [schemas, set]);

    return { validateAll, errors, updateErrors, ...form, ...setters, ...validators };
};

const formUtils = {
    phoneRegex: new RegExp(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
    ),
    validatePassword: z.string().refine(
        (val) => MB_passwordUtils.validatePassword(val).errorMessage === undefined,
        (val) => ({ message: MB_passwordUtils.validatePassword(val).errorMessage })
    ),
    username: new RegExp(/^[a-zA-Z0-9_.]+$/),
};

export { useForm, formUtils };
