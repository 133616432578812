import React from 'react';
import { ComponentWrapper } from '../../../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from '../../Shared/styles';
import { Header } from './Header';
import { Spacer } from '../../../../helperComponents/Spacer';

export default function AboutUs() {

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.container}
            wrapInScrollView={false}
        >
            <Header title="ABOUT" />
            <Spacer height={30} />
        </ComponentWrapper>
    );
}


