import React, { createRef, useEffect, useImperativeHandle } from 'react';
import { MB_TextInput, MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { ShadowTextInput, TextInputProps } from './ShadowTextInput';
import { NativeSyntheticEvent, StyleSheet, Text, TextInputKeyPressEventData, View } from 'react-native';
import { useCallback, useRef, useState } from 'react';
import { textStyles } from '../../constants/textStyles';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { envs } from '../../../env';

const SegmentInput = (props: TextInputProps) => {
    return (
        <ShadowTextInput
            placeholder=""
            maxLength={6}
            style={styles.code}
            textInputStyle={styles.codeText}
            {...props}
        />
    );
};

type CodeInputProps = {
    onCodeChange?: (code: string) => void,
    isError?: boolean,
    errorMessage?: string,
};

export type CodeInputRef = {
    reset: () => void,
}

const CodeInputBase = ({ isError, errorMessage, onCodeChange }: CodeInputProps, ref: React.Ref<CodeInputRef>) => {

    const [codes, setCodes] = useState(['', '', '', '', '', '']);
    const codeRef = useRef(Array(6).fill(null).map(() => createRef<MB_TextInputRef>()));
    const hits = useRef(0);

    useImperativeHandle(ref, () => ({
        reset: () => {
            setCodes(['', '', '', '', '', '']);
            codeRef.current[0].current?.focus();
        },
    }), []);

    useEffect(() => {
        const code = codes.join('');
        onCodeChange?.(code);
    }, [codes, onCodeChange]);

    const onChangeText = useCallback((text: string, index: number) => {
        const getNextInputIndex = (input: string[]) => {
            let nextIndex = index;
            for (; nextIndex < input.length - 1 && input[nextIndex + 1] !== '';) {
                nextIndex++;
            }
            return nextIndex;
        };
        const character = text[0] ?? '';
        setCodes(prev => {
            prev[index] = character;
            if (character !== '') {
                const nextInputIndex = getNextInputIndex(prev);
                if (nextInputIndex < prev.length - 1) {
                    codeRef.current[nextInputIndex + 1].current?.focus();
                } else {
                    codeRef.current[index].current?.blur();
                }
            }
            return [...prev];
        });
        const rest = text.slice(1);
        if (rest.length > 0 && index + 1 < 6) {
            onChangeText(rest, index + 1);
        }
    }, []);

    const onKeyPress = useCallback((e: NativeSyntheticEvent<TextInputKeyPressEventData>, index: number) => {
        if (e.nativeEvent.key === 'Backspace') {
            setCodes(prev => {
                if (index > 0) {
                    if (prev[index] !== '') {
                        return prev;
                    }
                    prev[index - 1] = '';
                    codeRef.current[index - 1].current?.focus();
                    return [...prev];
                }
                return prev;
            });
        }
    }, []);

    const handleFocus = useCallback(() => (hits.current = 0), []);

    return (
        <View>
            <View style={styles.codes}>
                {codes.map((code, index) => (
                    <View key={`code-input-${index}`} style={styles.code}>
                        <SegmentInput value={code} isError={isError} />
                        <MB_TextInput
                            MB_Ref={codeRef.current[index]}
                            autoFocus={envs.FLAVOR !== 'dev' && index === 0}
                            value={code}
                            onChangeText={(text) => onChangeText(text, index)}
                            onKeyPressed={(event) => onKeyPress(event, index)}
                            onFocus={handleFocus}
                            style={[styles.code, styles.inputController]}
                            textInputStyle={[styles.codeText, styles.inputControllerText]}
                        />
                    </View>
                ))}
            </View>
            {isError && <Text style={styles.errorMessageText}>{errorMessage}</Text>}
        </View>
    );
};

const CodeInput = React.forwardRef(CodeInputBase);

export { CodeInput };

const styles = StyleSheet.create({
    codes: {
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    code: {
        width: 45,
        height: 60,
        paddingStart: 0,
        paddingEnd: 0,
    },
    codeText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '400',
        color: '#000000',
    }]),
    errorMessageText: mbTextStyles([textStyles.smallText, {
        position: 'absolute',
        zIndex: 1,
        color: mbApplyTransparency('#FF0000', 0.80),
        textAlign: 'center',
        fontWeight: '400',
        fontSize: 11,
        left: 6,
        bottom: -19,
    }]),
    inputController: {
        position: 'absolute',
        top: -60,
        left: 0,
        backgroundColor: 'transparent',
        borderWidth: 0,
    },
    inputControllerText: {
        color: 'transparent',
    },
});
