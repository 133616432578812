import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { getColors } from '../../../../../constants/colors';

const COLORS = getColors();

const quarterSharedStyles = StyleSheet.create({
    rowSpaceBetween: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleText: mbTextStyles([
        textStyles.largerText,
        {
            fontSize: 22,
            textAlign: 'left',
            color: COLORS.headerText,
        },
    ]),
    button: {
        backgroundColor: COLORS.orange,
        borderRadius: 8,
        padding: 10,
        paddingHorizontal: 20,
    },
    buttonText: mbTextStyles([
        textStyles.normalText,
        {
            color: '#FFFFFF',
            fontSize: 16,
        },
    ]),
    section: {
        borderRadius: 12,
        padding: 16,
        backgroundColor: '#FFFFFF',
        justifyContent: 'flex-start',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    smallInput: {
        maxWidth: 80,
        minHeight: 51,
        justifyContent: 'center',
        padding: 10,
        backgroundColor: COLORS.white,
    },

    textInputStyle: mbTextStyles([
        textStyles.normalText,
        {
            color: COLORS.textInputText,
        },
    ]),

    textInputTitle: mbTextStyles([
        textStyles.smallerText,
        {
            color: COLORS.bodyText,
            fontSize: 14,
            textAlign: 'left',
        },
    ]),
    fakeTitle: {
        marginBottom: 8,
        textAlign: 'left',
    },
    secondaryBtn: {
        height: 45,
        borderRadius: 8,
        backgroundColor: COLORS.white,
        borderWidth: 2,
        borderColor: COLORS.orange,
    },
    secondaryBtnText: mbTextStyles([
        textStyles.normalText,
        {
            color: COLORS.orange,
            fontSize: 16,
        },
    ]),
});
export { quarterSharedStyles };
