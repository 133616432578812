import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { NativeModules } from 'react-native';

const appFlavor = (isMobileApp ? NativeModules.RNConfig.flavor : process.env.flavor) as 'dev' | 'staging' | 'prod' | undefined;

if (!appFlavor || (appFlavor !== 'prod' && appFlavor !== 'staging' && appFlavor !== 'dev')) {
    throw 'Error: Invalid flavor specified. Must be "dev", "staging", or "prod"';
}

const serverURLs: { dev: string, staging: string, prod: string } = {
    dev: 'http://localhost:3000',
    staging: 'https://api.staging.sapphicsavvy.mightybyte.us',
    prod: 'TODO',
};

const websiteBaseUrls: { dev: string, staging: string, prod: string } = {
    dev: 'http://localhost:8044',
    staging: 'https://sapphicsavvy.mightybyte.us/',
    prod: 'TODO',
};

const quarterDurationHours: { dev: number, staging: number, prod: number } = {
    dev:24,
    staging: 24,
    prod: 168,
};


const envs = {
    FLAVOR: appFlavor,
    SERVER_URL: serverURLs[appFlavor],
    WEBSITE_BASE_URL: websiteBaseUrls[appFlavor],
    LOCATION_PICKER_API: 'AIzaSyBWy9oC9WflyMT9Gf2lHVkJ5gQkvFO2Ifs',// TODO: Yulia: Fix this
    QUARTER_DURATION_HOURS: quarterDurationHours[appFlavor],
};

export { envs };
