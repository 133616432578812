import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

type RefreshIconProps = {

}

export default function RefreshIcon({}: RefreshIconProps) {
    return (
        <Svg
            width={17}
            height={16}
            viewBox="0 0 17 16"
            fill="none"
        >
            <G
                clipPath="url(#clip0_1375_12321)"
                stroke="#F35E2E"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <Path d="M15.833 13.333v-4h-4M1.167 2.667v4h4" strokeWidth={2} />
                <Path
                    d="M14.16 6a6 6 0 00-9.9-2.24L1.167 6.667m14.666 2.667L12.74 12.24A6 6 0 012.84 10"
                    strokeWidth={1.5}
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1375_12321">
                    <Path fill="#fff" transform="translate(.5)" d="M0 0H16V16H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    );
}
