import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import isEqual from 'lodash/isEqual';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useMemo } from 'react';
import { getColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';

const COLORS = getColors();

export interface RadioButtonProps {
    value: any,
    groupValue?: any,
    setGroupValue?: React.Dispatch<React.SetStateAction<any>>,
    containerStyle?: StyleProp<ViewStyle>,
    onPressCallback?: () => void,
    innerCircleStyle?: StyleProp<ViewStyle>,
    radioText?: string,
    radioTextStyle?: StyleProp<TextStyle>,
    disabled?: boolean,
    preventDisableStyleChange?: boolean,
    viewOnly?: boolean,
}

const RadioButton = (
    {
        value,
        groupValue,
        setGroupValue,
        containerStyle,
        onPressCallback,
        innerCircleStyle,
        radioText,
        disabled,
        preventDisableStyleChange,
        viewOnly,
    }: RadioButtonProps) => {

    function onPress() {
        setGroupValue?.(value);
        onPressCallback?.();
    }

    const isSelected = React.useMemo(() => {
        return isEqual(value, groupValue);
    },[value, groupValue]);

    const colors = useMemo(() => {
       return {
            elementColor: isSelected ? COLORS.orange : 'transparent',
            backgroundFill: 'transparent',
            text: COLORS.textPrimary,
        };
    }, [isSelected]);


    return (
        <View style={[styles.container, containerStyle]} >
            <TouchableOpacity
                style={styles.touchableContainer}
                disabled={disabled || viewOnly}
                onPress={onPress}
            >
                <View style={[styles.outerCircle]}>
                    {isSelected ? <View style={[styles.innerCircle,
                        {backgroundColor: disabled && !preventDisableStyleChange ? mbApplyTransparency(colors.elementColor, 0.4) : colors.elementColor},
                        innerCircleStyle]} /> : null}

                </View>
            </TouchableOpacity>
            <Text style={[ styles.label,
                { color: disabled && !preventDisableStyleChange ? mbApplyTransparency(colors.text, 0.4) : colors.text }]
            }>{radioText}</Text>
        </View>
    );
};

export { RadioButton };

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginLeft: -10,

    },
    touchableContainer: {
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
    },
    outerCircle: {
        width: 18,
        height: 18,
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 300,
        alignSelf: 'center',
        borderColor: COLORS.orange,
        borderWidth: 1,
    },

    innerCircle: {
        borderRadius: 300,
        width: 11,
        height: 11,
    },
    disabledStyle: {
        backgroundColor: COLORS.backgroundPrimary,
    },
    label: mbTextStyles([
        textStyles.largerText,
        {
            fontSize:14,
            marginLeft: -4,
        },
    ]),
});
