import React from 'react';
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import { Defs, G, LinearGradient, Rect, Stop, Svg } from 'react-native-svg';

function rotatePoint(x: number, y: number, cx: number, cy: number, degrees: number) {
    const theta = degrees * (Math.PI / 180);
    const cosTheta = Math.cos(theta);
    const sinTheta = Math.sin(theta);
    const xPrime = cx + (x - cx) * cosTheta - (y - cy) * sinTheta;
    const yPrime = cy + (x - cx) * sinTheta + (y - cy) * cosTheta;
    return { x: xPrime, y: yPrime };
}

function toPercent(value: number) {
    return `${value}%`;
}

type GradientContainerProps = ViewProps & {
    style?: StyleProp<ViewStyle>
    children?: React.ReactNode,
    colors?: [string, string],
    angle?: number
}

export default function GradientContainer({ style, children, colors = ['#EF3A24', '#F9943B'], angle = -45, ...props }: GradientContainerProps) {
    const id = `${Date.now().toString(10)}`;
    const filterId = `filter_${id}`;
    const filter = `url(#${filterId})`;
    const start = rotatePoint(50, 0, 50, 50, angle);
    const end = rotatePoint(50, 100, 50, 50, angle);
    return (
        <View style={[styles.container, style]} {...props}>
            <View style={styles.gradient}>
                <Svg style={styles.svg}>
                    <G filter={filter}>
                        <Rect x={0} y={0} width="100%" height="100%" fill={filter} />
                    </G>
                    <Defs>
                        <LinearGradient
                            id={filterId}
                            x1={toPercent(start.x)}
                            y1={toPercent(start.y)}
                            x2={toPercent(end.x)}
                            y2={toPercent(end.y)}
                            gradientUnits="userSpaceOnUse"
                        >
                            <Stop stopColor={colors[0]} />
                            <Stop offset={1} stopColor={colors[1]} />
                        </LinearGradient>
                    </Defs>
                </Svg>
            </View>
            {children}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {

    },
    svg: {
        width: '100%',
        height: '100%',
    },
    gradient: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -100,
    },
});
