import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { z } from 'zod';
import { useForm } from '../../../../../hooks/useForm';
import {
    AgeGroup,
    AnswerType,
    GroupBonuses,
    QuarterStatus,
    QuestionSchema,
    UserIdentity,
} from '@sapphicsavvy/business';
import {utils} from '../../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../../constants/constants';
import { LoadingContent } from './LoadingContent';
import {QuarterQuestionsViewUI} from './QuarterQuestionsViewUI';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { AdminConfirmPopup } from './ConfirmActionUI';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { quarterSharedStyles } from './quarterSharedStyles';
import { StyleSheet, Text, View, SafeAreaView } from 'react-native';
import { getColors } from '../../../../../constants/colors';
import { Spacer } from '../../../../helperComponents/Spacer';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import MessageIcon from '../../../../../resources/svgComponents/MessageIcon';
import CheckIcon from '../../../../../resources/svgComponents/CheckIcon';
import { AdminScreenProps } from '../../../../../typesAndInterfaces/componentProps';
import {Question} from './QuestionUI';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';


type Props = {
    id: string;
    initialSet?: string;
}

type ValidForm = {
    setNumber: number
    quarterNumber: number;
    startDate:Date;
    endDate: Date;
    questions: Question[]
}

const schemas = {
    id: z.string()?.optional(),
    setNumber: z.number({message: 'Set number is required'}).min(1, {message: 'Set number must be greater than 0'}),
    quarterNumber: z.number({message: 'Quarter number is required'}).min(1, {message: 'Quarter number should be greater than 0'}).max(4, {
        message: 'Quarter number should be not be greater than 4',
    }),
    startDate: z.date({ message: 'Start date is required' }),
    endDate: z.date({ message: 'End date is required' }),
    questions: z.array(QuestionSchema).max(4, {message: 'Maximum 4 questions is allowed.'}),
};

const COLORS = getColors();

enum PublishPopUpType {
    confirm = 'confirm',
    success = 'success',
}

const QuarterQuestionsView = ({
                                  id,
    initialSet,
                              }:Props) => {
    const trpcUtils = trpc.useUtils();

    const navigation = useNavigation<NativeStackNavigationProp<AdminScreenProps<'QuarterQuestions'>>>();
    const [questionToDelete, setQuestionToDelete] = React.useState<string | undefined>(undefined);
    const [expandedTabs, setExpandedTabs] = React.useState<number[]>([]);
    const [showPublishPopup, setShowPublishPopup] = React.useState<PublishPopUpType | false>(false);

    const toggleExpand = useCallback((index: number) => {
        setExpandedTabs((prev) => {
            if (prev.includes(index)) {
                return prev.filter((i) => i !== index);
            }
            return [...prev, index];
        });
    }, [setExpandedTabs]);

    const [viewResultsIdentity, setViewResultsIdentity] = React.useState<UserIdentity | undefined>(undefined);
    const [viewResultsAge, setViewResultsAge] = React.useState<AgeGroup | undefined>(undefined);

    const onBackButtonPress = () => {
        navigation.setParams({id: undefined, initialSet: undefined});
    };

    const [initialIsDone, setInitialIsDone] = React.useState(id === 'new');

    const { setNumber,
              setSetNumber,
              quarterNumber,
              setQuarterNumber,
              startDate,
              setStartDate,
              endDate,
              setEndDate,
              questions,
              setQuestions,
              validateAll,
              errors,
    } = useForm<Partial<ValidForm>>({ schemas }, {
        setNumber: initialSet ? Number(initialSet) : undefined,
        questions: [],
    });


    const { data } = trpc.admin.quarters.get.useQuery({
        id: id,
    }, {
        enabled: !!id && id !== 'new',
    });

    const {mutate: createQuarter} = trpc.admin.quarters.create.useMutation();

    const {mutate: updateQuarter} = trpc.admin.quarters.update.useMutation();

    const {mutate: updateFacts} = trpc.admin.quarters.updateFacts.useMutation();

    const {mutate: publishQuarter} = trpc.admin.quarters.publish.useMutation();

    const onFactsSave = useCallback(() => {
        if (!questions) {
            return;
        }
        const payload = questions.map((q) => {
            if (q.id) {
                return {
                    questionId: q.id ?? '',
                    fact: q.fact ?? '',
                };
            }
            return undefined;
        }).filter((q) => q !== undefined) as Array<{questionId: string, fact: string}>;
        updateFacts({
            quarterId: id,
            data: payload,
        }, {
            onSuccess: () => {
                trpcUtils.admin.quarters.get.invalidate({id: id});
                utils.showToast('success', 'Facts updated successfully');
            },
            onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
        });
    }, [id, questions, updateFacts, trpcUtils.admin.quarters.get]);

    const createPayload = (formData: ValidForm) => {
        return {
            isDraft: true,
            setNumber: formData.setNumber,
            quarterNumber: formData.quarterNumber,
            startDate: {
                year: formData.startDate.getFullYear(),
                month: formData.startDate.getMonth() + 1,
                day: formData.startDate.getDate(),
            },
            questions: formData.questions.map((q) => {
                return {
                    id: q.id,
                    coreQuestion: q.coreQuestion,
                    sapphicQuestion: q.sapphicQuestion,
                    answerType: q.answerType,
                    answerOptions: q.answerOptions,
                    groupBonusType: q.groupBonusType,
                    hasIdealBonus: q.hasIdealBonus,
                    imageUrl: q?.imageUrl ?? undefined,
                };
            }),
        };
    };

    const onSave = () => {
        const result = validateAll<ValidForm>();

        if (result.isValid) {
            const payload = createPayload(result);
            if (id === 'new') {
                createQuarter(payload, {
                    onSuccess: (createdQuarter) => {
                        navigation.setParams({id: createdQuarter.id, initialSet: undefined});
                        utils.showToast('success', 'Quarter created successfully');
                    },
                    onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),

                });
            } else {
                updateQuarter({ ...payload, id: id }, {
                    onSuccess: () => {
                        trpcUtils.admin.quarters.get.invalidate({id: id});
                        utils.showToast('success', 'Quarter updated successfully');
                    },
                    onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
                });
            }

        }
    };

    const onPublish = useCallback(() => {
        if (!showPublishPopup) {}
        const result = validateAll<ValidForm>();
        if (!result.isValid) {
            return;
        }
        if (!showPublishPopup) {
            setShowPublishPopup(PublishPopUpType.confirm);
            return;
        }
        if (showPublishPopup === PublishPopUpType.confirm) {
            const payload = createPayload(result);
            updateQuarter({ ...payload, id: id }, {
                onSuccess: ()=> {
                    publishQuarter({quarterId: id}, {
                        onSuccess: () => {
                            trpcUtils.admin.quarters.get.invalidate({id: id});
                            setShowPublishPopup(PublishPopUpType.success);
                        },
                        onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
                    });
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    }, [showPublishPopup, validateAll, updateQuarter, id, publishQuarter, trpcUtils.admin.quarters.get]);


    useEffect(() => {
        if (data?.quarterData && !initialIsDone) {
            setInitialIsDone(true);
            setSetNumber(data.quarterData.setNumber);
            setQuarterNumber(data.quarterData.quarterNumber);
            setStartDate(utils.getDate(data.quarterData.startDate));
            setEndDate(utils.getDate(data.quarterData.endDate));
            setQuestions(data.quarterData.questions.map((q) => {
                return {
                    id: q.id,
                    coreQuestion: q.coreQuestion,
                    sapphicQuestion: q.sapphicQuestion,
                    answerType: q.answerType,
                    answerOptions: q.answerOptions,
                    groupBonusType: q.groupBonusType,
                    hasIdealBonus: q.hasIdealBonus,
                    imageUrl: q?.imageUrl ? mbGetMediumImage(q.imageUrl) : undefined,
                    fact: q.fact,
                    result: q?.result,
                };

            }));

        }
    }, [initialIsDone,data, setEndDate, setQuarterNumber, setQuestions, setSetNumber, setStartDate]);
    const canPublishQuarter = useCallback(() => {
        const result = validateAll<ValidForm>();
        return result.isValid && result?.questions.length === 4;
    }, [validateAll]);

    const onDeletePress = useCallback((index) => {
        setQuestionToDelete(index.toString());
    }, []);

    const onDeleteConfirm = useCallback(() => {
        if (questionToDelete) {
            setQuestions(questions?.filter((_, i) => i !== parseInt(questionToDelete, 10)));
        }
        setQuestionToDelete(undefined);
    }, [questionToDelete, questions, setQuestions]);


    const addQuestion = useCallback(()=> {
        const newQuestion:Question = {
            coreQuestion:'',
            sapphicQuestion:'',
            answerType: AnswerType.percentage,
            answerOptions: {
                min: 0,
                max: 100,
                increment: 1,
            },
            hasIdealBonus: false,
            groupBonusType: GroupBonuses.none,
            imageUrl: undefined,
        };
        const newIndex = questions?.length ?? 0;
       setQuestions([...(questions ?? []), newQuestion]);
       setExpandedTabs((prev) => [...prev, newIndex]);
    },[setQuestions, questions]);

    const modifyQuestion = useCallback((index: number, upd: Partial<Question>) => {
      const updatedQuestions = questions?.map((q, i) => {
            if (i === index) {
                return { ...q, ...upd };
            }
            return q;
      });
        setQuestions(updatedQuestions);
    }, [questions, setQuestions]);


    const canEditQuarter = useMemo(() => {
        const status = data?.quarterData?.status;
        return status === QuarterStatus.draft || id === 'new';
    }, [data?.quarterData?.status, id]);

    const viewResults = useMemo(() => {
        return data?.quarterData?.status === QuarterStatus.closed || data?.quarterData?.status === QuarterStatus.active;
    }, [data?.quarterData]);

    const [questionToPreview, setQuestionToPreview] = React.useState<string| undefined>(undefined);
    const {data: previewQuestions} = trpc.admin.quarters.previewQuestions.useQuery({
        question: questionToPreview ?? '',
    }, {
        enabled: !!questionToPreview,
    });

   const previewContent = useMemo(() => {
       if (!previewQuestions) {
           return undefined;
       }

       return (
           <View>
               <Text style={styles.title}>{previewQuestions.coreQuestion}</Text>
               <Text style={styles.subtitle}>Other group bonus: </Text>
               <Text style={styles.text}>{previewQuestions.otherGroupBonus}</Text>
               {previewQuestions.guessQuestion && Object.entries(previewQuestions.guessQuestion).map(([key, value]) => {
                     return (
                          <View style={{marginTop: 15}} key={key}>
                              <Text style={styles.subtitle}>{`${key} guess question:`}</Text>
                              {value instanceof Array ? value.map((v, i) => {
                                    return (
                                        <View style={{flexDirection: 'row'}} key={i}>
                                            <Text style={styles.text}>{`${v[0]}:`}</Text>
                                            <Spacer width={10}/>
                                            <Text style={styles.text}>{v[1]}</Text>
                                        </View>
                                    );
                              }) : null}
                          </View>
                     );
               })}


           </View>
       );

   }, [previewQuestions]);
    const canUpdateFacts = data?.quarterData?.status === QuarterStatus.active || viewResults;

    if (!initialIsDone) {
        return <LoadingContent/>;
    }

    return (
        <SafeAreaView>
            <MB_Modal isVisible={!!questionToPreview}
                      onDismiss={() => setQuestionToPreview(undefined)}
            >
                {previewContent}
            </MB_Modal>
            <MB_Modal isVisible={!!showPublishPopup}
                      hideCloseButton={true}
                      childrenWrapperStyle={{padding: 0}}
            >
                <AdminConfirmPopup
                                   icon={showPublishPopup === PublishPopUpType.confirm ? <MessageIcon/> :
                                       <CheckIcon/>}
                                   iconBackground={showPublishPopup === PublishPopUpType.confirm ? undefined : COLORS.white}
                                    title={showPublishPopup === PublishPopUpType.confirm ?
                                        `Are you sure you want to publish Quarter ${quarterNumber} SET ${setNumber} Questions?` :
                                        `Quarter ${quarterNumber} SET ${setNumber} Questions are successfully published.`}
                                    buttons={showPublishPopup === PublishPopUpType.confirm ? [
                                        <MB_Button title={'Publish'}
                                                   onPress={onPublish}
                                                   style={[quarterSharedStyles.button, {width: 150, marginRight: 20}]}
                                                   textStyle={quarterSharedStyles.buttonText}
                                        />,
                                        <MB_Button title={'Cancel'}
                                                   onPress={() => setShowPublishPopup(false)}
                                                   style={[quarterSharedStyles.secondaryBtn, {width: 150}]}
                                                   textStyle={quarterSharedStyles.secondaryBtnText}
                                        />,
                                    ] : [
                                        <MB_Button title={'Done'}
                                                   onPress={() => setShowPublishPopup(false)}
                                                   style={[quarterSharedStyles.secondaryBtn]}
                                                   textStyle={quarterSharedStyles.secondaryBtnText}
                                        />,
                                    ]} />
            </MB_Modal>
            <MB_Modal isVisible={!!questionToDelete}
                      hideCloseButton={true}
                      childrenWrapperStyle={{padding: 0}}
            >
                {<AdminConfirmPopup title={'Delete Question'}
                                    note={'Are you sure you want to delete the question?'}
                                    buttons={[
                                        <MB_Button title={'Delete'}
                                                   onPress={onDeleteConfirm}
                                                   style={[quarterSharedStyles.secondaryBtn, {width: 150, marginRight: 20}]}
                                                   textStyle={quarterSharedStyles.secondaryBtnText}
                                        />,
                                        <MB_Button title={'Cancel'}
                                                   onPress={() => setQuestionToDelete(undefined)}
                                                    style={[quarterSharedStyles.button, {width: 150}]}
                                                    textStyle={quarterSharedStyles.buttonText}
                                        />,
                                        ]}
                                    />}
            </MB_Modal>
            <QuarterQuestionsViewUI onBackButtonPress={onBackButtonPress}
                                    onSave={canUpdateFacts ? onFactsSave  : onSave}
                                    onPublish={onPublish}
                                    id={id}
                                    errors={errors}
                                    canEditQuarter={canEditQuarter}
                                    viewResults={viewResults}
                                    viewResultsAge={viewResultsAge}
                                    viewResultsIdentity={viewResultsIdentity}
                                    setViewResultsAge={setViewResultsAge}
                                    setViewResultsIdentity={setViewResultsIdentity}
                                    modifyQuestion={modifyQuestion}
                                    addQuestion={addQuestion}
                                    onDeletePress={onDeletePress}
                                    quarterData={data?.quarterData}
                                    canPublishQuarter={canPublishQuarter}
                                    quarterNumber={quarterNumber}
                                    setQuarterNumber={setQuarterNumber}
                                    setNumber={setNumber}
                                    setSetNumber={setSetNumber}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    setQuestionToPreview={setQuestionToPreview}
                                    expandedTabs={expandedTabs}
                                    toggleExpand={toggleExpand}
                                    canUpdateFacts={canUpdateFacts}
                                    resultOptions={{
                                        identity: viewResultsIdentity,
                                        ageGroup: viewResultsAge,
                                    }
                                    }


            />
        </SafeAreaView>

    );
};

const styles = StyleSheet.create({
    title: mbTextStyles([textStyles.largerText, {
        color: COLORS.textPrimary,
        fontWeight: 'bold',
        marginBottom: 20,
    }]),
    subtitle: mbTextStyles([textStyles.normalText, {
        color: COLORS.greyText,
       textAlign: 'left',
    }]),
    text: mbTextStyles([textStyles.normalText, {
        color: COLORS.textPrimary,
        textAlign: 'left',
    }]),
});

export { QuarterQuestionsView };

