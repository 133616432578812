import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { MB_TabView, MB_TabViewRef } from '@mightybyte/rnw.components.tab-view';
import VerificationStep from './Screens/VerificationStep';
import AccountSetupStep from './Screens/AccountSetupStep';
import UploadProfilePhotoStep from './Screens/UploadProfilePhotoStep';
import ConnectYourEmailStep from './Screens/ConnectYourEmailStep';
import ReferralCodeStep from './Screens/ReferralCodeStep';
import { WelcomeSetup } from './Screens/WelcomeSetup';
import { ScreenProps } from '../../../typesAndInterfaces/componentProps';
import { AccountSetupForm } from '../../../typesAndInterfaces/typesAndInterfaces';

const AccountSetup = ({ route }: ScreenProps<'AccountSetup'>) => {
    const { token, phoneNumber, skipVerification } = route.params;
    const tabRef = useRef<MB_TabViewRef>(null);
    const step = useRef(0);
    const [setupForm, setSetupForm] = useState<AccountSetupForm>({} as AccountSetupForm);

    const goToNextStep = useCallback(() => {
        tabRef.current?.navigateToTab(++step.current);
    }, []);

    const onCompleteStep = useCallback((partialForm: Partial<AccountSetupForm>) => {
        setSetupForm(prev => ({ ...prev, ...partialForm }));
        goToNextStep();
    }, [goToNextStep]);

    const immutableScreens = useMemo(
        () => [
            skipVerification ? null : () => <VerificationStep verificationToken={token} phoneNumber={phoneNumber} onVerify={goToNextStep} />,
            () => <AccountSetupStep onComplete={onCompleteStep} />,
            () => <UploadProfilePhotoStep onComplete={onCompleteStep} />,
            () => <ConnectYourEmailStep onComplete={onCompleteStep} />,
        ].filter(Boolean) as (() => React.JSX.Element)[],
        [goToNextStep, onCompleteStep, phoneNumber, skipVerification, token]
    );

    const screens = useMemo(() => [
        ...immutableScreens,
        () => <ReferralCodeStep setupForm={setupForm} onComplete={onCompleteStep} />,
        () => <WelcomeSetup setupForm={setupForm} />,
    ], [immutableScreens, onCompleteStep, setupForm]);

    const titles = useMemo(() => screens.map((_, index) => `screen#${index}`), [screens]);

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <MB_TabView
                    MB_Ref={tabRef}
                    titles={titles}
                    screens={screens}
                    swipeEnabled={false}
                    tabStyle={styles.tab}
                />
            </View>
        </View>
    );
};

export { AccountSetup };

const styles = StyleSheet.create({
    screen: {
        paddingHorizontal: 0,
        paddingBottom: 0,
    },
    container: {
        flex: 1,
    },
    content: {
        flex: 1,
        zIndex: -1,
    },
    tab: {
        display: 'none',
    },
});

