import React, { useCallback, useEffect, useState } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import { Spacer } from '../../../../helperComponents/Spacer';
import { UserIdentity } from '@sapphicsavvy/business';
import XIcon from '../../../../../resources/svgComponents/XIcon';
import { ShadowView } from '../../../../helperComponents/ShadowView';
import ShadowCheckbox from '../../../../helperComponents/ShadowCheckbox';
import clone from 'lodash/clone';
import { getTopInset, hitSlop } from '../../../../../utils/shared';
import { Slider } from '@miblanchard/react-native-slider';

function getOpacity(value: number) {
    const dist = 60 - value;
    const minDist = 20;
    if (dist > minDist) {
        return 1;
    }
    return dist * 1 / minDist;
}

export type Filter = {
    identity?: UserIdentity[];
    age?: {
        min: number;
        max: number;
    }
}

export type FilterModalProps = {
    isVisible: boolean,
    filter?: Filter,
    onDismiss?: (filter?: Filter) => void,
    onModalHide?: () => void,
}

export default function FilterModal({ isVisible, onDismiss, filter, onModalHide }: FilterModalProps) {

    const [newFilter, setNewFilter] = useState<Filter>();

    const onToggleIdentity = (identity: UserIdentity) => {
        setNewFilter(prev => {
            const identites = prev?.identity ?? [];
            if (identites.includes(identity)) {
                const newIdentites = identites.filter(e => e !== identity);
                if (newIdentites.length === 0) {
                    return { ...prev, identity: undefined };
                }
                return { ...prev, identity: newIdentites };
            }
            return { ...prev, identity: [...identites, identity] };
        });
    };

    const onChangeAge = useCallback((ageRange) => {
        setNewFilter(prev => {
            if (ageRange[1] === 60) {
                return { ...prev, age: { min: ageRange[0], max: null } };
            }
            return { ...prev, age: { min: ageRange[0], max: ageRange[1] } };
        });
    }, []);

    const renderAboveThumbComponent = useCallback(
        (_, value: number) => <Text style={[styles.thumbText, value === 60 && styles.maxThumbText]}>{value === 60 ? '60+' : value}</Text>,
        []
    );

    const renderThumbComponent = useCallback(
        () => <View style={styles.thumb} />,
        []
    );

    useEffect(() => {
        if (isVisible) {
            setNewFilter(clone(filter));
        }
    }, [filter, isVisible]);

    const sixtyPlusTextOpacity = getOpacity(newFilter?.age?.max ?? 60);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={() => onDismiss?.()}
            hideCloseButton
            childrenWrapperStyle={styles.container}
            withoutScrollView
            onModalHide={onModalHide}
        >
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>FILTERS</Text>
                <TouchableOpacity
                    onPress={() => onDismiss?.()}
                    hitSlop={hitSlop}
                >
                    <XIcon size={20} color="#000000" />
                </TouchableOpacity>
            </View>
            <Spacer height={20} />
            <View style={styles.content}>
                <View style={styles.section}>
                    <Text style={styles.sectionTitleText}>Sapphics Age Range</Text>
                    <ShadowView style={[styles.sectionContent, styles.ageSection]}>
                        <Slider
                            trackStyle={styles.track}
                            onValueChange={onChangeAge}
                            value={[newFilter?.age?.min ?? 18, newFilter?.age?.max ?? 60]}
                            minimumValue={18}
                            maximumValue={60}
                            step={1}
                            stepsBetweenThumbs={4}
                            maximumTrackTintColor="#898989"
                            minimumTrackTintColor="#F35E2E"
                            renderAboveThumbComponent={renderAboveThumbComponent}
                            renderThumbComponent={renderThumbComponent}
                        />
                        <Text style={[styles.thumbText, styles.maxThumbText, styles.sixtyPlusText, { opacity: sixtyPlusTextOpacity }]}>60+</Text>
                    </ShadowView>
                </View>
                <Spacer height={40} />
                <View style={styles.section}>
                    <Text style={styles.sectionTitleText}>Sapphics identity</Text>
                    <ShadowView style={styles.sectionContent}>
                        <ShadowCheckbox
                            value={newFilter?.identity?.includes(UserIdentity.lesbian) ?? false}
                            text="Lesbian"
                            style={styles.checkboxContainer}
                            textStyle={styles.checkboxText}
                            checkBoxStyle={styles.checkbox}
                            onChange={() => onToggleIdentity(UserIdentity.lesbian)}
                        />
                        <Spacer height={29} />
                        <ShadowCheckbox
                            value={newFilter?.identity?.includes(UserIdentity.bisexual) ?? false}
                            text="Bisexual"
                            style={styles.checkboxContainer}
                            textStyle={styles.checkboxText}
                            checkBoxStyle={styles.checkbox}
                            onChange={() => onToggleIdentity(UserIdentity.bisexual)}
                        />
                        <Spacer height={29} />
                        <ShadowCheckbox
                            value={newFilter?.identity?.includes(UserIdentity.queer) ?? false}
                            text="Queer"
                            style={styles.checkboxContainer}
                            textStyle={styles.checkboxText}
                            checkBoxStyle={styles.checkbox}
                            onChange={() => onToggleIdentity(UserIdentity.queer)}
                        />
                    </ShadowView>
                </View>
                <TouchableOpacity onPress={() => setNewFilter({})}>
                    <ShadowView style={styles.reset}>
                        <Text style={styles.resetText}>Reset Filters</Text>
                    </ShadowView>
                </TouchableOpacity>
            </View>
            <Spacer />
            <View style={styles.footer}>
                <MB_Button
                    title="APPLY"
                    style={styles.primaryBtn}
                    textStyle={styles.primaryBtnText}
                    onPress={() => onDismiss?.(newFilter)}
                />
                <Spacer width={9} />
                <MB_Button
                    title="CANCEL"
                    style={styles.secondaryBtn}
                    textStyle={[styles.primaryBtnText, styles.secondaryBtnText]}
                    onPress={() => onDismiss?.()}
                />
            </View>
        </MB_Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#EFEFF3',
        marginVertical: 0,
        width: '100%',
        height: '100%',
        paddingHorizontal: 0,
        paddingBottom: 0,
        marginBottom: 1,
        paddingTop: getTopInset(),
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderBottomWidth: 1,
        borderBottomColor: '#8E9396',
        paddingBottom: 20,
        paddingLeft: 40,
        paddingRight: 30,
        paddingTop: 20,
    },
    headerTitleText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 25,
        textAlign: 'left',
        color: '#000000',
    }]),
    content: {
        alignSelf: 'stretch',
        paddingHorizontal: 20,
    },
    section: {
        alignSelf: 'stretch',
    },
    sectionTitleText: mbTextStyles([textStyles.smallText, {
        fontWeight: '400',
        textAlign: 'left',
        color: '#000000',
        marginBottom: 18,
    }]),
    ageSection: {
        paddingVertical: 18,
        paddingTop: 36,
        paddingHorizontal: 19,
    },
    sectionContent: {
        paddingVertical: 29,
        paddingHorizontal: 19,
        borderRadius: 12,
    },
    track: {
        height: 2,
    },
    thumb: {
        width: 16,
        height: 16,
        backgroundColor: '#F35E2E',
        borderWidth: 2,
        borderRadius: 360,
        borderColor: '#FFE7EC',
    },
    thumbText: mbTextStyles([textStyles.normalText, {
        color: '#F35E2E',
        textAlign: 'left',
        marginLeft: -10,
        marginBottom: -8,
    }]),
    maxThumbText: {
        color: '#898989',
        marginLeft: -20,
    },
    sixtyPlusText: {
        position: 'absolute',
        right: 16,
        top: 24,
    },
    checkboxContainer: {
        alignItems: 'center',
    },
    checkbox: {
        borderWidth: 2,
        borderColor: '#F35E2E',
        borderRadius: 0,
    },
    checkboxText: mbTextStyles([textStyles.normalText, {
        color: '#2C2826',
    }]),
    reset: {
        paddingHorizontal: 14,
        paddingVertical: 9,
        backgroundColor: '#FFFFFF',
        borderRadius: 15,
        alignSelf: 'flex-start',
        marginTop: 20,
    },
    resetText: mbTextStyles([textStyles.normalText, {
        fontWeight: '600',
        color: '#F35E2E',
    }]),
    footer: {
        height: 97,
        paddingTop: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#F35E2E',
        alignSelf: 'stretch',
    },
    primaryBtn: {
        width: 163,
        height: 43,
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        borderWidth: 0,
    },
    primaryBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#F35E2E',
        fontWeight: '800',
    }]),
    secondaryBtn: {
        width: 163,
        height: 43,
        borderRadius: 8,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: '#FFFFFF',
    },
    secondaryBtnText: {
        color: '#FFFFFF',
    },
});
