import React from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';
import { View } from 'react-native';
import { Header } from './Shared/Header';
import { ComingSoon } from './Shared/ComingSoon';

const ResourceArticles = () => {

    return (
        <ComponentWrapper
            containerStyle={sharedStyles.container}
        >
            <Header />
            <View style={sharedStyles.body}>
                <ComingSoon />
            </View>
        </ComponentWrapper>
    );

};

export { ResourceArticles };
