import React, { useCallback, useMemo } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import {useNavigation} from '@react-navigation/core';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { MB_Button } from '@mightybyte/rnw.components.button';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {quarterSharedStyles} from './quarterSharedStyles';
import { getColors } from '../../../../../constants/colors';
import { LoadingPage } from '../../../../helperComponents/LoadingPage';
import { Spacer } from '../../../../helperComponents/Spacer';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import GradientContainer from '../../../../helperComponents/gradients/GradientContainer';
import { ListQuartersReturnType, QuarterStatus } from '@sapphicsavvy/business';
import { textStyles } from '../../../../../constants/textStyles';
import EditIcon from '../../../../../resources/svgComponents/EditIcon';
import TrashIcon from '../../../../../resources/svgComponents/TrashIcon';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AdminScreenProps } from '../../../../../typesAndInterfaces/componentProps';
import { AdminConfirmPopup } from './ConfirmActionUI';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { utils } from '../../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../../constants/constants';

type Props = {
    addNewButtonAction: () => void;

}
const COLORS = getColors();

const QuartersList = ({
    addNewButtonAction,
}:Props) => {

    const trpcUtils = trpc.useUtils();
    const navigation = useNavigation<NativeStackNavigationProp<AdminScreenProps<'QuarterQuestions'>>>();
    const [quarterToDelete, setQuarterToDelete] = React.useState<string | undefined>(undefined);

    const openQuarter = useCallback((quarterId:string, initialSet?:string)=> {
        navigation.setParams({id: quarterId, initialSet});
    },[navigation]);

    const {data, isLoading} = trpc.admin.quarters.list.useQuery();

    const QuarterCard = useCallback(({quarter}) => {
        const isDraft = quarter.status === QuarterStatus.draft;
        const isPublished = quarter.status === QuarterStatus.published;
        let gradientProps:{
            colors: [string, string],
            angle: number
        } = {
            colors: ['#FCAF4140', '#FCAF4140'],
            angle: -45,
        };

        let textColor = COLORS.orange;
        let buttonLabel = isDraft ? 'Edit Draft' : 'View Details';
        if (quarter.status === QuarterStatus.active) {
            gradientProps.colors = ['#EE3823', '#FCAF41'];
            textColor =  COLORS.white;
        } else if (quarter.status === QuarterStatus.closed) {
            gradientProps.colors = ['#F35E2E','#F35E2E'];
            buttonLabel = 'View Results';
            textColor =  COLORS.white;
        }

        const startDateToDisplay = `${quarter.startDate.month}/${quarter.startDate.day}/${quarter.startDate.year}`;
        const endDateToDisplay = `${quarter.endDate.month}/${quarter.endDate.day}/${quarter.endDate.year}`;

        return (
            <View style={styles.cardWrapper} key={`${quarter?.status}-${quarter.quarterNumber}-${quarter.setNumber}`}>
                <GradientContainer {...gradientProps} style={styles.cardContent}>
                    <Text style={[styles.smallText, {color: textColor}]}>{quarter.status}</Text>
                    <View>
                        <Text style={[styles.titleText, {color: textColor}]}>{`Quarter ${quarter.quarterNumber}`}</Text>
                        <Text style={[styles.smallText, {color: isDraft || isPublished ? textColor : '#FFFFFF8C', fontSize: 12}]}>{`${startDateToDisplay} - ${endDateToDisplay}`}</Text>
                        <Spacer height={10}/>
                    </View>
                </GradientContainer>
                <Spacer height={8}/>
                <View style={quarterSharedStyles.rowSpaceBetween}>
                    <MB_Button title={buttonLabel}
                               style={[quarterSharedStyles.secondaryBtn, {borderWidth: 1, height: 39, flex: 1}]}
                               textStyle={styles.buttonText}
                               onPress={()=>openQuarter(quarter.id)}
                               leftElement={quarter.status === QuarterStatus.draft ? <EditIcon/> : undefined}
                    />
                    {isDraft ?
                        <MB_Button title=""
                                   onPress={()=>setQuarterToDelete(quarter.id)}
                                   leftElement={<TrashIcon/>}
                                   style={{backgroundColor: mbApplyTransparency(COLORS.red, 0.1),
                                       height: '100%',
                                       marginLeft: 8,

                        }}
                    /> : null}
                    <View />
                </View>
            </View>
        );
    },[openQuarter]);

    const renderItem = useCallback(({item, idx})=> {
        const setNumber = item[0].setNumber;
        const key = `set-${setNumber}-${idx}`;
        return (
            <View style={[quarterSharedStyles.section, {marginBottom: 16}]} key={key}>
                <Text style={[quarterSharedStyles.titleText, quarterSharedStyles.uppercase]}>{`Set ${setNumber}`}</Text>
                <View style={styles.setRow}>
                    {item.map((quarter:ListQuartersReturnType, index:number) => <QuarterCard key={`${quarter.id}-${setNumber}-${index}-${quarter.status}`} quarter={quarter} />)}
                    {item.length < 4 ? <TouchableOpacity style={styles.cardWrapper} onPress={()=>openQuarter('new', setNumber)}>
                        <View style={[styles.cardContent, styles.addNewCard]}>
                            <AntDesign name={'plus'} size={36} color={COLORS.orange}/>
                            <Spacer height={12}/>
                            <Text style={mbTextStyles([textStyles.normalText, {color: COLORS.orange}])}> New Quarter</Text>
                        </View>

                    </TouchableOpacity> : null}
                </View>

            </View>
        );
    },[QuarterCard, openQuarter]);

    const {mutate: deleteQuarter} = trpc.admin.quarters.delete.useMutation();

    const onDeleteConfirm = useCallback(()=> {
    if (quarterToDelete) {
            deleteQuarter({
                id: quarterToDelete,
            }, {
                onSuccess: ()=> {
                    trpcUtils.admin.quarters.list.invalidate();
                    utils.showToast('success', 'Quarter deleted');
                    setTimeout(()=>setQuarterToDelete(undefined), 600);
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }

    },[deleteQuarter, quarterToDelete, trpcUtils.admin.quarters.list]);

    const quarterDeleteNote = useMemo(()=> {
        if (quarterToDelete) {
            const q =  data?.sets.find((item) => item.some((quarter) => quarter.id === quarterToDelete));
            const qNumber = q ? q?.find((i)=> i.id === quarterToDelete)?.quarterNumber : undefined;
            return q ? `Quarter ${qNumber}, Set ${q[0].setNumber}` : undefined;
        }
        return undefined;
    },[data?.sets, quarterToDelete]);


    return (
        <View style={{flex:1}}>
            <MB_Modal isVisible={!!quarterToDelete}
                      hideCloseButton={true}
                      childrenWrapperStyle={{padding: 0}}
            >
                {<AdminConfirmPopup title={'Delete Quarter'}
                                    middle={quarterDeleteNote}
                                    note={'Are you sure you want to delete the quarter draft? Deleting it will remove all the questions added to this quarter.'}
                                    buttons={[
                                        <MB_Button title={'Delete'}
                                                   onPress={onDeleteConfirm}
                                                   style={[quarterSharedStyles.secondaryBtn, {width: 150, marginRight: 20}]}
                                                   textStyle={quarterSharedStyles.secondaryBtnText}
                                        />,
                                        <MB_Button title={'Cancel'}
                                                   onPress={() => setQuarterToDelete(undefined)}
                                                   style={[quarterSharedStyles.button, {width: 150}]}
                                                   textStyle={quarterSharedStyles.buttonText}
                                        />,
                                    ]}
                />}
            </MB_Modal>
            <View style={quarterSharedStyles.rowSpaceBetween}>
                <Text style = {quarterSharedStyles.titleText}>Manage Quarter Questions</Text>
                <MB_Button
                    leftElement={<AntDesign name="plus" size={20} color="white" />}
                    title="New Quarter"
                    onPress={addNewButtonAction}
                    style={quarterSharedStyles.button}
                    textStyle={quarterSharedStyles.buttonText}
                />

            </View>
            <Spacer height={20}/>
            {isLoading ? <View style={{flex:1}}> <LoadingPage /></View> :
                <ScrollView>
                    {data?.sets.map((item, index)=> renderItem({item, idx: index}))}

                </ScrollView>

            }


        </View>

    );
};

export { QuartersList };

const styles = StyleSheet.create({
    setRow:{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,
    },

    cardWrapper: {
        padding: 5,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: COLORS.strokes,
        width: 210,
        height: 183,
        marginRight: 18,
    },

    cardContent: {
        padding: 10,
        flex: 1,
        justifyContent: 'space-between',
        borderRadius: 5,
    },
    smallText: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
        textTransform: 'capitalize',
    }]),
    titleText: mbTextStyles([textStyles.largestText, {
        textAlign: 'left',
        fontSize: 22,
        textTransform: 'capitalize',

    }]),
    buttonText: mbTextStyles([textStyles.smallText, {
        fontSize: 16,
        color: COLORS.orange,
    }]),
    addNewCard: {
        backgroundColor: '#FCAF4140',
        alignItems: 'center',
        justifyContent: 'center',
    },
});


