import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type XIconProps = {
  color?: string
  size?: number
}

function XIcon({ color = '#F35E2E', size = 16 }: XIconProps) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 16 15"
      fill="none"
    >
      <Path
        d="M15.045 1.831L13.625.412 8 6.038 2.373.412.954 1.832 6.58 7.457.954 13.084l1.42 1.419L8 8.877l5.626 5.626 1.419-1.42-5.626-5.625 5.626-5.627z"
        fill={color}
      />
    </Svg>
  );
}

export default XIcon;
