import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function DocumentIcon() {
  return (
    <Svg
      width={21}
      height={22}
      viewBox="0 0 21 22"
      fill="none"
    >
      <Path
        d="M1.743 11.654v1.768c0 4.375 1.75 6.125 6.125 6.125h5.25c4.375 0 6.125-1.75 6.125-6.125v-5.25c0-4.375-1.75-6.125-6.125-6.125h-5.25c-4.375 0-6.125 1.75-6.125 6.125M13.775 8.172H7.212M13.775 13.422H7.212"
        stroke="#292D32"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default DocumentIcon;
