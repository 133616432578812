import React, { useCallback, useEffect, useState } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { Header } from './Shared/Header';
import { quarterSharedStyles } from './components/quarter/quarterSharedStyles';
import { trpc } from '../../../apiCalls/trpcClient';
import { AgeGroup, DataExport, UserIdentity, DataExportInputType } from '@sapphicsavvy/business';
import { MB_Button } from '@mightybyte/rnw.components.button';
import {MB_customWindowForWeb} from '@mightybyte/rnw.utils.custom-window-for-web';
import {Spacer} from '../../helperComponents/Spacer';
import { RadioButton } from '../../helperComponents/RadioButton';
import { getColors } from '../../../constants/colors';
import { MB_TextInput, MB_TextInputInputType } from '@mightybyte/rnw.components.text-input';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { utils } from '../../../utils/utils';

const COLORS = getColors();

const AppData = () => {

    const [selectedDataType, setSelectedDataType] = useState<DataExport>(DataExport.profile);
    const [selectedQuarters, setSelectedQuarters] = useState<'all'| 'specific'>('all');
    const [selectedUsers, setSelectedUsers] = useState<'all'| 'specific'>('all');

    const [selectedIdentity, setSelectedIdentity] = useState<UserIdentity>(UserIdentity.lesbian);
    const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroup>(AgeGroup.lateTeens);

    const [inStudySelected, setIStudySelected] = useState(false);

    const [selectedQuarterNumber, setSelectedQuarterNumber] = useState<number | undefined>(undefined);
    const [selectedSetNumber, setSelectedSetNumber] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (selectedQuarters === 'all') {
            setSelectedQuarterNumber(undefined);
            setSelectedSetNumber(undefined);
        }
    }, [selectedQuarters]);

    useEffect(() => {
        if (selectedDataType === DataExport.profile) {
            setSelectedQuarters('all');
        }
    }, [selectedDataType]);

    const trpcUtils = trpc.useUtils();

    const downloadHandler = async () => {
        if (selectedDataType === DataExport.profile) {
            downloadUsers();
        }
        else {
            downloadQuarters();
        }
    };

    const getUserFilters = useCallback(() => {
        if (selectedUsers === 'specific') {
            return {
                identity: selectedIdentity,
                ageGroup: selectedAgeGroup,
                isInStudy: inStudySelected,
            };
        } else {
            return undefined;
        }
    },[selectedIdentity, selectedAgeGroup, inStudySelected, selectedUsers]);

    const downloadQuarters =  ()=> {
        if (Platform.OS === 'web') {
            const filters:DataExportInputType['filters'] = {};
            if (selectedQuarters === 'specific') {
                filters.quarterFilter = {
                    quarterNumber: selectedQuarterNumber,
                    setNumber: selectedSetNumber,
                };
            }
            filters.userFilter = getUserFilters();
            trpcUtils.admin.utils.exportData.fetch({
                dataType: DataExport.quarterResponses,
                filters,
            }).then((data) => {
                if (!data) {
                    return;
                }
                MB_customWindowForWeb.open(data.csvUrl, '_blank');
            });
        } else {
            console.log('Download is not supported on this platform');
        }
    };

    const downloadUsers = ()=> {
        if (Platform.OS === 'web') {
            trpcUtils.admin.utils.exportData.fetch({
                dataType: DataExport.profile,
                filters: {
                    userFilter: getUserFilters(),
                },
            }).then((data) => {
                if (!data) {
                    console.log('No data to download');
                    return;
                }
                MB_customWindowForWeb.open(data.csvUrl, '_blank');
            });
        } else {
            console.log('Download is not supported on this platform');
        }
    };

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.innerContainer}
            containerStyle={sharedStyles.container}
            scrollViewStyle={sharedStyles.scrollView}
            wrapInScrollView
        >
            <Header />
            <View style={sharedStyles.body}>
                <Text style = {quarterSharedStyles.titleText}>Download data</Text>
                <Spacer height={20}/>

                <View style={[quarterSharedStyles.section]}>
                    <Text style={[quarterSharedStyles.titleText, {fontSize: 18}]}>Download Data Type</Text>
                    <Spacer height={16}/>
                    <View style={{flexDirection: 'row'}}>
                        <RadioButton value={DataExport.profile}
                                        radioText={'Profile Data'}
                                        groupValue={selectedDataType}
                                        setGroupValue={setSelectedDataType}/>
                        <Spacer width={20}/>
                        <RadioButton value={DataExport.quarterResponses}
                                        radioText={'Quarter Responses'}
                                        groupValue={selectedDataType}
                                        setGroupValue={setSelectedDataType}/>

                    </View>
                    {selectedDataType === DataExport.quarterResponses ?
                        <View style={[styles.subsection, {marginTop: 8}]}>
                            <View>
                                <RadioButton value={'all'}
                                             radioText={'All'}
                                             groupValue={selectedQuarters}
                                             setGroupValue={setSelectedQuarters}/>
                            </View>

                            <Spacer width={40}/>
                            <View style={[styles.quarterSelectorContainer, {
                                backgroundColor: selectedQuarters === 'specific' ? COLORS.white : COLORS.lighterGray,
                                paddingBottom: selectedQuarters === 'specific' ? 16 : 0,
                            }]}>
                                <RadioButton value={'specific'}
                                             radioText={'Specific'}
                                             groupValue={selectedQuarters}
                                             setGroupValue={setSelectedQuarters}/>
                                {selectedQuarters === 'specific' ?
                                    <View style={{marginTop: 20, flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
                                        <View style={quarterSharedStyles.row}>
                                            <MB_TextInput
                                                title={'Quarter'}
                                                onChangeText={(text) => setSelectedQuarterNumber(Number(text))}
                                                value={selectedQuarterNumber?.toString()}
                                                inputType={MB_TextInputInputType.numbers}
                                                titleStyle={quarterSharedStyles.textInputTitle}
                                                textInputStyle={quarterSharedStyles.textInputStyle}
                                                style={quarterSharedStyles.smallInput}
                                            />
                                            <Spacer width={20}/>
                                            <MB_TextInput
                                                title={'Set'}
                                                onChangeText={(text) => setSelectedSetNumber(Number(text))}
                                                value={selectedSetNumber?.toString()}
                                                inputType={MB_TextInputInputType.numbers}
                                                titleStyle={quarterSharedStyles.textInputTitle}
                                                textInputStyle={quarterSharedStyles.textInputStyle}
                                                style={quarterSharedStyles.smallInput}
                                                />
                                        </View>
                                    </View>
                                    : null}
                            </View>


                        </View>
                        : null}
                </View>
                <Spacer height={18}/>
                <View style={[quarterSharedStyles.section]}>
                    <Text style={[quarterSharedStyles.titleText, {fontSize: 18}]}>Download Data from all or specific user groups </Text>
                    <View style={{marginTop: 8, flexDirection: 'row'}}>
                            <RadioButton value={'all'}
                                         radioText={'All'}
                                         groupValue={selectedUsers}
                                         setGroupValue={setSelectedUsers}/>
                        <Spacer width={40}/>
                            <RadioButton value={'specific'}
                                         radioText={'Specific user groups'}
                                         groupValue={selectedUsers}
                                         setGroupValue={setSelectedUsers}/>
                    </View>
                    {selectedUsers === 'specific' ? <View style={[styles.subsection]}>
                        <View style={{alignItems: 'flex-start'}}>
                            <Text style={[styles.columnLabel]}>Sexual identity</Text>
                            <RadioButton value={UserIdentity.lesbian}
                                         radioText={utils.capitalizeFirstLetter(UserIdentity.lesbian)}
                                            groupValue={selectedIdentity}
                                            setGroupValue={setSelectedIdentity}
                            />
                            <RadioButton value={UserIdentity.bisexual}
                                            radioText={utils.capitalizeFirstLetter(UserIdentity.bisexual)}
                                            groupValue={selectedIdentity}
                                            setGroupValue={setSelectedIdentity}
                            />
                            <RadioButton value={UserIdentity.queer}
                                            radioText={utils.capitalizeFirstLetter(UserIdentity.queer)}
                                            groupValue={selectedIdentity}
                                            setGroupValue={setSelectedIdentity}
                                         />
                        </View>
                        <Spacer width={40}/>
                        <View style={{alignItems: 'flex-start'}}>
                            <Text style={[styles.columnLabel]}>Age groups</Text>
                            <RadioButton value={AgeGroup.lateTeens}
                                            radioText={'Late teens (18-19)'}
                                            groupValue={selectedAgeGroup}
                                            setGroupValue={setSelectedAgeGroup}
                            />
                            <RadioButton value={AgeGroup['20s']}
                                            radioText={'20s'}
                                            groupValue={selectedAgeGroup}
                                            setGroupValue={setSelectedAgeGroup}
                            />
                            <RadioButton value={AgeGroup['30s']}
                                            radioText={'30s'}
                                            groupValue={selectedAgeGroup}
                                            setGroupValue={setSelectedAgeGroup}
                                         />
                            <RadioButton value={AgeGroup['40s']}
                                             radioText={'40s'}
                                              groupValue={selectedAgeGroup}
                                              setGroupValue={setSelectedAgeGroup}
                                         />
                            <RadioButton value={AgeGroup['50s+']}
                                                radioText={'50s+'}
                                                groupValue={selectedAgeGroup}
                                                setGroupValue={setSelectedAgeGroup}
                            />
                        </View>
                        <Spacer width={80}/>
                        <View style={{alignItems: 'flex-start'}}>
                            <Text style={[styles.columnLabel]}>Other</Text>
                            <RadioButton value={true}
                                         radioText={'In study'}
                                         groupValue={inStudySelected}
                                         setGroupValue={()=> {
                                                setIStudySelected(!inStudySelected);
                                         }}
                            />
                        </View>
                    </View> : null}
                </View>
                <MB_Button title={'Download CSV'}
                           onPress={downloadHandler}
                           style={[quarterSharedStyles.button, {width: 200, marginTop: 20}]}
                           textStyle={quarterSharedStyles.buttonText}
                />
            </View>
        </ComponentWrapper>
    );

};

export { AppData };

const styles = StyleSheet.create({
    subsection: {
        backgroundColor: COLORS.lighterGray,
        padding: 16,
        borderRadius: 5,
        flexDirection: 'row',
        maxWidth: 530,
    },
    quarterSelectorContainer: {
        paddingHorizontal: 16,
        borderRadius: 5,
        width: 248,
        alignItems:'flex-start',
    },
    columnLabel: mbTextStyles([
        textStyles.largerText,
        {
            fontSize:14,
            color: COLORS.greyText,
            paddingBottom: 8,
        },
    ]),
});
