import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function ProfileCircle() {
  return (
    <Svg
      width={31}
      height={30}
      viewBox="0 0 31 30"
      fill="none"
    >
      <Path
        d="M27.87 15c0-6.887-5.613-12.5-12.5-12.5-6.888 0-12.5 5.613-12.5 12.5 0 3.625 1.562 6.887 4.037 9.175 0 .012 0 .012-.012.025.125.125.275.225.4.338.075.062.137.125.212.174.225.188.475.363.713.538.087.063.162.113.25.175.237.162.487.313.75.45.087.05.187.113.275.163.25.137.512.262.787.375.1.05.2.1.3.137.275.113.55.212.825.3.1.037.2.075.3.1.3.088.6.163.9.238.088.024.175.05.275.062.35.075.7.125 1.063.163.05 0 .1.012.15.024.425.038.85.063 1.275.063.425 0 .85-.025 1.262-.063.05 0 .1-.012.15-.024a9.892 9.892 0 001.063-.163c.087-.012.175-.05.275-.063.3-.075.612-.137.9-.237.1-.037.2-.075.3-.1.275-.1.562-.188.825-.3.1-.038.2-.088.3-.137.262-.113.525-.238.787-.375.1-.05.188-.113.275-.163.25-.15.5-.288.75-.45.088-.05.163-.113.25-.175.25-.175.488-.35.713-.538.075-.062.137-.125.212-.174.138-.113.275-.226.4-.338 0-.012 0-.012-.012-.025 2.487-2.288 4.05-5.55 4.05-9.175zm-6.325 6.212c-3.388-2.274-8.938-2.274-12.35 0-.55.363-1 .788-1.375 1.25A10.6 10.6 0 014.745 15c0-5.863 4.762-10.625 10.625-10.625 5.862 0 10.625 4.762 10.625 10.625a10.6 10.6 0 01-3.075 7.462 5.785 5.785 0 00-1.375-1.25z"
        fill="#F35E2E"
      />
      <Path
        d="M15.37 8.663a4.69 4.69 0 00-4.688 4.687c0 2.538 1.988 4.6 4.625 4.675h.225a4.679 4.679 0 004.525-4.675 4.69 4.69 0 00-4.687-4.687z"
        fill="#F35E2E"
      />
    </Svg>
  );
}

export default ProfileCircle;

