import * as React from 'react';
import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg';
import { uniqueId } from 'lodash';

function HeartIcon({ isFull }: { isFull?: boolean }) {
    const id = uniqueId();
    return (
        <Svg
            width={21}
            height={18}
            viewBox="0 0 21 18"
            fill="none"
        >
            <Path
                d="M9.733 2.695l.767.916.767-.916c.87-1.038 2.203-1.691 3.571-1.691 2.4 0 4.302 1.91 4.302 4.393 0 1.53-.668 2.995-2.039 4.7-1.383 1.72-3.38 3.564-5.881 5.872l-.002.001-.72.668-.717-.659H9.78c-2.502-2.313-4.498-4.16-5.88-5.88-1.372-1.707-2.04-3.171-2.04-4.702 0-2.484 1.902-4.393 4.302-4.393 1.368 0 2.702.653 3.571 1.691z"
                fill={isFull ? `url(#${id})` : '#FBFDFE' }
                stroke={isFull ? undefined : '#EE3823'}
                strokeWidth={isFull ? undefined : 2}
            />
            {isFull &&
                <Defs>
                    <LinearGradient
                        id={id}
                        x1={2.39162}
                        y1={2.49068}
                        x2={19.9206}
                        y2={10.4291}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#EE3823" />
                        <Stop offset={1} stopColor="#FCAF41" />
                    </LinearGradient>
                </Defs>
            }
        </Svg>
    );
}

export default HeartIcon;
