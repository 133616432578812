import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet } from 'react-native';
import { BaseToast, ErrorToast, ToastConfigParams } from 'react-native-toast-message';
import { getColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';

const COLORS = getColors();

const CustomToastConfig = {
    success: (props: ToastConfigParams<any>) => {
        const { text1, text2, text1Style, text2Style, ...restOfProps } = props;
        return (
            <BaseToast
                style={styles.toastSuccessStyle}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={text1Style ?? styles.text1Style}
                text2Style={text2Style ?? styles.text2Style}
                text1={text1 ?? 'Success!'}
                text2={text2 ?? 'Updated successfully'}
                {...restOfProps}
            />
        );
    },
    error: (props: ToastConfigParams<any>) => {
        const { text1, text2, ...restOfProps } = props;
        return (
            <ErrorToast
                style={styles.toastErrorAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={styles.text1Style}
                text2Style={styles.text2Style}
                text1={text1 ?? 'Failed!'}
                text2={text2}
                {...restOfProps}
            />
        );
    },
};

export { CustomToastConfig };

const baseStyles = StyleSheet.create({
    toastStyle: {
        borderStartWidth: 0,
        paddingHorizontal: 10,
        alignItems: 'center',
        height: undefined,
        paddingVertical: 5,
    },
    questionToastStyle: {
        borderStartWidth: 0,
        paddingTop: 8,
        paddingBottom: 10,
        paddingHorizontal: 10,
        alignItems: 'center',
        height: undefined,
        width: undefined,
    },
});

const styles = StyleSheet.create({
    defaultIconWrapper: {
        width: 17,
        height: 17,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.textPrimary,
        borderRadius: 300,
    },
    text1Style: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 11,
            lineHeight: undefined,
            textAlign: 'left',
            fontWeight: '700',
            marginStart: 0,
            marginLeft: 0,
            margin: 0,
            marginHorizontal: 0,
            alignSelf: 'flex-start',
            color: COLORS.textPrimary,
        },
    ]),
    text2Style: mbTextStyles([
        textStyles.smallerText, {
            lineHeight: undefined,
            textAlign: 'left',
            fontWeight: '400',
            marginStart: 0,
            marginLeft: 0,
            margin: 0,
            marginHorizontal: 0,
            alignSelf: 'flex-start',
            color: mbApplyTransparency('#000000', 0.6),
            marginTop: 2,
        },
    ]),
    toastSuccessStyle: StyleSheet.flatten([
        baseStyles.toastStyle, {
            backgroundColor: COLORS.backgroundPrimary,
            borderStartColor: '#7BC852',
            borderStartWidth: 5,
            paddingVertical: 8,
        },
    ]),
    toastErrorAnswerStyle: StyleSheet.flatten([
        baseStyles.toastStyle, {
            backgroundColor: COLORS.backgroundPrimary,
            borderStartColor: '#FE9F9F',
            borderStartWidth: 5,
            paddingVertical: 8,
        },
    ]),
    toastContentContainerStyle: {
        paddingStart: 8,
    },
    toastContentContainerStyleForGameToasts: {
        paddingStart: 10,
        paddingEnd: 0,
        flex: 0,
    },
});
