import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function MessageIcon({ size}: { size?: number }) {
    return (
        <Svg
            width={size ?? 35}
            height={size ? size * (32 / 35) : 32}
            viewBox="0 0 35 32"
            fill="none"
        >
            <Path
                d="M25.833.05H9.167c-5 0-8.334 3.333-8.334 8.333v10c0 5 3.334 8.334 8.334 8.334v3.55c0 1.333 1.483 2.133 2.583 1.383l7.417-4.933h6.666c5 0 8.334-3.334 8.334-8.334v-10c0-5-3.334-8.333-8.334-8.333zM17.5 20.333c-.7 0-1.25-.566-1.25-1.25 0-.683.55-1.25 1.25-1.25s1.25.567 1.25 1.25c0 .684-.55 1.25-1.25 1.25zm2.1-6.916c-.65.433-.85.716-.85 1.183v.35a1.26 1.26 0 01-1.25 1.25 1.26 1.26 0 01-1.25-1.25v-.35c0-1.934 1.417-2.883 1.95-3.25.617-.417.817-.7.817-1.133 0-.834-.684-1.517-1.517-1.517-.833 0-1.517.683-1.517 1.517a1.26 1.26 0 01-1.25 1.25 1.26 1.26 0 01-1.25-1.25C13.483 8 15.283 6.2 17.5 6.2c2.217 0 4.017 1.8 4.017 4.016 0 1.9-1.4 2.85-1.917 3.2z"
                fill="#F35E2E"
            />
        </Svg>
    );
}

export default MessageIcon;
