import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg';

const ProfileIcon = ({ size = 160, disableBackgroundColor, style }: { size?: number, disableBackgroundColor?: boolean, style?: StyleProp<ViewStyle> }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 160 160"
      fill="none"
      style={style}
    >
      <G clipPath="url(#clip0_218_30195)">
        {!disableBackgroundColor &&
          <Path
            d="M159.527 80.5c0 43.921-35.606 79.527-79.527 79.527S.473 124.421.473 80.5 36.08.973 80 .973 159.527 36.58 159.527 80.5z"
            fill="#FA953C"
            fillOpacity={0.45}
          />
        }
        <Path
          d="M79.653 39.383c-16.558 0-29.996 13.438-29.996 29.996 0 16.238 12.718 29.436 29.596 29.916h1.44c16.157-.56 28.876-13.678 28.956-29.916 0-16.558-13.438-29.996-29.996-29.996zM142.57 159.736s-41.96.191-62.917.191-59.806.28-59.806.28c1.92-7.28 1.249-25.299 20.291-40.515 21.837-14.558 57.353-14.558 79.03 0 22.377 17.425 21.482 32.765 23.402 40.044z"
          fill="#F35E2E"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_218_30195">
          <Rect
            x={0.473389}
            y={0.973389}
            width={159.053}
            height={159.053}
            rx={79.5266}
            fill="#fff"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default ProfileIcon;

