import React from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

type InnerShadowProps = {
    style?: StyleProp<ViewStyle>
    children?: React.ReactNode,
    shadowColor?: string,
    shadowOpacity?: number,
    shadowRadius?: number,
    shadowOffset?: {
        height: number,
        width: number,
    },

    topShadowColor?: string,
    topShadowOpacity?: number,
    topShadowRadius?: number,
    topShadowOffset?: {
        height: number,
        width: number,
    }

    leftShadowColor?: string,
    leftShadowOpacity?: number,
    leftShadowRadius?: number,
    leftShadowOffset?: {
        height: number,
        width: number,
    }

    bottomShadowColor?: string,
    bottomShadowOpacity?: number,
    bottomShadowRadius?: number,
    bottomShadowOffset?: {
        height: number,
        width: number,
    }

    rightShadowColor?: string,
    rightShadowOpacity?: number,
    rightShadowRadius?: number,
    rightShadowOffset?: {
        height: number,
        width: number,
    }
};

const InnerShadow = ({
    style,
    children,
    shadowColor = Platform.OS === 'android' ? '#000000' : '#AAAACC',
    shadowOpacity = 0.5,
    shadowRadius = Platform.OS === 'android' ? 10 : 5,
    shadowOffset = { width: 5, height: 5 },

    topShadowColor,
    topShadowOpacity,
    topShadowRadius,
    topShadowOffset,

    leftShadowColor = Platform.OS === 'android' ? '#000000' : '#AAAACC',
    leftShadowOpacity,
    leftShadowRadius = Platform.OS === 'android' ? 20 : 5,
    leftShadowOffset,

    bottomShadowColor = '#FFFFFF',
    bottomShadowOpacity = 0.45,
    bottomShadowRadius,
    bottomShadowOffset,

    rightShadowColor = '#FFFFFF',
    rightShadowOpacity = 0.45,
    rightShadowRadius,
    rightShadowOffset,

}: InnerShadowProps) => {
    const shadowProps = {
        top: {
            shadowOffset: {
                height: topShadowOffset?.height ?? shadowOffset.height,
                width: topShadowOffset?.width ?? 0,
            },
            shadowColor: topShadowColor ?? shadowColor,
            shadowOpacity: topShadowOpacity ?? shadowOpacity,
            shadowRadius: topShadowRadius ?? shadowRadius,
            elevation: topShadowRadius ?? shadowRadius,
        },
        left: {
            shadowOffset: {
                height: leftShadowOffset?.height ?? 0,
                width: leftShadowOffset?.width ?? shadowOffset.height,
            },
            shadowColor: leftShadowColor ?? shadowColor,
            shadowOpacity: leftShadowOpacity ?? shadowOpacity,
            shadowRadius: leftShadowRadius ?? shadowRadius,
            elevation: leftShadowRadius ?? shadowRadius,
        },
        bottom: {
            shadowOffset: {
                height: bottomShadowOffset?.height ?? -shadowOffset.height,
                width: bottomShadowOffset?.width ?? 0,
            },
            shadowColor: bottomShadowColor ?? shadowColor,
            shadowOpacity: bottomShadowOpacity ?? shadowOpacity,
            shadowRadius: bottomShadowRadius ?? shadowRadius,
            elevation: bottomShadowRadius ?? shadowRadius,
        },
        right: {
            shadowOffset: {
                height: rightShadowOffset?.width ?? 0,
                width: rightShadowOffset?.height ?? -shadowOffset.height,
            },
            shadowColor: rightShadowColor ?? shadowColor,
            shadowOpacity: rightShadowOpacity ?? shadowOpacity,
            shadowRadius: rightShadowRadius ?? shadowRadius,
            elevation: rightShadowRadius ?? shadowRadius,
        },
    };

    return (
        <View style={[styles.shadowConatiner, style]}>
            {children}
            <View style={[styles.topShadow, shadowProps.top]} />
            <View style={[styles.leftShadow, shadowProps.left]} />
            <View style={[styles.bottomShadow, shadowProps.bottom]} />
            <View style={[styles.rightShadow, shadowProps.right]} />
        </View>
    );
};

export { InnerShadow };

const styles = StyleSheet.create({
    shadowConatiner: {
        overflow: 'hidden',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#EDEDED',
        backgroundColor: '#F0F0F3',
    },
    topShadow: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        height: 10,
        width: '100%',
        top: -10,
        shadowColor: '#AAAACC',
        shadowOpacity: 0.5,
        shadowOffset: {
            height: 5,
            width: 0,
        },
        shadowRadius: 10,
    },
    leftShadow: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        width: 10,
        height: '100%',
        left: -10,
        shadowColor: '#AAAACC',
        shadowOpacity: 0.5,
        shadowOffset: {
            height: 0,
            width: 5,
        },
        shadowRadius: 10,
    },
    bottomShadow: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        height: 10,
        width: '100%',
        bottom: -10,
        shadowColor: '#FFFFFF',
        shadowOpacity: 0.45,
        shadowOffset: {
            height: -5,
            width: 0,
        },
        shadowRadius: 10,
    },
    rightShadow: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        width: 10,
        height: '100%',
        right: -10,
        shadowColor: '#FFFFFF',
        shadowOpacity: 0.45,
        shadowOffset: {
            height: 0,
            width: -5,
        },
        shadowRadius: 10,
    },
});
