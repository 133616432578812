import * as React from 'react';
import Svg, { G, Rect, Defs, LinearGradient, Stop } from 'react-native-svg';

const GradientBackground = ({ width = 393, height = 343 }: { width?: number, height?: number }) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <G filter="url(#filter0_dd_376_26061)">
        <Rect
          x={-10}
          y={0}
          width={width + 20}
          height={height}
          fill="url(#paint0_linear_376_26061)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_376_26061"
          x1={0}
          y1={0}
          x2={width}
          y2={height}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#EF3A24" />
          <Stop offset={1} stopColor="#F9943B" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default GradientBackground;
