import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

type CoinIconProps = {
    size?: number
}

export default function CoinIcon({ size = 56 }: CoinIconProps) {
    return (
        <Svg
            width={size}
            height={size * (55 / 56)}
            viewBox="0 0 56 55"
            fill="none"
        >
            <G clipPath="url(#clip0_278_43756)">
                <Path
                    d="M28 0c7.731 0 14.732 3.079 19.798 8.055C52.865 13.031 56 19.907 56 27.5s-3.135 14.469-8.202 19.445C42.732 51.922 35.731 55 28 55c-7.731 0-14.732-3.078-19.799-8.055C3.135 41.97 0 35.093 0 27.5S3.135 13.031 8.201 8.055C13.268 3.079 20.268 0 28 0z"
                    fill="#ECCA43"
                />
                <Path
                    d="M28 54.462c15.162 0 27.452-12.07 27.452-26.962C55.452 12.61 43.162.538 28 .538 12.84.538.548 12.61.548 27.5c0 14.891 12.29 26.962 27.452 26.962z"
                    fill="#F7E259"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M55.098 23.17c.236 1.432.354 2.88.354 4.33 0 14.89-12.29 26.962-27.452 26.962C12.84 54.462.548 42.39.548 27.5c0-2.348.306-4.625.88-6.797l26.954 19.71L55.098 23.17z"
                    fill="#F8D548"
                />
                <Path
                    d="M28 6.33c5.951 0 11.34 2.369 15.242 6.2 3.9 3.83 6.313 9.124 6.313 14.97 0 5.845-2.413 11.138-6.313 14.969C39.34 46.3 33.952 48.67 28 48.67c-5.952 0-11.341-2.37-15.242-6.201-3.9-3.831-6.313-9.124-6.313-14.97 0-5.845 2.413-11.138 6.313-14.969 3.9-3.831 9.29-6.2 15.242-6.2z"
                    fill="#D7925B"
                />
                <Path
                    d="M28 48.132c11.602 0 21.008-9.237 21.008-20.632 0-11.395-9.406-20.633-21.008-20.633-11.602 0-21.007 9.238-21.007 20.633S16.398 48.132 28 48.132z"
                    fill="#EDA140"
                />
                <Path
                    d="M25.802 37.921c-.304-.021-.49-.051-.714-.087l-1.253-.176-.773-.128a18.547 18.547 0 01-1.47-.32l-.295-.077V32.38l1.368.11 1.005.058 1.054.046 1.068.033 1.037.02.965.004c.293 0 .55-.01.77-.03.226-.02.429-.05.608-.09.164-.036.307-.084.428-.141a.94.94 0 00.258-.174.533.533 0 00.128-.197.902.902 0 00.049-.313v-.314c0-.25-.082-.433-.246-.547a1.14 1.14 0 00-.333-.16 1.405 1.405 0 00-.41-.055h-1.412c-1.063 0-2.007-.117-2.827-.35-.837-.239-1.543-.598-2.118-1.077-.587-.493-1.026-1.144-1.316-1.955-.281-.789-.423-1.728-.423-2.816v-.863c0-1.008.157-1.895.47-2.66a4.793 4.793 0 011.433-1.944c.461-.373.898-.68 1.416-.918a5.847 5.847 0 011.533-.453v-2.108h4.463v2.023c.738.058 1.31.16 2.032.288l.375.064a32.658 32.658 0 011.768.36l.304.072v4.758l-.43-.037c-.43-.037-.86-.068-1.291-.095a75.341 75.341 0 00-2.9-.124c-.415-.01-.854-.014-1.317-.014-.243 0-.469.008-.675.024a5.99 5.99 0 00-.587.072 1.73 1.73 0 00-.421.127.972.972 0 00-.274.186.607.607 0 00-.146.236c-.038.11-.058.246-.058.404v.262c0 .168.025.31.074.425a.684.684 0 00.205.268c.097.078.229.138.39.18a2.7 2.7 0 00.666.072h1.758a8.28 8.28 0 011.796.184 5.533 5.533 0 011.503.561c.895.5 1.57 1.183 2.022 2.044.223.424.391.88.503 1.365.111.483.168.99.168 1.52v.864c0 .852-.078 1.612-.231 2.276-.157.678-.396 1.255-.716 1.731a4.57 4.57 0 01-1.165 1.198 5.06 5.06 0 01-1.56.73l-.15.04c-.569.161-.98.278-1.643.35v2.28h-4.463v-2.259z"
                    fill="#C26A34"
                />
                <Path
                    d="M25.123 37.254c-.304-.021-.49-.051-.714-.087l-1.253-.176-.773-.128a18.397 18.397 0 01-1.471-.32l-.295-.077v-4.753l1.369.11 1.004.058 1.055.046 1.067.033 1.038.02.965.004c.293 0 .55-.01.77-.03.225-.02.428-.05.608-.09.164-.036.306-.084.428-.141a.94.94 0 00.258-.174.532.532 0 00.128-.197.901.901 0 00.049-.313v-.314c0-.25-.083-.433-.246-.547a1.147 1.147 0 00-.333-.16 1.409 1.409 0 00-.41-.055h-1.412c-1.064 0-2.007-.117-2.828-.35-.836-.238-1.542-.598-2.117-1.077-.587-.493-1.027-1.144-1.316-1.955-.282-.789-.423-1.728-.423-2.816v-.863c0-1.008.157-1.895.469-2.66a4.794 4.794 0 011.434-1.944c.461-.373.897-.68 1.416-.918a5.854 5.854 0 011.533-.453V14.82h4.462v2.023c.739.058 1.31.16 2.033.288l.375.064a32.25 32.25 0 011.768.36l.304.072v4.758l-.43-.037c-.43-.037-.861-.068-1.292-.095a74.272 74.272 0 00-4.216-.138c-.243 0-.469.008-.675.024a5.978 5.978 0 00-.587.072 1.73 1.73 0 00-.421.127.97.97 0 00-.274.186.608.608 0 00-.146.236c-.039.111-.058.246-.058.404v.262c0 .168.025.31.074.425a.686.686 0 00.205.268c.097.078.228.138.39.18.182.047.405.072.666.072h1.758c.646 0 1.245.062 1.796.184a5.535 5.535 0 011.503.561c.895.5 1.57 1.183 2.022 2.044.222.424.39.88.503 1.365.111.483.168.99.168 1.52v.864c0 .852-.078 1.612-.231 2.276-.157.678-.396 1.255-.716 1.731-.32.477-.709.877-1.165 1.198a5.062 5.062 0 01-1.56.73l-.15.04c-.569.161-.98.278-1.644.35v2.28h-4.462v-2.259z"
                    fill="#F3DC6B"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_278_43756">
                    <Path fill="#fff" d="M0 0H56V55H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    );
}
