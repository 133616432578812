import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {MB_ToggleSwitch} from '@mightybyte/rnw.components.toggle-switch';
import {mbTextStyles} from '@mightybyte/rnw.utils.style-utils';
import {getColors} from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';

const COLORS = getColors();

const DefaultSwitch = ({isToggled, onToggle, label, labelLeft}: {
    isToggled: boolean;
    onToggle: (isToggled: boolean) => void;
    disabled?: boolean;
    label?: string;
    labelLeft?: boolean;
}) => {

    const trackActiveBackgroundColor = COLORS.orange;
    const trackInactiveBackgroundColor = COLORS.lightGray;

    return (
        <View style={{flexDirection: labelLeft ? 'row-reverse' : 'row', alignItems: 'center'}}>
            <MB_ToggleSwitch isToggled={isToggled }
                             // disable={disabled}
                             onToggle={onToggle}
                             trackBar={{
                                 activeBackgroundColor: trackActiveBackgroundColor,
                                 inActiveBackgroundColor: trackInactiveBackgroundColor,
                                 width: 60,
                                 radius: 30,
                                 height: 30,
                                 borderWidth: 3,
                                 borderActiveColor: trackActiveBackgroundColor,
                                 borderInActiveColor: trackInactiveBackgroundColor,
                             }}
                             thumbButton={{
                                 activeBackgroundColor: COLORS.white,
                                 inActiveBackgroundColor: COLORS.white,
                                 height: 30,
                                 width: 30,
                             }}
            />
            {label ?  <Text style={[styles.switchText, {
                marginLeft: labelLeft ? 0 : 8,
                marginRight: labelLeft ? 8 : 0,
            }]}>{label}</Text> : null}
        </View>
    );

};

export {DefaultSwitch };

const styles = StyleSheet.create({
    switchText: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.textInputText,
            textAlign: 'left',
            textTransform: 'capitalize',
        },
    ]),
});
