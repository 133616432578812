export enum ImageFileExtension {
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    WEBP = 'webp',
}

export enum RedirectReason {
    verifyPhoneNumber = 'verifyPhoneNumber',
    passwordRecovery = 'passwordRecovery',
}

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}
