import { UserSelectTags } from '@sapphicsavvy/business';
import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ShadowView } from './ShadowView';
import { Spacer } from './Spacer';
import Feather from 'react-native-vector-icons/Feather';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import EditTagModal from './modals/EditTagModal';
import { useModalState } from '../../utils/shared';

type TagsProps = {
    title?: string,
    tags?: UserSelectTags[],
    onTagsChange?: (tags: UserSelectTags[]) => void,
    max?: number
}

export default function Tags({ title, tags, max = 6, onTagsChange }: TagsProps) {

    const { state, show, hide } = useModalState(false);

    const onModalDismiss = useCallback((newTags?: UserSelectTags[]) => {
        if (newTags) {
            onTagsChange?.(newTags);
        }
        hide();
    }, [hide, onTagsChange]);

    const onRemoveTag = useCallback(
        (item: UserSelectTags) => onTagsChange?.(tags?.filter(tag => tag !== item) ?? []),
        [onTagsChange, tags]
    );

    return (
        <View>
            <Text style={styles.title}>{title ?? 'Add Tags'}</Text>
            <Text style={styles.title}>(up to {max} profile tags)</Text>
            <Spacer height={12} />
            <ShadowView onPress={() => show(true)}>
                <View style={styles.editTag}>
                    <Text style={styles.editTagText}>EDIT TAGS</Text>
                </View>
            </ShadowView>
            <View style={styles.tags}>
                {tags?.map((item, index) => (
                    <View key={`${item}-${index}`} style={styles.tag}>
                        <Text style={styles.tagText}>{item}</Text>
                        <Spacer width={5} />
                        <Feather
                            name="x"
                            size={20}
                            color={mbApplyTransparency('#FFFFFF', 0.55)}
                            onPress={() => onRemoveTag(item)}
                        />
                    </View>
                ))}
            </View>
            <Spacer height={10} />
            <EditTagModal
                isVisible={state ?? false}
                tags={tags}
                onDismiss={onModalDismiss}
                max={max}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    title: mbTextStyles([textStyles.normalText, {
        fontWeight: '600',
        textAlign: 'left',
        color: mbApplyTransparency('#000000', 0.55),
    }]),
    editTag: {
        height: 50,
        borderWidth: 2,
        borderColor: '#F35E2E',
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
    },
    editTagText: mbTextStyles([textStyles.normalText, {
        fontWeight: '800',
        textAlign: 'left',
        color: '#F35E2E',
    }]),
    tags: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 8,
    },
    tag: {
        backgroundColor: '#F35E2E',
        borderRadius: 10,
        paddingLeft: 12,
        paddingRight: 4,
        paddingVertical: 4,
        flexDirection: 'row',
        marginTop: 8,
        marginLeft: 5,
    },
    tagText: mbTextStyles([textStyles.normalText, {
        color: '#FFFFFF',
    }]),
});
