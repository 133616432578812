import React from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { Header } from '../Tabs/Settings/components/Header';
import { StyleSheet, Text } from 'react-native';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES } from '../../../constants/textStyles';
import { Spacer } from '../../helperComponents/Spacer';
import { ShadowTextInput } from '../../helperComponents/ShadowTextInput';
import { formUtils, useForm } from '../../../hooks/useForm';
import { z } from 'zod';
import { GradientButton } from '../../helperComponents/gradients/GradientButton';
import { trpc } from '../../../apiCalls/trpcClient';
import { utils } from '../../../utils/utils';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../navigations/RootNavigator';
import { STRING_CONSTANTS } from '../../../constants/constants';

type ValidForm = {
    phoneNumber: string,
}

const schemas = {
    phoneNumber: z.string().min(1, { message: 'Phone number is required' }).regex(formUtils.phoneRegex, 'Invalid phone number'),
};

const ForgotPassword = () => {

    const { setPhoneNumber, errors, validateAll } = useForm<Partial<ValidForm>>({ schemas }, { phoneNumber: '' });
    const { mutate: requestPasswordRecovery, isLoading } = trpc.user.requestPasswordRecovery.useMutation();

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();


    const onNext = () => {
        const result = validateAll<ValidForm>();
        if (result.isValid) {
            requestPasswordRecovery({
                phoneNumber: result.phoneNumber,
            }, {
                onSuccess: (data) => {
                    if (!data) {
                        utils.showPopUp('Phone number not found. Please check your number and try again');
                    } else {
                        navigation.navigate('VerifyForgetPassword', {
                            phoneNumber: result.phoneNumber,
                            token: data.token,
                        });
                    }
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    };

    return (
        <ComponentWrapper containerStyle={styles.container}>
            <Header title="FORGOT PASSWORD" />
            <Spacer height={40} />
            <Text style={styles.title}>Forgot Password?</Text>
            <Spacer height={5} />
            <Text style={styles.subTitle}>Enter your registered phone number.</Text>
            <Spacer height={40} />
            <ShadowTextInput
                title="Registered Phone Number"
                placeholder="+1 000 000 0000"
                showRequiredAsteriks
                keyboardType="phone-pad"
                onChangeText={setPhoneNumber}
                isError={errors.phoneNumber !== undefined}
                errorMessage={errors.phoneNumber}
                returnKeyType="done"
            />
            <Spacer />
            <GradientButton
                title="Next"
                onPress={onNext}
                loading={isLoading}
                disabled={isLoading}
            />
        </ComponentWrapper>
    );
};

export { ForgotPassword };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // paddingBottom: 30,
    },
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 28,
        color: '#242121',
    }]),
    subTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        textAlign: 'left',
        fontSize: 18,
        color: mbApplyTransparency('#000000', 0.55),
    }]),
});
