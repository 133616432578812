import React, { useCallback } from 'react';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { StyleSheet, Text } from 'react-native';
import { FONT_STYLES } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { CodeInput } from '../../../helperComponents/CodeInput';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import sharedStyles from './Shared/styles';
import { Header } from './Shared/Header';
import { z } from 'zod';
import { useForm } from '../../../../hooks/useForm';
import { trpc } from '../../../../apiCalls/trpcClient';
import { utils } from '../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { UserPrivateDataForClient } from '@sapphicsavvy/business';
import { AccountSetupForm } from '../../../../typesAndInterfaces/typesAndInterfaces';

type ValidForm = {
    code: string
}

const schemas = {
    code: z.string().max(0, { message: 'Invalid code. Please either fill it in or leave it empty' }).or(z.string().min(6)),
};

const ReferralCodeStep = ({ setupForm, onComplete }: { setupForm: AccountSetupForm, onComplete?: (data: { pointsEarned: number, userData: UserPrivateDataForClient }) => void }) => {

    const { mutate: setupAccount, data: { userData } = {}, isLoading: isSettingUp } = trpc.user.setupAccount.useMutation();

    const { setCode, validateAll, errors } = useForm<Partial<ValidForm>>({ schemas }, { code: '' });

    const complete = useCallback(() => {
        const result = validateAll<ValidForm>();
        if (result.isValid) {
            setupAccount({
                userName: setupForm.userName,
                dob: {
                    year: setupForm.dob.getFullYear(),
                    month: setupForm.dob.getMonth() + 1,
                    day: setupForm.dob.getDate(),
                },
                identity: setupForm.identity,
                email: setupForm.email,
                avatarFilename: setupForm.avatarFilename.split('/').pop() as string,
                referralCode: setupForm.referralCode,
            }, {
                onSuccess(data) {
                    onComplete?.({ pointsEarned: data.pointsEarned, userData: data.userData as UserPrivateDataForClient });
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    }, [validateAll, setupAccount, setupForm, onComplete]);

    return (
        <KeyboardAwareScrollView
            style={sharedStyles.container}
            contentContainerStyle={sharedStyles.contentContainer}
        >
            <Header title="REFERRAL CODE" step={5} steps={6} />
            <Spacer height={40} />
            <Text style={styles.title}>HAVE A REFERRAL CODE?</Text>
            <Spacer height={10} />
            <Text style={styles.subTitle}>If yes, enter it below so you and your friend will get 100 bonus points</Text>
            <Spacer height={20} />
            <CodeInput
                onCodeChange={setCode}
                isError={errors.code !== undefined}
                errorMessage={errors.code}
            />
            <Spacer height={35} />
            <Text style={styles.codeSent}>To skip this, leave blank and tap next.</Text>
            <Spacer />
            <GradientButton
                title="Next"
                onPress={complete}
                loading={isSettingUp}
                disabled={isSettingUp || userData !== undefined}
            />
        </KeyboardAwareScrollView>
    );
};

export default ReferralCodeStep;

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#EFEFF3',
        paddingHorizontal: 30,
        paddingVertical: 40,
        flex: 1,
    },
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 28,
        color: '#242121',
    }]),
    subTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        textAlign: 'left',
        fontSize: 18,
        color: mbApplyTransparency('#000000', 0.55),
    }]),
    codeSent: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        fontSize: 14,
        fontWeight: '400',
        color: mbApplyTransparency('#000000', 0.55),
        textAlign: 'center',
    }]),
});
