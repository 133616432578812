import * as React from 'react';
import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg';

function NoImageIcon() {
  return (
    <Svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
    >
      <G clipPath="url(#clip0_781_57619)">
        <Path
            d="M2.904.705h29.192c.66 0 1.264.273 1.698.71.435.437.706 1.044.706 1.71v29.161c0 .665-.271 1.272-.706 1.71a2.394 2.394 0 01-1.698.71H2.904a2.394 2.394 0 01-1.698-.71 2.423 2.423 0 01-.706-1.71V3.124c0-.665.271-1.272.706-1.71A2.394 2.394 0 012.904.706zm-.462 24.58l10.38-9.434a.962.962 0 011.363.07l.022.025.003-.003 8.702 10.36 1.334-8.236a.969.969 0 011.638-.537l6.682 6.452V3.124a.46.46 0 00-.462-.465h-29.2a.466.466 0 00-.462.465v22.161zm30.116 1.398l-6.702-6.473-1.37 8.466a.984.984 0 01-.334.618.965.965 0 01-1.364-.114l-9.416-11.21-10.93 9.937v4.38c0 .127.053.241.136.328.086.086.2.136.326.136h29.192a.466.466 0 00.462-.465v-5.604zm-6.436-20.45c.963 0 1.831.393 2.462 1.028a3.505 3.505 0 011.021 2.477c0 .966-.39 1.843-1.02 2.478a3.463 3.463 0 01-4.926 0 3.505 3.505 0 01-1.02-2.478c0-.966.39-1.843 1.02-2.477a3.458 3.458 0 012.463-1.028zM27.5 8.352a1.946 1.946 0 00-2.756 0 1.97 1.97 0 000 2.773 1.946 1.946 0 002.756 0 1.97 1.97 0 000-2.773z"
            fill="#000"
            fillOpacity={0.25}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_781_57619">
          <Path fill="#fff" transform="translate(.5 .705)" d="M0 0H34V34H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default  NoImageIcon;
