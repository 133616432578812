import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function PeopleIcon({ size = 25 }: { size?: number }) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
    >
      <Path
        d="M18.5 8.41h-.13c-1.89-.06-3.3-1.52-3.3-3.32 0-1.84 1.5-3.33 3.33-3.33s3.33 1.5 3.33 3.33a3.327 3.327 0 01-3.22 3.33c0-.01 0-.01-.01-.01zm-.1-5.16c-1.01 0-1.83.82-1.83 1.83 0 .99.77 1.79 1.76 1.83.01-.01.09-.01.18 0a1.832 1.832 0 00-.11-3.66zM18.51 15.78c-.39 0-.78-.03-1.17-.1a.75.75 0 01-.61-.87.75.75 0 01.87-.61c1.23.21 2.53-.02 3.4-.6.47-.31.72-.7.72-1.09 0-.39-.26-.77-.72-1.08-.87-.58-2.19-.81-3.43-.59-.41.08-.8-.2-.87-.61-.07-.41.2-.8.61-.87 1.63-.29 3.32.02 4.52.82.88.59 1.39 1.43 1.39 2.33 0 .89-.5 1.74-1.39 2.34-.91.6-2.09.93-3.32.93zM6.47 8.41h-.02a3.324 3.324 0 01-3.22-3.32c0-1.84 1.5-3.34 3.33-3.34s3.33 1.5 3.33 3.33c0 1.81-1.41 3.27-3.21 3.33l-.21-.75.07.75h-.07zm.1-1.5c.06 0 .11 0 .17.01.89-.04 1.67-.84 1.67-1.83a1.83 1.83 0 10-1.93 1.83c.01-.01.05-.01.09-.01zM6.46 15.78c-1.23 0-2.41-.33-3.32-.93-.88-.59-1.39-1.44-1.39-2.34 0-.89.51-1.74 1.39-2.33 1.2-.8 2.89-1.11 4.52-.82.41.07.68.46.61.87-.07.41-.46.69-.87.61-1.24-.22-2.55.01-3.43.59-.47.31-.72.69-.72 1.08 0 .39.26.78.72 1.09.87.58 2.17.81 3.4.6.41-.07.8.21.87.61.07.41-.2.8-.61.87-.39.07-.78.1-1.17.1zM12.5 15.88h-.13c-1.89-.06-3.3-1.52-3.3-3.32 0-1.84 1.5-3.33 3.33-3.33s3.33 1.5 3.33 3.33a3.327 3.327 0 01-3.22 3.33c0-.01 0-.01-.01-.01zm-.1-5.16c-1.01 0-1.83.82-1.83 1.83 0 .99.77 1.79 1.76 1.83.01-.01.09-.01.18 0 .97-.05 1.72-.85 1.73-1.83 0-1-.82-1.83-1.84-1.83zM12.5 23.26c-1.2 0-2.4-.31-3.33-.94-.88-.59-1.39-1.43-1.39-2.33 0-.89.5-1.75 1.39-2.34 1.87-1.24 4.8-1.24 6.66 0 .88.59 1.39 1.43 1.39 2.33 0 .89-.5 1.75-1.39 2.34-.93.62-2.13.94-3.33.94zM10 18.91c-.47.31-.72.7-.72 1.09 0 .39.26.77.72 1.08 1.35.91 3.64.91 4.99 0 .47-.31.72-.7.72-1.09 0-.39-.26-.77-.72-1.08-1.34-.91-3.63-.9-4.99 0z"
        fill="#fff"
      />
    </Svg>
  );
}

export default PeopleIcon;
