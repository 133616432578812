import React from 'react';
import { View } from 'react-native';

const Spacer = ({ width, height, color }: { width?: number, height?: number, color?: string }) => (
    width === undefined && height === undefined ?
        <View style={{ flexGrow: 1, backgroundColor: color }} />
        :
        <View style={{ width, height, backgroundColor: color }} />
);

export { Spacer };
