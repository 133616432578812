import React, { ReactNode } from 'react';
import { ShadowView } from '../../components/helperComponents/ShadowView';
import { StyleSheet } from 'react-native';
import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';

type ShadowRoundedButtonProps = {
    size?: number,
    borderWidth?: number,
    icon?: ReactNode,
    onPress?: () => void
}

export default function ShadowRoundedButton({ size = 81, borderWidth = 23, icon, onPress }: ShadowRoundedButtonProps) {
    return (
        <ShadowView style={[styles.container, { width: size, height: size }]} onPress={onPress}>
            <ShadowView style={[styles.innerContainer, { width: size - borderWidth, height: size - borderWidth }]}>
                {icon}
            </ShadowView>
        </ShadowView>
    );
}

const styles = StyleSheet.create({
    container: {
        height: 81,
        width: 81,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 360,
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: mbApplyTransparency('#D6E3F3', 0.41),
    },
    innerContainer: {
        height: 58,
        width: 58,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 360,
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: mbApplyTransparency('#D6E3F3', 0.41),
    },
});
