import React from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';
import { Header } from './Shared/Header';
import StandingIcon from '../../../resources/svgComponents/StandingIcon';


const Results = () => {

    return (
        <ComponentWrapper
            containerStyle={sharedStyles.container}
        >
            <Header title="RESULTS" icon={<StandingIcon size={30} style={{ marginBottom: 10 }} />} />
        </ComponentWrapper>
    );

};

export { Results };
