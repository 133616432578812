import { StyleSheet, ViewStyle } from 'react-native';

export default StyleSheet.create({
    container: {
        minWidth: 1000,
    },
    innerContainer: {
        flex: 1,
        backgroundColor: '#F8F8F8',
    },
    scrollView: {
        overflowX: 'visible',
    } as ViewStyle,
    body: {
        flex: 1,
        paddingHorizontal: 20,
        marginBottom: 40,
    },
});
