import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

function ArrowLeft({ size = 40, style }: { size?: number, style?: StyleProp<ViewStyle> }) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      style={style}
    >
      <Path
        d="M25 34.45c-.317 0-.633-.117-.883-.367L13.25 23.216c-1.767-1.767-1.767-4.667 0-6.433L24.117 5.916a1.258 1.258 0 011.766 0 1.258 1.258 0 010 1.767L15.017 18.549c-.8.8-.8 2.1 0 2.9l10.866 10.867a1.258 1.258 0 010 1.767 1.31 1.31 0 01-.883.367z"
        fill="#fff"
      />
    </Svg>
  );
}

export default ArrowLeft;
