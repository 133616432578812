import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function FilterIcon() {
  return (
    <Svg
      width={24}
      height={22}
      viewBox="0 0 24 22"
      fill="none"
    >
      <Path
        d="M.544 0h22.91A.56.56 0 0124 .574c0 .175-.074.33-.191.436l-9.145 9.567v8.21a.577.577 0 01-.308.517l-4.313 2.619a.53.53 0 01-.745-.209.592.592 0 01-.073-.287h-.001V10.575L.158.978A.593.593 0 01.16.168.53.53 0 01.544.002V0zm21.59 1.148H1.853l8.273 8.759c.116.105.189.26.189.434v10.093l3.256-1.977v-8.116h.002c0-.147.053-.294.16-.406l8.4-8.787z"
        fill="#F35E2E"
      />
    </Svg>
  );
}

export default FilterIcon;
