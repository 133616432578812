import React, { useCallback } from 'react';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from '../Shared/styles';
import { Header } from '../Shared/Header';
import MultiSettingsIcon from '../../../../resources/svgComponents/MultiSettingsIcon';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import ProfileIcon from '../../../../resources/svgComponents/ProfileIcon';
import { GradientContainer } from '../../../helperComponents/gradients/GradientButton';
import { MenuCard } from '../../../helperComponents/MenuCard';
import DocumentIcon from '../../../../resources/svgComponents/DocumentIcon';
import Feather from 'react-native-vector-icons/Feather';
import MailIcon from '../../../../resources/svgComponents/MailIcon';
import TrashIcon from '../../../../resources/svgComponents/TrashIcon';
import InfoIcon from '../../../../resources/svgComponents/InfoIcon';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../../navigations/RootNavigator';
import { useNavigation } from '@react-navigation/core';
import { useModalState } from '../../../../utils/shared';
import LogoutModal from './modals/LogoutModal';
import DeleteAccountModal from './modals/DeleteAccountModal';
import SapphicProfileModal from '../Sapphics/modals/SapphicProfileModal';
import KeyIcon from '../../../../resources/svgComponents/KeyIcon';


const Settings = () => {

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const { currentUserData } = useSignedInContext();
    const { state: isLogoutVisible, hide: hideLogout, show: showLogout } = useModalState(false);
    const { state: isDeleteVisible, hide: hideDelete, show: showDelete } = useModalState(false);
    const { state: userId, show: showUser, hide: hideUser } = useModalState<string>();

    const navigate = useCallback(
        (screen: keyof RootStackParamList) => () => navigation.navigate(screen as any),
        [navigation]
    );

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.container}
            wrapInScrollView
        >
            <Header title="SETTINGS" icon={<MultiSettingsIcon />} />
            <GradientContainer style={styles.secondHeader}>
                <View style={styles.imageConatiner}>
                    <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                </View>
                <View style={styles.profileInfo}>
                    <Text style={styles.usernameText}>{currentUserData?.userName?.toUpperCase()}</Text>
                    <TouchableOpacity
                        style={styles.viewProfileContainer}
                        onPress={() => currentUserData && showUser(currentUserData?.id)}
                    >
                        <ProfileIcon size={18} disableBackgroundColor style={styles.profileIcon} />
                        <Spacer width={5} />
                        <Text style={styles.viewProfileText}>View public profile</Text>
                    </TouchableOpacity>
                </View>
            </GradientContainer>
            <MenuCard
                title="Edit Profile"
                icon={<DocumentIcon />}
                onPress={navigate('EditProfile')}
            />
            <MenuCard
                title="Refer your Friends"
                icon={<Feather name="share-2" color="#000000" size={20} />}
                onPress={navigate('ReferFriend')}
            />
            <MenuCard
                title="Change Password"
                icon={<KeyIcon />}
                onPress={navigate('ChangePassword')}
            />
            <MenuCard
                title="Contact Us"
                icon={<MailIcon />}
                onPress={navigate('ContactUs')}
            />
            <MenuCard
                title="Terms & Privacy"
                icon={<Feather name="shield" color="#000000" size={20} />}
                onPress={navigate('TermsAndPrivacy')}
            />
            <MenuCard
                title="About"
                icon={<InfoIcon />}
                onPress={navigate('AboutUs')}
            />
            <MenuCard
                title="Delete Account"
                titleStyle={styles.deleteTitleText}
                icon={<TrashIcon />}
                rightIcon={null}
                onPress={() => showDelete(true)}
            />
            <TouchableOpacity style={styles.logOutContainer} onPress={() => showLogout(true)}>
                <Feather name="log-out" size={24} color="#F35E2E" />
                <Text style={styles.logOutText}>Log Out</Text>
            </TouchableOpacity>
            <LogoutModal isVisible={isLogoutVisible ?? false} onDismiss={hideLogout} />
            <DeleteAccountModal isVisible={isDeleteVisible ?? false} onDismiss={hideDelete} />
            <SapphicProfileModal userId={userId} onDismiss={hideUser} />
        </ComponentWrapper>
    );

};

export { Settings };

const styles = StyleSheet.create({
    secondHeader: {
        marginHorizontal: -16,
        flexDirection: 'row',
        height: 117,
        alignItems: 'center',
    },
    gradient: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 80,
        borderColor: '#FFFFFF',
        marginRight: 14,
        marginLeft: 20,
    },
    image: {
        width: 66,
        height: 66,
        borderRadius: 80,
    },
    profileInfo: {
        justifyContent: 'space-between',
        height: 66,
    },
    usernameText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        textAlign: 'left',
    }]),
    viewProfileContainer: {
        flexDirection: 'row',
        paddingHorizontal: 14,
        paddingVertical: 8,
        backgroundColor: '#FFFFFF',
        borderRadius: 29,
    },
    viewProfileText: mbTextStyles([textStyles.smallText, {
        color: '#F35E2E',
    }]),
    profileIcon: {
        marginTop: -2,
    },
    deleteTitleText: mbTextStyles([textStyles.normalText, {
        color: '#EE3823',
        fontWeight: '500',
    }]),
    logOutContainer: {
        flexDirection: 'row',
        alignSelf: 'center',
        alignItems: 'center',
        marginTop: 20,
    },
    logOutText: mbTextStyles([textStyles.normalText, {
        color: '#F35E2E',
        fontWeight: '500',
        marginLeft: 5,
    }]),
});
