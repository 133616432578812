import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const CalendarIcon = ({ style }: { style?: StyleProp<ViewStyle> }) => {
    return (
        <Svg
            style={style}
            width={26}
            height={26}
            viewBox="0 0 26 26"
            fill="none"
        >
            <Path
                d="M8.667 2.167v3.25M17.333 2.167v3.25M3.792 9.847h18.416M22.75 9.208v9.209c0 3.25-1.625 5.416-5.417 5.416H8.667c-3.792 0-5.417-2.166-5.417-5.416V9.208c0-3.25 1.625-5.416 5.417-5.416h8.666c3.792 0 5.417 2.166 5.417 5.416z"
                stroke="#EE3823"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M17.003 14.842h.01M17.003 18.092h.01M12.995 14.842h.01M12.995 18.092h.01M8.985 14.842h.01M8.985 18.092h.01"
                stroke="#EE3823"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default CalendarIcon;

