import React, { useCallback, useState } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { UserDataForAdmin } from '@sapphicsavvy/business';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import { Spacer } from '../../../helperComponents/Spacer';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { trpc } from '../../../../apiCalls/trpcClient';
import { utils } from '../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../constants/constants';

const Option = ({ isActive, text, onPress }: { isActive: boolean, text: string, onPress?: () => void }) => {
    const styles = optionStyles;
    return (
        <TouchableOpacity
            activeOpacity={1}
            style={styles.optionContainer}
            onPress={onPress}
        >
            <View style={styles.option}>
                <View style={[isActive && styles.activeOption]} />
            </View>
            <Text style={styles.optionText}>{text}</Text>
        </TouchableOpacity>
    );
};

const optionStyles = StyleSheet.create({
    optionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    option: {
        width: 17,
        height: 17,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderColor: '#F35E2E',
        borderRadius: 80,
    },
    activeOption: {
        width: 8,
        height: 8,
        backgroundColor: '#F35E2E',
        borderRadius: 80,
    },
    optionText: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
        color: '#000000',
        marginLeft: 12,
    }]),
});

const getActive = (value?: boolean, options: { reverse?: boolean } = {}) => value !== undefined ? (options.reverse ? !value : value) : false;

const EditUserModal = ({ isVisible, userInfo, onDismiss }: { isVisible: boolean, userInfo?: UserDataForAdmin, onDismiss?: () => void }) => {

    const trpcUtils = trpc.useUtils();
    const { mutate: editUser, isLoading } = trpc.admin.users.editUser.useMutation();
    const [hasFullAccess, setHasFullAccess] = useState<boolean | undefined>(userInfo?.hasFullAccess);
    const [isInStudy, setIsInStudy] = useState<boolean | undefined>(userInfo?.isInStudy);

    const onModalWillShow = useCallback(() => {
        setHasFullAccess(userInfo?.hasFullAccess);
        setIsInStudy(userInfo?.isInStudy);
    }, [userInfo?.hasFullAccess, userInfo?.isInStudy]);

    const onSave = () => {
        if (userInfo?.id) {
            editUser({
                id: userInfo.id,
                hasFullAccess,
                isInStudy,
            }, {
                onSuccess: () => {
                    trpcUtils.admin.users.getPage.invalidate();
                    onDismiss?.();
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    };

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            hideCloseButton
            childrenWrapperStyle={styles.container}
            onModalWillShow={onModalWillShow}
        >
            <View style={styles.row}>
                <View style={styles.row}>
                    <View style={styles.icon}>
                        <Feather name="settings" color="#FFFFFF" size={18} />
                    </View>
                    <Text style={[styles.text, styles.primaryText]}>User Settings</Text>
                </View>
                <Text style={[styles.text]}>{userInfo?.email}</Text>
            </View>
            <Spacer height={40} />
            <Text style={styles.sectionText}>App Access</Text>
            <Spacer height={17} />
            <View style={styles.appAccess}>
                <Option
                    text="Full Access"
                    isActive={getActive(hasFullAccess)}
                    onPress={() => setHasFullAccess(true)}
                />
                <Option
                    text="Limited Access"
                    isActive={getActive(hasFullAccess, { reverse: true })}
                    onPress={() => setHasFullAccess(false)}
                />
            </View>
            <Spacer height={26} />
            <Text style={styles.sectionText}>In Study</Text>
            <Spacer height={17} />
            <View style={styles.inStudy}>
                <Option
                    text="Yes"
                    isActive={getActive(isInStudy)}
                    onPress={() => setIsInStudy(true)}
                />
                <Option
                    text="No"
                    isActive={getActive(isInStudy, { reverse: true })}
                    onPress={() => setIsInStudy(false)}
                />
            </View>
            <Spacer height={40} />
            <View style={styles.footer}>
                <MB_Button
                    title="Save Changes"
                    style={styles.primaryBtn}
                    textStyle={styles.primaryBtnText}
                    onPress={onSave}
                    disabled={isLoading}
                    loading={isLoading}
                />
                <MB_Button
                    title="Cancel"
                    style={styles.secondaryBtn}
                    textStyle={[styles.primaryBtnText, styles.secondaryBtnText]}
                    onPress={onDismiss}
                />
            </View>
        </MB_Modal>
    );
};

export { EditUserModal };

const styles = StyleSheet.create({
    container: {
        width: 533,
        paddingTop: 20,
        paddingBottom: 0,
        paddingHorizontal: 20,
        alignItems: 'stretch',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        width: 28,
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 80,
        backgroundColor: '#F35E2E',
        marginRight: 8,
    },
    text: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        textAlign: 'left',
    }]),
    primaryText: {
        fontWeight: '600',
        color: '#F35E2E',
    },
    sectionText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '600',
        color: '#000000',
        textAlign: 'left',
    }]),
    appAccess: {
        flexDirection: 'row',
        backgroundColor: '#FAFAFA',
        height: 46,
        width: 315,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 22,
    },
    inStudy: {
        flexDirection: 'row',
        backgroundColor: '#FAFAFA',
        height: 46,
        width: 164,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 22,
    },
    footer: {
        height: 95,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FEF2EE',
        paddingHorizontal: 15,
        flexDirection: 'row',
        marginHorizontal: -20,
    },
    primaryBtn: {
        width: 244,
        height: 49,
        backgroundColor: '#F35E2E',
        borderRadius: 8,
        borderWidth: 0,
    },
    primaryBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
    }]),
    secondaryBtn: {
        width: 244,
        height: 49,
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: '#F35E2E',
    },
    secondaryBtnText: {
        color: '#F35E2E',
    },
});
