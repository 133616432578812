import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function DashboardIcon({ color = '#8D9297' }: { color?: string }) {
    return (
        <Svg
            width={18}
            height={19}
            viewBox="0 0 18 19"
            fill="none"
        >
            <Path
                d="M0 9.733a1 1 0 001 1h6a1 1 0 001-1v-8a1 1 0 00-1-1H1a1 1 0 00-1 1v8zm0 8a1 1 0 001 1h6a1 1 0 001-1v-4a1 1 0 00-1-1H1a1 1 0 00-1 1v4zm10 0a1 1 0 001 1h6a1 1 0 001-1v-8a1 1 0 00-1-1h-6a1 1 0 00-1 1v8zm1-17a1 1 0 00-1 1v4a1 1 0 001 1h6a1 1 0 001-1v-4a1 1 0 00-1-1h-6z"
                fill={color}
            />
        </Svg>
    );
}

export default DashboardIcon;
