import React, { useCallback } from 'react';
import { Image, StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import GradientBackground from '../../../resources/svgComponents/GradientBackground';
import { imageUtils } from '../../../utils/imageUtils';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { TextInput } from '../../helperComponents/ShadowTextInput';
import { Spacer } from '../../helperComponents/Spacer';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { trpc } from '../../../apiCalls/trpcClient';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import { utils } from '../../../utils/utils';
import { z } from 'zod';
import { formUtils, useForm } from '../../../hooks/useForm';
import { MB_Button } from '@mightybyte/rnw.components.button';

type ValidForm = {
    phoneNumber: string,
    password: string,
}

const schemas = {
    phoneNumber: z.string().min(1, { message: 'Phone number is required' }).regex(formUtils.phoneRegex, 'Invalid phone number'),
    password: z.string().min(1, { message: 'Password is required' }),
};

const SignIn = () => {

    const { setSignedInStatus } = useSignedInContext();
    const { mutate: login, isLoading: isLogin } = trpc.user.login.useMutation();
    const { width: windowWidth } = useWindowDimensions();

    const {
        setPhoneNumber,
        setPassword,
        validateAll,
        errors,
    } = useForm<Partial<ValidForm>>({ schemas }, { phoneNumber: '', password: '' });

    const onSignIn = useCallback(() => {
        const result = validateAll<ValidForm>();
        if (result.isValid) {
            login({
                phoneNumber: result.phoneNumber,
                password: result.password,
            }, {
                async onSuccess(data) {
                    await MB_accessTokenUtils.setAccessToken(data.tokenPair);
                    setSignedInStatus(SIGNED_IN_STATUS.signedIn);
                },
                onError: () => utils.showPopUp('Phone number or password is incorrect'),
            });
        }
    }, [login, setSignedInStatus, validateAll]);


    return (
        <ComponentWrapper containerStyle={styles.screen}>
            <View style={styles.container}>
                <View style={styles.hiddenHeader}>
                    <GradientBackground
                        height={200}
                        width={windowWidth}
                    />
                </View>
                <View style={styles.header}>
                    <View style={styles.appIconContainer}>
                        <Image source={imageUtils.images.appIcon} style={styles.appIcon} />
                    </View>
                </View>
                <View style={styles.content}>
                    <Text style={styles.pageTitle}>Admin Panel Login</Text>
                    <Text style={styles.pageSubTitle}>Enter your phone number and password to log in!</Text>
                    <Spacer height={45} />
                    <TextInput
                        title="Phone Number"
                        placeholder="+1 000 000 0000"
                        showRequiredAsteriks
                        onChangeText={setPhoneNumber}
                        isError={errors.phoneNumber !== undefined}
                        errorMessage={errors.phoneNumber}
                        style={styles.textInput}
                        placeholderTextColor="#959595"
                        textInputStyle={styles.textInputText}
                    />
                    <Spacer height={20} />
                    <TextInput
                        title="Password"
                        placeholder="Enter Password"
                        showRequiredAsteriks
                        secureTextEntry
                        onChangeText={setPassword}
                        isError={errors.password !== undefined}
                        errorMessage={errors.password}
                        style={styles.textInput}
                        placeholderTextColor="#959595"
                        textInputStyle={styles.textInputText}
                    />
                    <Spacer height={30} />
                    <MB_Button
                        title="Login"
                        style={styles.signUpBtn}
                        loading={isLogin}
                        onPress={onSignIn}
                        disabled={isLogin}
                        textStyle={styles.signInText}
                    />
                    <Spacer height={30} />
                </View>
            </View>
        </ComponentWrapper>
    );
};

export { SignIn };

const styles = StyleSheet.create({
    screen: {
        paddingHorizontal: 0,
        paddingBottom: 0,
        backgroundColor: '#FAFAFA',
    },
    container: {
        flex: 1,
    },
    hiddenHeader: {
        position: 'absolute',
        // marginTop: -Dimensions.get('window').height * 0.65,
    },
    header: {
        height: 200,
        marginBottom: (173 / 2),
    },
    content: {
        alignSelf: 'center',
        width: 410,
        paddingHorizontal: 30,
        borderBottomRightRadius: 50,
        borderBottomLeftRadius: 50,
    },
    pageTitle: mbTextStyles([textStyles.largeText, {
        fontSize: 25,
        fontWeight: '700',
        color: '#000000',
        textAlign: 'center',
        lineHeight: 56,
    }], { allowLineHeight: true }),
    pageSubTitle: mbTextStyles([textStyles.normalText, {
        fontWeight: '400',
        color: '#666666',
        textAlign: 'center',
    }]),
    appIconContainer: {
        position: 'absolute',
        alignItems: 'center',
        height: 173,
        top: 200 - (173 / 2),
        right: 0,
        left: 0,
    },
    appIcon: {
        width: 173,
        height: 173,
    },
    important: mbTextStyles([textStyles.smallText, {
        color: '#F14728',
        textAlign: 'center',
        fontWeight: '700',
        marginTop: 'auto',
        marginBottom: 30,
    }]),
    signUpBtn: {
        backgroundColor: '#F35E2E',
    },
    textInput: {
        backgroundColor: '#FFFFFF',
        borderWidth: 0.5,
        borderColor: '#696969',
        borderRadius: 5,
    },
    textInputText: mbTextStyles([textStyles.smallText, {
        fontWeight: '400',
        textAlign: 'left',
        color: '#000000',
    }]),
    signInText: mbTextStyles([textStyles.normalText, {
        color: '#FFFFFF',
    }]),
});
