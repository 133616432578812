import React, { useCallback } from 'react';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { FONT_STYLES } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import sharedStyles from './Shared/styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Header } from './Shared/Header';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import ProfileIcon from '../../../../resources/svgComponents/ProfileIcon';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../../context/SignedInContext';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../../navigations/RootNavigator';
import { useNavigation } from '@react-navigation/core';
import { trpc } from '../../../../apiCalls/trpcClient';
import { AccountSetupForm } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { hitSlop } from '../../../../utils/shared';

const WelcomeSetup = ({ setupForm }: { setupForm: AccountSetupForm }) => {

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const trpcUtils = trpc.useUtils();
    const { isSignedIn, setSignedInStatus } = useSignedInContext();

    const complete = useCallback((options: { editMode?: boolean } = {}) => {
        if (!isSignedIn) {
            setSignedInStatus(SIGNED_IN_STATUS.signedIn);
        }
        trpcUtils.user.getCurrentUserData.setData(undefined, { userData: setupForm.userData });
        if (options.editMode) {
            setTimeout(() => navigation.reset({
                routes: [{ name: 'Tabs' }, { name: 'EditProfile' }],
                index: 1,
            }), isSignedIn ? 0 : 200);
        } else {
            setTimeout(() => navigation.reset({
                routes: [{ name: 'Tabs' }],
                index: 1,
            }), isSignedIn ? 0 : 200);
        }
    }, [isSignedIn, navigation, setSignedInStatus, setupForm.userData, trpcUtils.user.getCurrentUserData]);

    return (
        <KeyboardAwareScrollView
            style={sharedStyles.container}
            contentContainerStyle={sharedStyles.contentContainer}
        >
            <Header title="WELCOME" step={6} steps={6} />
            <View>
                <Spacer height={40} />
                <View style={styles.background} />
                <Text style={styles.welcomeText}>Hi @{setupForm.userName}</Text>
                <Spacer height={20} />
                <View style={styles.imageContainer}>
                    {setupForm.avatarFilename ?
                        <Image style={styles.profileImage} source={{ uri: setupForm.image }} />
                        :
                        <ProfileIcon size={136} />
                    }
                </View>
            </View>
            <Spacer height={20} />
            <Text style={styles.title}>ACCOUNT SET UP SUCCESS</Text>
            <Spacer height={30} />
            <Text style={styles.important}>Congratulations, you earned</Text>
            <Spacer height={15} />
            <ShadowView style={styles.points}>
                <CoinsIcon />
                <Spacer width={8} />
                <Text style={styles.pointsText}>{setupForm.pointsEarned}</Text>
            </ShadowView>
            <Spacer height={30} />
            <Text style={styles.subTitle}>Would you like to finish your public profile now or do this later via settings?</Text>
            <Spacer height={30} />
            <GradientButton
                title="Complete Profile"
                onPress={() => complete({ editMode: true })}
            />
            <Spacer height={5} />
            <View style={styles.skipBtn}>
                <TouchableOpacity
                    onPress={() => complete()}
                    hitSlop={hitSlop}
                >
                    <Text style={styles.skipBtnText}>Skip</Text>
                </TouchableOpacity>
            </View>
        </KeyboardAwareScrollView>
    );
};

export { WelcomeSetup };

const styles = StyleSheet.create({
    background: {
        marginHorizontal: -30,
        position: 'absolute',
        backgroundColor: '#F35E2E',
        height: 160,
        left: 0,
        right: 0,
    },
    welcomeText: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_500,
        textAlign: 'center',
        fontSize: 25,
        color: '#FFFFFF',
        marginTop: 'auto',
    }]),
    imageContainer: {
        height: 140,
        width: 140,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 360,
        borderWidth: 4,
        borderColor: '#FFFFFF',
        backgroundColor: '#D9D9D9',
    },
    profileImage: {
        width: 136,
        height: 136,
        borderRadius: 360,
    },
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'center',
        fontSize: 28,
        color: '#242121',
        paddingHorizontal: 20,
    }]),
    subTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        textAlign: 'center',
        fontSize: 16,
        color: '#000000',
    }]),
    important: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_500,
        textAlign: 'center',
        fontSize: 16,
        color: '#F14728',
    }]),
    points: {
        height: 94,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        flexDirection: 'row',
    },
    pointsText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'center',
        fontSize: 55,
        color: '#F35E2E',
    }]),
    skipBtn: {
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    skipBtnText: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        textAlign: 'center',
        fontWeight: '600',
        fontSize: 16,
        color: '#F14728',
    }]),
});
