import React from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { Header } from '../Tabs/Settings/components/Header';
import { StyleSheet, Text } from 'react-native';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES } from '../../../constants/textStyles';
import { Spacer } from '../../helperComponents/Spacer';
import { ShadowTextInput } from '../../helperComponents/ShadowTextInput';
import { formUtils, useForm } from '../../../hooks/useForm';
import { GradientButton } from '../../helperComponents/gradients/GradientButton';
import { trpc } from '../../../apiCalls/trpcClient';
import { utils } from '../../../utils/utils';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { ScreenProps } from '../../../typesAndInterfaces/componentProps';

type ValidForm = {
    password: string,
}

const schemas = {
    password: formUtils.validatePassword,
};

const ResetPassword = ({ route, navigation }: ScreenProps<'ResetPassword'>) => {

    const { setPassword, errors, validateAll } = useForm<Partial<ValidForm>>({ schemas }, { password: '' });
    const { mutate: changePasswordViaRecoveryToken, isLoading } = trpc.user.changePasswordViaRecoveryToken.useMutation();

    const onNext = () => {
        const result = validateAll<ValidForm>();
        if (result.isValid) {
            changePasswordViaRecoveryToken({
                newPassword: result.password,
                recoveryToken: route.params.token,
                code: route.params.code,
            }, {
                onSuccess: () => {
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'Login' }],
                    });
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    };

    return (
        <ComponentWrapper containerStyle={styles.container}>
            <Header title="FORGOT PASSWORD" />
            <Spacer height={40} />
            <Text style={styles.title}>Forgot Password?</Text>
            <Spacer height={5} />
            <Text style={styles.subTitle}>Enter your registered phone number.</Text>
            <Spacer height={40} />
            <ShadowTextInput
                title="Create Password"
                placeholder="Create Password"
                showRequiredAsteriks
                secureTextEntry
                onChangeText={setPassword}
                isError={errors.password !== undefined}
                errorMessage={errors.password}
            />
            <Spacer />
            <GradientButton
                title="Reset Password"
                onPress={onNext}
                loading={isLoading}
                disabled={isLoading}
            />
        </ComponentWrapper>
    );
};

export { ResetPassword };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingBottom: 30,
    },
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 28,
        color: '#242121',
    }]),
    subTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        textAlign: 'left',
        fontSize: 18,
        color: mbApplyTransparency('#000000', 0.55),
    }]),
});
