import React, { useCallback, useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { getNumberOfSteps, getTopInset, NotNull } from '../../../../utils/shared';
import CirculaireSlider from '../../../helperComponents/CirculaireSlider';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import ShadowRoundedButton from '../../../../resources/svgComponents/ShadowRoundedButton';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import MinusSignIcon from '../../../../resources/svgComponents/Quarter/MinusSignIcon';
import PlusSignIcon from '../../../../resources/svgComponents/Quarter/PlusSignIcon';
import Stepper from '../../../helperComponents/Stepper';
import { AnswerType, QuestionToPlay } from '@sapphicsavvy/business';
import BarSlider from '../../../helperComponents/BarSlider';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { Answer } from '../../../../navigations/RootNavigator';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';

type GuessStepProp = {
    onNext?: (answer: Partial<Answer>) => void,
    onBack?: () => void,
    question: QuestionToPlay
}

export default function GuessStep({ question, onNext, onBack }: GuessStepProp) {

    const [value, setValue] = useState<number>();

    const imageUri = mbGetMediumImage(question.imageUrl, { asUri: true });

    const min = question.answerOptions.min || 0;
    const max = question.answerOptions.max || 100;
    const increment = question.answerOptions.increment || 1;

    const onPlus = useCallback(
        () => setValue(prev => Math.min((prev || 0) + increment, max)),
        [increment, max]
    );

    const onMinus = useCallback(
        () => setValue(prev => Math.max((prev || 0) - increment, min)),
        [increment, min]
    );

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>GUESS</Text>
                <Spacer width={10} />
                <FontAwesome name="question-circle" color={styles.headerTitleText.color} size={40} />
                <Spacer />
                <ShadowView style={styles.coins}>
                    <CoinsIcon size={27} />
                    <Spacer width={6} />
                    <Text style={styles.pointsText}>200</Text>
                </ShadowView>
            </View>
            <View style={styles.topSection}>
                <Stepper style={styles.topSectionStepper} step={1} max={getNumberOfSteps(question)} />
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{question.guessQuestion}</Text>
                    <Image style={styles.topSectionImage} source={imageUri} />
                </View>
                <View style={styles.topSectionPeople} />
            </View>
            <View style={styles.bottomSection}>
                {question.answerType === AnswerType.percentage ?
                    <View>
                        <CirculaireSlider
                            size={300}
                            percentage={value}
                            onChange={setValue}
                            step={increment}
                        />
                        <Text style={styles.bottomPercentageAbsoluteText}>{value !== undefined ? `${value}%` : '?'}</Text>
                    </View>
                    :
                    <View style={{ alignSelf: 'stretch', paddingHorizontal: 30 }}>
                        <BarSlider colors={['#0495CD', '#2EB0E2']} minValue={min} value={value ?? min} maxValue={max} />
                        <Text style={styles.bottomPercentageText}>{value !== undefined ? value : '?'}</Text>
                    </View>
                }
                <View style={styles.bottomSectionBtns}>
                    <ShadowRoundedButton
                        icon={<MinusSignIcon />}
                        onPress={onMinus}
                    />
                    <Spacer width={30} />
                    <ShadowRoundedButton
                        icon={<PlusSignIcon />}
                        onPress={onPlus}
                    />
                </View>
            </View>
            <View style={styles.footer}>
                <MB_Button
                    style={styles.backBtn}
                    leftElement={<Feather name="chevron-left" color={styles.backBtnText.color} size={20} />}
                    title="BACK"
                    textStyle={styles.backBtnText}
                    onPress={onBack}
                />
                <MB_Button
                    style={styles.nextBtn}
                    rightElement={<Feather name="chevron-right" color={styles.nextBtnText.color} size={20} />}
                    title="NEXT"
                    textStyle={styles.nextBtnText}
                    onPress={() => onNext?.({ guess: value })}
                    disabled={!NotNull(value)}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: getTopInset(),
        flex: 1,
        backgroundColor: '#EFEFF3',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    coins: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: 111,
        height: 40,
    },
    pointsText: mbTextStyles([textStyles.largerText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    headerTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontWeight: '400',
        fontSize: 38,
        color: '#0495CD',
    }]),
    topSection: {
        flex: 1,
        backgroundColor: '#0295CD',
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionImage: {
        width: '100%',
        flex: 1,
        borderRadius: 12,
    },
    topSectionStepper: {
        margin: 2,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
        marginBottom: 21,
    }]),
    topSectionPeople: {
        height: 55,
        backgroundColor: mbApplyTransparency('#FFFFFF', 0.17),
        marginTop: 'auto',
    },
    bottomSection: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    bottomPercentageText: mbTextStyles([textStyles.normalText, {
        fontSize: 45,
        fontWeight: '700',
        color: '#0495CD',
        marginBottom: 15,
    }]),
    bottomPercentageAbsoluteText: mbTextStyles([textStyles.normalText, {
        fontSize: 45,
        fontWeight: '700',
        color: '#0495CD',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 20,
        zIndex: -1,
    }]),
    bottomSectionBtns: {
        flexDirection: 'row',
    },
    footer: {
        flexDirection: 'row',
        height: 60,
        ...mbShadow({
            offsetWidth: 5,
            offsetHeight: 5,
            radius: 10,
            elevation: 10,
            color: '#AEAEC0',
            opacity: 0.5,
        }),
    },
    backBtn: {
        backgroundColor: '#F0F0F3',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    backBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#7E8F9F',
    }]),
    nextBtn: {
        backgroundColor: '#0495CD',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    nextBtnDisabled: {
        backgroundColor: mbApplyTransparency('#0495CD', 0.70),
    },
    nextBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#FFFFFF',
    }]),
});
