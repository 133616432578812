import React from 'react';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { StyleSheet, Text, View } from 'react-native';
import { textStyles } from '../../constants/textStyles';
import { getColors } from '../../constants/colors';

const COLORS = getColors();

const UpdateApp = ({ version }: { version: string }) => {

    return (
        <View style={styles.container}>
            <Text style={styles.textLarge}>New Version  available</Text>
            <Text style={styles.textSmall}>V{version}</Text>
            <Text style={styles.textSmall}>The current version of this application is no longer supported.</Text>
        </View>
    );
};

export { UpdateApp };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 30,
    },
    textLarge: mbTextStyles([textStyles.normalText, {
        color: COLORS.textPrimary,
    }]),
    textSmall: mbTextStyles([textStyles.smallText, {
        fontSize: 14,
        color: COLORS.textSecondary,
    }]),
});
