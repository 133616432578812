import React, { useCallback, useState } from 'react';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Image, StyleSheet, Text, View } from 'react-native';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { ShadowView } from '../../../helperComponents/ShadowView';
import ProfileIcon from '../../../../resources/svgComponents/ProfileIcon';
import { MB_Button } from '@mightybyte/rnw.components.button';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import sharedStyles from './Shared/styles';
import { Header } from './Shared/Header';
import { useUploadImage } from '../../../../utils/queries';
import { utils } from '../../../../utils/utils';


const UploadProfilePhotoStep = ({ onComplete }: { onComplete?: (data: { avatarFilename: string, image: string }) => void } = {}) => {

    const [errorMessage, setErrorMessage] = useState<string>();
    const { pickImage, isUploading, image, uploadUrl } = useUploadImage();

    const complete = useCallback(() => {
        if (!image) {
            setErrorMessage('Profile image is required');
        } else if (!uploadUrl) {
            setErrorMessage('Image upload failed. Please try again.');
        } else {
            onComplete?.({
                image: image,
                avatarFilename: utils.getFileNameFromUrl(uploadUrl),
            });
        }
    }, [image, onComplete, uploadUrl]);

    return (
        <KeyboardAwareScrollView
            style={sharedStyles.container}
            contentContainerStyle={sharedStyles.contentContainer}
        >
            <Header title="ACCOUNT SETUP" step={3} steps={6} />
            <Spacer height={40} />
            <Text style={styles.title}>UPLOAD PROFILE PHOTO</Text>
            <Spacer height={25} />
            <ShadowView style={styles.profile}>
                {image ?
                    <Image style={styles.profileImage} source={{ uri: image }} />
                    :
                    <View style={[styles.profileIcon, errorMessage !== undefined && styles.error]}>
                        <ProfileIcon />
                    </View>
                }
                <Text style={styles.errorMessageText}>{errorMessage}</Text>
                <Spacer height={15} />
                <MB_Button
                    title="Upload Profile Picture"
                    style={styles.uploadBtn}
                    textStyle={styles.uploadBtnText}
                    leftElement={<AntDesign name="upload" color="#EE3823" size={22} />}
                    disabled={isUploading}
                    loading={isUploading}
                    activityIndicatorProps={{ color: '#EE3823' }}
                    onPress={() => pickImage({ onSelectImage: () => setErrorMessage(undefined) })}
                />
            </ShadowView>
            <Spacer height={14} />
            <ShadowView style={styles.note}>
                <Text style={styles.noteTitle}>Why is a profile picture required?</Text>
                <Spacer height={15} />
                <Text style={styles.noteInfo}>This app aims to increase sapphic visibility so a photo is needed to represent you throughout the app. Not comfortable showing your face? No worries, a bitmoji or pet photo is fine here!</Text>
            </ShadowView>
            <Spacer height={14} />
            <Spacer />
            <GradientButton
                title="Next"
                disabled={isUploading}
                onPress={complete}
            />
        </KeyboardAwareScrollView>
    );
};

export default UploadProfilePhotoStep;

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#EFEFF3',
        paddingHorizontal: 30,
        paddingVertical: 40,
        flex: 1,
    },
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 28,
        color: '#242121',
    }]),
    profile: {
        paddingHorizontal: 23,
        paddingVertical: 26,
        alignItems: 'center',
    },
    profileImage: {
        width: 160,
        height: 160,
        borderRadius: 80,
    },
    uploadBtn: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: '#EE3823',
        borderRadius: 6,
    },
    uploadBtnText: mbTextStyles([textStyles.normalText, {
        color: '#EE3823',
        fontWeight: '600',
    }]),
    profileIcon: {
        width: 160,
        height: 160,
        borderWidth: 1,
        borderRadius: 80,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        borderColor: 'transparent',
    },
    error: {
        borderColor: mbApplyTransparency('#FF0000', 0.80),
    },
    errorMessageText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#FF0000', 0.80),
        textAlign: 'left',
        fontWeight: '400',
        fontSize: 11,
        marginTop: 10,
    }]),
    note: {
        paddingVertical: 23,
        paddingHorizontal: 26,
    },
    noteTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        fontSize: 16,
        color: '#000000',
        textAlign: 'left',
    }]),
    noteInfo: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        fontSize: 16,
        color: mbApplyTransparency('#000000', 0.6),
        textAlign: 'left',
    }]),
    nextBtn: {
        marginTop: 'auto',
    },
});
